import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Pagination, RoleData, User } from "../common/define-types";
export interface UserResponse extends User {
    // data: User[],
    // meta: {
    //     pagination: Pagination
    // }
}
export interface UserRequest {
    confirmed: boolean,
    id?: string,
    role?: {
        connect?: {id: number}[],
        disconnect?: {id: number}[]
    },
    zone?: {
        connect?: number[],
        disconnect?: number[]
    },
    username?: string,
    email?: string,
    password?: string,
    phone?: string,
    fullName?: string,
}
export interface RoleResponse {
    roles: RoleData[]
}
export default class UserAPI {
    static host = SYSTEM_CONSTANTS.HOST;
    static get(pageSize: number, page: number, role?: number, isZone?: boolean, search?: string): Observable<UserResponse | null> {
        var api = `${UserAPI.host}/${SYSTEM_CONSTANTS.USER.GET}?populate[0]=role&pagination[pageSize]=${pageSize}&pagination[page]=${page}`;
        if (role) api = api + '&filters[role][id]='+role;
        if (isZone) {
            api = api + '&populate=zone'
        }
        if (search) {
            api = api + '&filters[fullName][$contains]=' +  search;
        }
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as UserResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static add(body: UserRequest): Observable<UserResponse | null> {
        const api = `${UserAPI.host}/${SYSTEM_CONSTANTS.USER.ADD}`;
        return HttpClient.post(api, body, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as UserResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static update(body: UserRequest): Observable<UserResponse | null> {
        const api = `${UserAPI.host}/${SYSTEM_CONSTANTS.USER.UPDATE(body.id ?? '/')}`;
        return HttpClient.put(api, body, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as UserResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static delete(id: string): Observable<UserResponse | null> {
        const api = `${UserAPI.host}/${SYSTEM_CONSTANTS.USER.DELETE(id)}`;
        return HttpClient.delete(api, null, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as UserResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static getAllRole (): Observable<RoleResponse | null> {
        const api = `${UserAPI.host}/${SYSTEM_CONSTANTS.USER.ROLE}`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as RoleResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}