import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  MenuProps,
  Modal,
  Pagination,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { DropdownComponent } from "../../../components/Dropdown/DropdownComponent";
import { ColumnsType } from "antd/es/table";
import Utils from "../../../common/Utils";
import exportSvg from "../../../image/icon/export.svg";
import { ReactSVG } from "react-svg";
import { useSelectorRoot } from "../../../store/store";
import { useDispatch } from "react-redux";
import { getBill } from "../../../store/controls/Bill.slice";
import BillAPI, { Bill } from "../../../api/Bill.api";
import { getOverview } from "../../../store/controls/Overview.slice";
import { CSVLink } from "react-csv";
import { ContainerOutlined } from "@ant-design/icons";
import moment from "moment";
import { handlePrint } from "../../../utils/request";
import {
  HTMLBase,
  convertBillToHtml,
  convertBillToUrlParam,
  invoiceHtml,
  onPrint,
} from "../../print/PrintHelper";

const { Text } = Typography;
const { Search } = Input;
var billExport: Bill[] = [];
var listPrintedPage: number[] = [];
export const StatusMeasureWater = () => {
  const dispatch = useDispatch();
  const months: MenuProps["items"] = Utils.arrayRange(1, 12, 1).map((x) => ({
    key: "" + x,
    label: "" + x,
  }));
  const years: MenuProps["items"] = Utils.arrayRange(
    1900,
    new Date().getFullYear(),
    1
  )
    .map((x) => ({ key: "" + x, label: "" + x }))
    .reverse();
  const { billData, pagination, loading } = useSelectorRoot(
    (state) => state.bill
  );
  const [load, setLoad] = useState(loading);
  const { overviewData } = useSelectorRoot((state) => state.overview);
  const { isSuccess } = useSelectorRoot((state) => state.login);
  const [length, setLength] = useState(pagination?.total ?? 10);
  const [exportBill, setExportBill] = useState<Bill[]>([]);
  const [searchText, setSearchText] = useState<string>();
  const [size, setSize] = useState(pagination?.pageSize ?? 10);
  const [currentPage, setCurrentPage] = useState(1);
  const [openPopupPint, setOpenPopupPrint] = useState(false);
  const [pagePrint, setPagePrint] = useState(1);
  const [selectedWard, setSelectedWard] = useState<string>();
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const { ward, area } = useSelectorRoot((state) => state.location);
  const [listWard, setListWard] = useState(
    ward?.map((x) => ({ label: x.attributes.name, key: x.attributes.name }))
  );
  const [data, setData] = useState(billData);
  const [selectedStatus, setSelectedStatus] = useState<{
    key: string;
    label: string;
  }>();
  const [listArea, setListArea] = useState(
    area?.map((x) => ({ label: x.attributes.name, key: x.attributes.name }))
  );
  const [selectedArea, setSelectedArea] = useState<string>();
  const currentMonth = new Date().getMonth() + 1;
  const currentDay = new Date().getDate();
  const previousMonth = currentMonth == 1 ? 12 : currentMonth;
  const displayMonth = currentDay < 10 ? previousMonth : currentMonth;
  const headers = [
    { label: "Họ và tên", key: "clientName" },
    { label: "Mã KH", key: "clientCode" },
    { label: "Mã số thuế", key: "clientTaxCode" },
    { label: "Địa chỉ", key: "clientAddress" },
    { label: "Hình thức thanh toán", key: "paymentMethod" },
    { label: "Mục đích sử dụng", key: "reason" },
    { label: "Chi tiết", key: "detail" },
    { label: "Từ ngày", key: "previousClosingDate" },
    { label: "Đến ngày", key: "closingDate" },
    { label: "CS mới", key: "endingQuantity" },
    { label: "CS cũ", key: "startingQuantity" },
    { label: "Tiêu thụ (m3)", key: "usage" },
    { label: "Đơn giá", key: "unitPrice" },
    { label: "Tiền trước thuế", key: "totalMoneyExcludeTax" },
    { label: "Tiền thuế GTGT", key: "VATMoney" },
    { label: "Tiền thuế phí môi trường", key: "cleaningMoney" },
    { label: "Thuế suất GTGT", key: "VATRate" },
    { label: "Phí môi trường", key: "cleaningFee" },
    { label: "Tổng số tiền", key: "totalMoney" },
    { label: "Thành chữ", key: "totalMoneyInWords" },
    { label: "Đã thanh toán", key: "paidMoney" },
    { label: "Còn nợ", key: "deptMoney" },
    { label: "Trạng thái", key: "status" },
  ];
  const print = (clientId: number) => {
    var bill = data?.find((x) => x.clientId == clientId);
    let url = `print`;
    if (bill) {
      var param = convertBillToUrlParam(bill);
      var win: Window | null = window.open(`${url}?${param.toString()}`);
      if (win) {
        win.onload = () => {
          win?.print();
        };
      }
    }
  };
  const columns: ColumnsType<Bill> = [
    {
      title: "Khách hàng",
      dataIndex: "clientName",
      key: "clientName",
      render: (text, item) => (
        <>
          <div>
            <Text style={{ color: "#2222225A" }}>{text}</Text>
          </div>
          <div>
            <Text style={{ color: "#2222225A" }}>{item.clientCode}</Text>
          </div>
        </>
      ),
    },
    {
      title: "Khu vực",
      dataIndex: "zone",
      key: "zone",
      render: (text) => (
        <>
          <Text style={{ color: "#2222225A" }}>{text}</Text>
        </>
      ),
    },
    {
      title: "Phường/xã/thị trấn",
      dataIndex: "ward",
      key: "ward",
      render: (text) => (
        <>
          <Text style={{ color: "#2222225A" }}>{text}</Text>
        </>
      ),
    },
    {
      title: "Huyện",
      dataIndex: "district",
      key: "district",
      render: (text) => (
        <>
          <Text style={{ color: "#2222225A" }}>{text}</Text>
        </>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "totalMoney",
      key: "totalMoney",
      render: (text) => (
        <>
          <Text style={{ color: "#2222225A" }}>
            {Utils.formatVNDCash(String(text))}
          </Text>
        </>
      ),
    },
    {
      title: "Tình trạng",
      key: "status",
      dataIndex: "status",
      render: (item, { status }) => (
        <>
          <Tag
            color={
              status === 2 ? "geekblue" : status === 1 ? "green" : "volcano"
            }
            key={item.clientName + item.status}
          >
            {status === 2
              ? "Đã thanh toán"
              : status === 1
              ? "Chưa thanh toán"
              : "Chưa chốt số"}
          </Tag>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "clientId",
      key: "clientId",
      render: (clientId) => (
        <>
          <Button
            type="primary"
            icon={<ContainerOutlined />}
            ghost
            onClick={() => {
              print(clientId);
            }}
          ></Button>
        </>
      ),
    },
  ];

  const onSearch = (value: string) => {
    setSearchText(value);
    dispatch(
      getBill({
        month: selectedMonth,
        year: selectedYear,
        page: currentPage,
        ward: selectedWard ?? "",
        status: selectedStatus?.key ?? "",
        search: value,
        size: size,
      })
    );
  };
  useEffect(() => {
    setLoad(loading);
  }, [loading]);

  useEffect(() => {
    setListWard(
      ward
        ?.map((x) => ({ label: x.attributes.name, key: x.id.toString() }))
        .concat([{ label: "Tất cả", key: "-1" }])
    );
  }, [ward]);
  useEffect(() => {
    setListArea(
      area
        ?.map((x) => ({ label: x.attributes.name, key: x.id.toString() }))
        .concat([{ label: "Tất cả", key: "-1" }])
    );
  }, [area]);
  useEffect(() => {
    setListArea(
      area
        ?.filter((x) => x.attributes.ward.data.id.toString() == selectedWard)
        .map((x) => ({ label: x.attributes.name, key: x.id.toString() }))
        .concat([{ label: "Tất cả", key: "-1" }])
    );
  }, [area, selectedWard]);
  useEffect(() => {
    dispatch(
      getBill({
        month: selectedMonth,
        year: selectedYear,
        page: currentPage,
        ward: selectedWard ?? "",
        status: selectedStatus?.key ?? "",
        search: searchText,
        size: size,
      })
    );
    dispatch(getOverview());
  }, [isSuccess]);
  useEffect(() => {
    setData(billData);
  }, [billData]);
  useEffect(() => {
    setLength(pagination?.total ?? 10);
    setSize(pagination?.pageSize ?? 10);
  }, [pagination]);
  const onExportBill = () => {
    var conutPage = pagination?.pageCount ?? 1;
    if (billExport.length < (pagination?.total ?? 0)) {
      setLoad(true);
      BillAPI.get({
        month: selectedMonth,
        year: selectedYear,
        page: currentPage,
        ward: selectedWard ?? "",
        status: selectedStatus?.key ?? "",
        search: searchText,
        size: 500,
      }).subscribe(async (json) => {
        conutPage = json?.meta.pagination.pageCount ?? 1;
        billExport = json?.data ?? [];

        for (var i = 2; i < conutPage + 1; i++) {
          var data = await BillAPI.get({
            month: selectedMonth,
            year: selectedYear,
            page: i,
            village: selectedArea,
            ward: selectedWard ?? "",
            status: selectedStatus?.key ?? "",
            search: searchText,
            size: 500,
          }).toPromise();
          billExport = billExport.concat(data?.data ?? []);
          console.log(billExport.length);
        }
        setExportBill(billExport);
        setTimeout(() => {
          setLoad(false);
          document.getElementById("exportCXV")?.click();
        }, 1000);
      });
    }
  };
  const onPrintInvoice = (page: number) => {
    setLoad(true);
    if (listPrintedPage.indexOf(page) < 0) listPrintedPage.push(page);
    BillAPI.get({
      month: selectedMonth,
      year: selectedYear,
      page: page,
      ward: selectedWard ?? "",
      status: selectedStatus?.key ?? "",
      search: searchText,
      size: 400,
    }).subscribe(async (json) => {
      setLoad(false);
      billExport = json?.data ?? [];
      console.log(billExport.length);
      if (billExport.length > 0) {
        var htmlValue = "";
        for (var bill of billExport) {
          htmlValue += convertBillToHtml(bill, invoiceHtml);
        }
        console.log(billExport.length);
        var dataHtml = HTMLBase(htmlValue);
        var newWindows = window.open();
        console.log("print");
        if (newWindows) {
          newWindows.document.write(dataHtml);
          newWindows.document.close();
          newWindows?.print();
          newWindows?.focus();
          newWindows.onafterprint = () => {
            newWindows?.close();
          };
        }

        // if (htmlElement) {
        //   onPrint(htmlElement);
        // }
      }
    });
  };
  return (
    <Content style={{ padding: "0px 0px 0px 15px" }}>
      <Spin spinning={load}>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card style={{ margin: "13px 10px 13px 0px" }}>
              <Text style={{ color: "#222222AA" }}>
                {"Khách chưa chốt tháng " + displayMonth}
              </Text>
              <br />
              <Text style={{ fontWeight: 700, fontSize: 22 }}>
                {overviewData?.unRecordedWaterBillQuantity ?? 0}
              </Text>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card style={{ margin: "13px 0px 13px 10px" }}>
              <Text style={{ color: "#222222AA" }}>
                {"Khách chưa thanh toán tháng " + displayMonth}
              </Text>
              <br />
              <Text style={{ fontWeight: 700, fontSize: 22 }}>
                {overviewData?.unpaidClientQuantity ?? 0}
              </Text>
            </Card>
          </Col>
        </Row>
        <Card
          title={
            <>
              <Row style={{ margin: "20px 8px 16px 0px" }}>
                <Text style={{ fontSize: 22, fontWeight: 600 }}>
                  {"Có " + length + " khách hàng"}
                </Text>
              </Row>
              <Row style={{ margin: "20px 8px 16px 0px" }}>
                <DropdownComponent
                  items={{
                    items: years,
                    onClick: (value) => {
                      setSelectedYear(Number(value.key));
                      dispatch(
                        getBill({
                          month: selectedMonth,
                          year: Number(value.key),
                          page: currentPage,
                          ward: selectedWard ?? "",
                          status: selectedStatus?.key ?? "",
                          search: searchText,
                          size: size,
                        })
                      );
                    },
                  }}
                  label={"Chọn năm"}
                  value={selectedYear}
                  flex={1}
                ></DropdownComponent>
                <DropdownComponent
                  items={{
                    items: months,
                    onClick: (value) => {
                      setSelectedMonth(Number(value.key));
                      dispatch(
                        getBill({
                          month: Number(value.key),
                          year: selectedYear,
                          page: currentPage,
                          ward: selectedWard ?? "",
                          village: selectedArea,
                          status: selectedStatus?.key ?? "",
                          search: searchText,
                          size: size,
                        })
                      );
                    },
                  }}
                  label={"Chọn tháng"}
                  value={selectedMonth}
                  flex={1}
                ></DropdownComponent>
                <DropdownComponent
                  items={{
                    items: listWard,
                    onClick: (value) => {
                      setSelectedWard(value.key);
                      dispatch(
                        getBill({
                          month: selectedMonth,
                          year: selectedYear,
                          page: currentPage,
                          ward: value.key ?? "",
                          village: selectedArea,
                          status: selectedStatus?.key ?? "",
                          search: searchText,
                          size: size,
                        })
                      );
                    },
                  }}
                  label={"Chọn Xã"}
                  flex={1}
                ></DropdownComponent>
                <DropdownComponent
                  items={{
                    items: listArea,
                    onClick: (value) => {
                      setSelectedArea(value.key);
                      dispatch(
                        getBill({
                          month: selectedMonth,
                          year: selectedYear,
                          page: currentPage,
                          ward: selectedWard,
                          village: value.key ?? "",
                          status: selectedStatus?.key ?? "",
                          search: searchText,
                          size: size,
                        })
                      );
                    },
                  }}
                  label={"Chọn thôn"}
                  flex={1}
                ></DropdownComponent>
                <DropdownComponent
                  items={{
                    items: items,
                    onClick: (value) => {
                      setSelectedStatus({
                        label: value.label as string,
                        key: value.key,
                      });
                      dispatch(
                        getBill({
                          month: selectedMonth,
                          year: selectedYear,
                          page: currentPage,
                          ward: selectedWard ?? "",
                          village: selectedArea,
                          status: value.key ?? "-1",
                          search: searchText,
                          size: size,
                        })
                      );
                    },
                  }}
                  label={"Chọn trạng thái"}
                  flex={1}
                ></DropdownComponent>
              </Row>
            </>
          }
        >
          <Row align={"middle"}>
            <Search
              placeholder="Nhập từ khóa tìm kiếm"
              allowClear
              size="large"
              onSearch={onSearch}
              style={{ flex: 1, paddingRight: "16px" }}
            />
            {/* <DropdownComponent items={{items: listSize, onClick: (value) => {setSize(Number(value.key))}}} label={'Số phần tử'} flex={0.2}></DropdownComponent> */}
            <div>
              <Button
                type="primary"
                icon={<ContainerOutlined />}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 10,
                }}
                onClick={() => setOpenPopupPrint(true)}
              >
                {"In hóa đơn"}
              </Button>
              <Button
                type="primary"
                style={{ justifyContent: "center", alignItems: "center" }}
                onClick={onExportBill}
              >
                <Row>
                  <ReactSVG
                    src={exportSvg}
                    style={{ marginRight: 5, width: 18, height: 18 }}
                  ></ReactSVG>
                  {"Xuất file"}
                </Row>
              </Button>
              <CSVLink
                style={{ display: "none" }}
                headers={headers}
                filename={`Tình trạng đo nước${
                  selectedMonth ? " " + selectedMonth : ""
                }${selectedYear ? "/" + selectedYear : ""}${
                  selectedWard ? " " + selectedWard : ""
                }${selectedArea ? " " + selectedArea : ""}.csv`}
                data={
                  exportBill?.map((x) => ({
                    ...x,
                    detail: "Tiền nước",
                    reason: "KDLS",
                    paymentMethod: "Tiền mặt/Chuyển khoản",
                    totalMoneyInWords:
                      x.totalMoney > 0
                        ? Utils.convertVNDCurrencyToWords(x.totalMoney)
                        : "",
                    status: items?.find((y) => y?.key == x.status.toString())
                      ?.label,
                  })) ?? []
                }
                id="exportCXV"
              />
            </div>
          </Row>
          <Row
            style={{ paddingTop: "20px", minHeight: "55vh", paddingBottom: 20 }}
          >
            <Table
              scroll={{ x: "60vh", y: "50vh" }}
              style={{ flex: 1 }}
              columns={columns}
              dataSource={data ?? []}
              pagination={false}
            />
          </Row>
          <Row style={{ justifyContent: "flex-end", flex: 1 }}>
            <Pagination
              defaultCurrent={1}
              total={length}
              pageSize={size}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setSize(pageSize);
                dispatch(
                  getBill({
                    month: selectedMonth,
                    year: selectedYear,
                    page: page,
                    size: pageSize,
                    ward: selectedWard ?? "",
                    village: selectedArea,
                    status: selectedStatus?.key ?? "",
                    search: searchText,
                  })
                );
              }}
              style={{ justifyContent: "flex-end" }}
            ></Pagination>
          </Row>
        </Card>
      </Spin>
      <Modal
        title="Tiến trình in"
        open={openPopupPint}
        onOk={() => {
          onPrintInvoice(pagePrint);
        }}
        onCancel={() => {
          setOpenPopupPrint(false);
        }}
      >
        <p>Tổng: {listPrintedPage.length}/{Math.round( (pagination?.total ?? 0) / 400)}</p>
        <p>{`Lượt đã in ${listPrintedPage.join()}; `} </p>
        <InputNumber
          min={1}
          max={(pagination?.total ?? 0) + 1}
          defaultValue={pagePrint}
          onChange={(index) => setPagePrint(index ?? 1)}
        />
      </Modal>
    </Content>
  );
};
const items = [
  {
    label: "Tất cả",
    key: "-1",
  },
  {
    label: "Đã thanh toán",
    key: "2",
  },
  {
    label: "Chưa chốt số",
    key: "0",
  },
  {
    label: "Chưa thanh toán",
    key: "1",
  },
];
