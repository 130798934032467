import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BillAPI, { Bill, BillDataResponse, BillResponse, BillYearResponse, QueryData } from "../../api/Bill.api";
import { Pagination, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface BillState {
    loading: boolean;
    billData: Bill[]| null;
    billRevenueMonth: BillDataResponse[] | null;
    billRevenueYear: BillYearResponse[] | null;
    billWaterUsageMonth: BillDataResponse[] | null;
    billWaterUsageYear: BillYearResponse[] | null;
    errorMessage: string | null;
    pagination: Pagination | null;
}

const initState: BillState = {
    loading: false,
    billData: null,
    pagination: null,
    billRevenueMonth: null,
    billRevenueYear: null,
    billWaterUsageMonth: null,
    billWaterUsageYear: null,
    errorMessage: null
};
const billSlice = createSlice({
    name: 'bill',
    initialState: initState,
    reducers: {
        getBill(state, action: PayloadAction<QueryData>) {
            state.loading = true;
        },
        getBillSuccess(state, action: PayloadAction<BillResponse>) {
            state.loading = false;
            state.billData = action.payload.data;
            state.pagination = action.payload.meta.pagination;
        },
        getBillRevenueMonth(state, action: PayloadAction<{year: number, ward?: number}>) {
            state.loading = true;
        },
        getBillRevenueMonthSuccess(state, action: PayloadAction<BillDataResponse[]>) {
            state.loading = false;
            state.billRevenueMonth = action.payload;
        },
        getBillRevenueYear(state, action: PayloadAction<{start: number, end:number, ward?: number}>) {
            state.loading = true;
        },
        getBillRevenueYearSuccess(state, action: PayloadAction<BillYearResponse[]>) {
            state.loading = false;
            state.billRevenueYear = action.payload;
        },
        getBillWaterUsageMonth(state, action: PayloadAction<{year: number, ward?: number}>) {
            state.loading = true;
        },
        getBillWaterUsageMonthSuccess(state, action: PayloadAction<BillDataResponse[]>) {
            state.loading = false;
            state.billWaterUsageMonth = action.payload;
        },
        getBillWaterUsageYear(state, action: PayloadAction<{start: number, end:number, ward?: number}>) {
            state.loading = true;
        },
        getBillWaterUsageYearSuccess(state, action: PayloadAction<BillYearResponse[]>) {
            state.loading = false;
            state.billWaterUsageYear = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const bill$: RootEpic = (action$) =>
    action$.pipe(
        filter(getBill.match),
        switchMap((re) => {
            return BillAPI.get(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        billSlice.actions.getBillSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    billSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

const billRevenueMonth$: RootEpic = (action$) =>
    action$.pipe(
        filter(getBillRevenueMonth.match),
        switchMap((re) => {
            return BillAPI.getRevenueMonth(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        billSlice.actions.getBillRevenueMonthSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    billSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const billRevenueYear$: RootEpic = (action$) =>
    action$.pipe(
        filter(getBillRevenueYear.match),
        switchMap((re) => {
            return BillAPI.getRevenueYear(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        billSlice.actions.getBillRevenueYearSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    billSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const billWaterUsageMonth$: RootEpic = (action$) =>
    action$.pipe(
        filter(getBillWaterUsageMonth.match),
        switchMap((re) => {
            return BillAPI.getWaterUsageMonth(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        billSlice.actions.getBillWaterUsageMonthSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    billSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const billWaterUsageYear$: RootEpic = (action$) =>
    action$.pipe(
        filter(getBillWaterUsageYear.match),
        switchMap((re) => {
            return BillAPI.getWaterUsageYear(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        billSlice.actions.getBillWaterUsageYearSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    billSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const BillEpics = [bill$, billRevenueMonth$, billWaterUsageMonth$, billRevenueYear$, billWaterUsageYear$];
export const {
    getBill,
    getBillSuccess,
    getBillRevenueMonth,
    getBillRevenueMonthSuccess,
    getBillWaterUsageMonth,
    getBillWaterUsageMonthSuccess,
    getBillRevenueYear,
    getBillRevenueYearSuccess,
    getBillWaterUsageYear,
    getBillWaterUsageYearSuccess
} = billSlice.actions;
export const billReducer = billSlice.reducer;