import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  MenuProps,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ExportBill, MaterialItem } from "../../../api/ExportBill.api";
import { DropdownComponent } from "../../../components/Dropdown/DropdownComponent";
import {
  createExportBill,
  getExportBill,
} from "../../../store/controls/ExportBill.slice";
import {
  getMaterial,
  getWarehouse,
} from "../../../store/controls/Warehouse.slice";
import { useSelectorRoot } from "../../../store/store";
import { EditOutlined, ContainerOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Material } from "../../../api/Warehouse.api";
import { printExportBill } from "../../print/PrintExportBill";
const { Text } = Typography;
const { Search } = Input;
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};
export const ExportBillScreen = (props: { typeBill: number }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { ExportBillData, loading } = useSelectorRoot(
    (state) => state.exportBill
  );
  const { user } = useSelectorRoot((state) => state.login);
  const { WarehouseData, MaterialData } = useSelectorRoot(
    (state) => state.warehouse
  );
  const [load, setLoad] = useState(loading);
  const [length, setLength] = useState(
    ExportBillData?.meta?.pagination.total ?? 10
  );
  const [searchText, setSearchText] = useState<string>();
  const [size, setSize] = useState(
    ExportBillData?.meta?.pagination.pageSize ?? 10
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<ExportBill[]>([]);
  const [selectedHouse, setSelectedHouse] = useState<number>(0);
  const [isPopupDetail, setIsPopupDetail] = useState(false);
  const [initValue, setInitValue] = useState<any>();
  const [selectedMaterials, setSelectedMaterials] = useState<MaterialItem[]>(
    []
  );
  const [showForm, setShowForm] = useState(true);
  const [selectedExportBill, setSelectedExportBill] =
    useState<ExportBill | null>();
  const [houses, setHouses] = useState<MenuProps["items"]>(
    WarehouseData?.data
      .map((x) => ({
        key: "" + x.id,
        label: "" + x.attributes?.wareHouseName,
      }))
      .concat([{ key: "0", label: "Tất cả kho" }]) ?? [
      { key: "0", label: "Tất cả" },
    ]
  );
  const [rangeDates, setRangeDates] = useState<Date[]>([]);
  const columns: ColumnsType<ExportBill> = [
    {
      title: "Tên kho",
      dataIndex: "wareHouseName",
      key: "wareHouseName",
      render: (text, item) => (
        <>
          <div>
            <Text style={{ color: "#2222225A" }}>{item.wareHouseName}</Text>
          </div>
        </>
      ),
    },
    {
      title: props.typeBill == 2 ? "Ngày xuất" : "Ngày nhập",
      dataIndex: "materialName",
      key: "materialName",
      render: (text, item) => (
        <>
          <Text style={{ color: "#2222225A" }}>
            {moment(item.publishedAt).format("DD/MM/YYYY")}
          </Text>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (text, item) => (
        <Space size="middle" style={{ justifyContent: "right" }}>
          <Tooltip title={'In hoá đơn'}>
          <Button
                type="primary"
                icon={<ContainerOutlined />}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 10,
                }}
                onClick={() => {
                  selectedRow(item)
                  printExportBill([item],"PX124396", 0, 0 , props.typeBill);
                }}
              >
          </Button>
          </Tooltip>
          <Tooltip title={'Chi tiết'}>
          <Button
            style={{ color: "#2222225A" }}
            icon={<EditOutlined />}
            onClick={() => {
              selectedRow(item)
              setIsPopupDetail(true);
              setShowForm(false);
              setTimeout(() => {
                setShowForm(true)
              }, 500)
            }}
          ></Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onSearch = (value: string) => {
    setSearchText(value);
    dispatch(
      getExportBill({
        page: currentPage,
        size: size,
        search: value,
        type: props.typeBill,
        startDate: rangeDates[0],
        endDate: rangeDates[1],
      })
    );
  };
  useEffect(() => {
    setLoad(loading);
  }, [loading]);
  useEffect(() => {
    dispatch(
      getExportBill({
        page: currentPage,
        size: size,
        search: searchText,
        warehouseId: selectedHouse,
        type: props.typeBill,
        startDate: rangeDates[0],
        endDate: rangeDates[1],
      })
    );
    dispatch(getWarehouse());
    dispatch(getMaterial());
  }, []);

  useEffect(() => {
    setHouses(
      WarehouseData?.data
        .map((x) => ({
          key: "" + x.id,
          label: "" + x.attributes?.wareHouseName,
        }))
        .concat([{ key: "0", label: "Tất cả kho" }]) ?? [
        { key: "0", label: "Tất cả kho" },
      ]
    );
  }, [WarehouseData]);

  useEffect(() => {
    setLength(ExportBillData?.meta.pagination?.total ?? 10);
    setSize(ExportBillData?.meta.pagination?.pageSize ?? 10);
    setData(ExportBillData?.data.map((x) => ({...x.attributes, id: x.id})) ?? []);
  }, [ExportBillData]);
  const selectedRow = (item: ExportBill) => {
    let initForm = {
      ...(selectedExportBill ?? {}),
      PublishedAt: dayjs(
        dayjs(
          moment(selectedExportBill?.publishedAt).format("DD/MM/YYYY"),
          "DD/MM/YYYY"
        )
      ),
    }
    setInitValue(initForm);
    setSelectedExportBill(item);
    setSelectedMaterials(item.materialItems);
  }
  return (
    <Content style={{ padding: "0px 0px 0px 15px" }}>
      <Spin spinning={load}>
        <Card
          title={
            <>
              <Row style={{ margin: "20px 8px 16px 0px" }}>
                <Text style={{ fontSize: 22, fontWeight: 600 }}>
                  {"Có " + length + " vật tư"}
                </Text>
                <div style={{ flex: 1 }}></div>
                <Button
                  type="primary"
                  style={{marginRight: 10}}
                  onClick={() => {
                    setInitValue({});
                    setSelectedExportBill(null);
                    setSelectedMaterials([]);
                    setIsPopupDetail(true);
                    setShowForm(false);
                    setTimeout(() => {
                      setShowForm(true)
                    }, 500)
                  }}
                >
                  Tạo phiếu
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    printExportBill(data,"PX124396", 0, 0 , props.typeBill);
                  }}
                >
                  In phiếu
                </Button>
              </Row>
            </>
          }
        >
          <Row align={"middle"} style={{ marginBottom: 20 }}>
            <Search
              placeholder="Nhập từ khóa tìm kiếm"
              allowClear
              size="large"
              onSearch={onSearch}
              style={{ flex: 1, paddingRight: "16px" }}
            />
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Text style={{ marginRight: 20 }}>Ngày tạo phiếu</Text>
            <RangePicker style={{ marginRight: 20 }} onChange={(value) =>{
              var dateRange: Date[] = [];
              if (value?.[0]){
                dateRange.push(moment(moment(value?.[0]?.toDate()).utc(true).format('DD/MM/YYYY') + ' 00:00:00', 'DD/MM/YYYY hh:mm:ss').utc(true).toDate())
              }
              if (value?.[1]){
                dateRange.push(moment(moment(value?.[1]?.toDate()).utc(true).format('DD/MM/YYYY') + ' 23:59:59', 'DD/MM/YYYY hh:mm:ss').utc(true).toDate())
              }
              setRangeDates(dateRange);
              dispatch(
                getExportBill({
                  page: currentPage,
                  size: size,
                  search: searchText,
                  warehouseId: selectedHouse,
                  type: props.typeBill,
                  startDate: value?.[0]?.toDate(),
                  endDate: value?.[1]?.toDate(),
                })
              );
            }}/>
            <DropdownComponent
              items={{
                items: houses,
                onClick: (value) => {
                  setSelectedHouse(Number(value.key));
                  dispatch(
                    getExportBill({
                      page: currentPage,
                      size: size,
                      search: searchText,
                      warehouseId: Number(value.key),
                      type: props.typeBill,
                      startDate: rangeDates[0],
                      endDate: rangeDates[1],
                    })
                  );
                },
              }}
              label={"Chọn kho"}
              value={
                WarehouseData?.data.find((x) => x?.id == selectedHouse)
                  ?.attributes?.wareHouseName ?? "Tất cả kho"
              }
              flex={1}
            ></DropdownComponent>
          </Row>
          <Row
            style={{ paddingTop: "20px", minHeight: "55vh", paddingBottom: 20 }}
          >
            <Table
              scroll={{ x: "60vh", y: "50vh" }}
              style={{ flex: 1 }}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </Row>
          <Row style={{ justifyContent: "flex-end", flex: 1 }}>
            <Pagination
              defaultCurrent={1}
              total={length}
              pageSize={size}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setSize(pageSize);
                dispatch(
                  getExportBill({
                    page: page,
                    size: pageSize,
                    search: searchText,
                    warehouseId: selectedHouse,
                    type: props.typeBill,
                    startDate: rangeDates[0],
                    endDate: rangeDates[1],
                  })
                );
              }}
              style={{ justifyContent: "flex-end" }}
            ></Pagination>
          </Row>
        </Card>
      </Spin>
      <Modal
        title="Thông tin phiếu"
        open={isPopupDetail}
        okButtonProps={
          {
            disabled: !!selectedExportBill
          }
        }
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setIsPopupDetail(false);
        }}
        width="70%"
      >
        {showForm ? (
          <Form
            form={form}
            initialValues={initValue}
            onFinish={(value) => {
              let body = {
                body: {
                  type: props.typeBill,
                  employeeName: user?.fullName ?? "",
                  reason: value.reason,
                  wareHouseId: value.wareHouseId,
                  wareHouseName: WarehouseData?.data.find(x => x.id == value.wareHouseId)?.attributes?.wareHouseName,
                  materialItems: selectedMaterials.map(x => ({
                    materialWarehouseId: x.materialWarehouseId,
                    requestQuantity: x.requestQuantity,
                    realQuantity: x.realQuantity
                  })),
                },
                query: {
                  page: currentPage,
                  size: size,
                  search: searchText,
                  warehouseId: selectedHouse,
                  type: props.typeBill,
                },
              };
              dispatch(createExportBill(body));
              setIsPopupDetail(false);
            }}
          >
            <Row style={{ flex: 1 }}>
              <Col style={{ marginRight: 20, marginBottom: 20, minWidth: '4 0%'}}>
                <Card style={{ height: "100%" }} bodyStyle={{ height: "100%" }}>
                  <div style={{ height: "100%" }}>
                    <div style={{ height: "90%"}}>
                      <Form.Item label="Chọn kho" name="wareHouseId">
                        <Select
                          defaultValue={selectedExportBill?.wareHouseId}
                          options={WarehouseData?.data.map((x) => ({
                            value: x.id?.toString(),
                            label: x.attributes?.wareHouseName,
                          }))}
                        ></Select>
                      </Form.Item>
                      <Form.Item label="Chọn vật tư" name="material">
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ maxWidth: 400 }}
                          defaultValue={selectedExportBill?.materialItems.map(
                            (x) => x.materialId
                          )}
                          options={MaterialData?.data.map((x) => ({
                            value: x.id?.toString(),
                            label: x.attributes?.materialName,
                          }))}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div
                      style={{ justifyContent: "flex-end", display: "flex" }}
                    >
                      <Button
                        type="primary"
                        disabled={!!selectedExportBill}
                        onClick={() => {
                          var metarialItemDatas: string[] =
                            form.getFieldValue("material");
                          let items: Material[] =
                            MaterialData?.data
                              .filter((x) =>
                                metarialItemDatas.includes(
                                  x.id?.toString() ?? ""
                                )
                              )
                              .map(
                                (x) =>
                                  ({ ...x.attributes, id: x.id } as Material)
                              ) ?? [];
                          setSelectedMaterials(
                            items.map(
                              (x) =>
                                ({
                                  materialId: x.id,
                                  materialWarehouseId: form.getFieldValue('wareHouseId'),
                                  materialName: x.materialName,
                                  materialCode: x.materialCode,
                                  materialUnit: x.unit,
                                  materialPrice: x.materialPrice,
                                  realQuantity:
                                    selectedExportBill?.materialItems.find(
                                      (y) => y.materialId == x.id
                                    )?.realQuantity ?? 0,
                                } as MaterialItem)
                            )
                          );
                        }}
                      >
                        Nhập vật tư đã chọn
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col>
                <Row>
                  <Card
                    style={{ width: 600, marginBottom: 20 }}
                    title={"Thông tin"}
                  >
                    <Form.Item
                      label={
                        "Chọn ngày " + (props.typeBill == 2 ? "xuất" : "nhập")
                      }
                      name="PublishedAt"
                    >
                      <DatePicker format={"DD/MM/YYYY"} />
                    </Form.Item>
                    <Form.Item name="employeeName" label="Tên người nhận">
                      <Input placeholder="Nhập tên" />
                    </Form.Item>
                    <Form.Item name="wareHouseAddress" label="Địa chỉ bộ phận">
                      <Input placeholder="Nhập địa chỉ" />
                    </Form.Item>
                    <Form.Item name="reason" label="Lý do xuất vật tư">
                      <Input.TextArea placeholder="Nhập lý do" />
                    </Form.Item>
                  </Card>
                </Row>
                <Row>
                  <Card
                    style={{ width: 600, maxHeight: "50%" }}
                    title={"Vật tư"}
                  >
                    <Form.Item name="materialItems">
                      <Row>
                        <Col>
                          {selectedMaterials.map((x) => (
                            <Row style={{ height: 40 }}>
                              <Text
                                style={{ wordWrap: "break-word", width: 300 }}
                              >
                                {x.materialName}
                              </Text>
                            </Row>
                          ))}
                        </Col>
                        <Col>
                          {selectedMaterials.map((x) => (
                            <Row style={{ height: 40 }}>
                              <InputNumber
                                defaultValue={x.realQuantity}
                                addonAfter={x.materialUnit}
                                onChange={(value) => {
                                  selectedMaterials.forEach((y) => {
                                    if (x.id == y.id) {
                                      y.realQuantity = Number(value);
                                      y.requestQuantity = Number(value);
                                    }
                                  });
                                }}
                              ></InputNumber>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </Form.Item>
                  </Card>
                </Row>
              </Col>
            </Row>
          </Form>
        ) : (
          <></>
        )}
      </Modal>
    </Content>
  );
};
