import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Pagination } from "../common/define-types";
export interface Bill {
    clientName: string,
    city: string ,
    clientPhone: string,
    clientAddress: string,
    clientCode: string,
    clientTaxCode: string,
    district: string ,
    ward: string,
    village: string ,
    totalMoney: number,
    paidMoney: number,
    deptMoney: number,
    zone: string,
    totalMoneyExcludeTax: number,
    VATMoney: number,
    cleaningMoney: number,
    previousClosingDate?: Date,
    closingDate?: Date,
    startingQuantity: number,
    endingQuantity: number,
    usage: number,
    unitPrice: number,
    status: number,
    VATRate: string,
    cleaningFee: string,
    clientId: number,
    gaugesCode: string
}
export interface BillResponse {
    data: Bill[],
    meta: {
        pagination: Pagination
    }
}
export interface BillDataResponse {
    month: string,
    value: number
}
export interface BillYearResponse {
   year: string, value: number
}
export interface QueryData {
    month?: number, 
    year?: number, 
    size?: number, 
    page?: number,
    status?: string,
    ward?: string,
    village?: string,
    search?: string
}
export default class BillAPI {
    static host = SYSTEM_CONSTANTS.HOST;
    static get(data: QueryData): Observable<BillResponse | null> {
        var api = `${BillAPI.host}/${SYSTEM_CONSTANTS.BILL.GET}?month=${data.month}&year=${data.year}&pagination[pageSize]=${data.size}&pagination[page]=${data.page}`;
        if (data.status && data.status != '-1') {
            api = api + '&status=' + data.status
        }
        if (data.ward && data.ward != '-1') {
            api = api + '&ward=' + data.ward
        }
        if (data.village && data.village != '-1') {
            api = api + '&village=' + data.village
        }
        if(data.search) {
            api = api + '&keyword=' + data.search
        }
        
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as BillResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static getRevenueMonth(data: {year: number, ward?: number}): Observable<BillDataResponse[] | null> {
        var api = `${BillAPI.host}/${SYSTEM_CONSTANTS.BILL.GET_REVENUE_MONTH}?year=${data.year}`;
        if (data.ward && data.ward != -1) {
            api = api + '&ward=' + data.ward
        }
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as BillDataResponse[]) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static getRevenueYear(data: {start: number, end: number, ward?: number}): Observable<BillYearResponse[] | null> {
        var api = `${BillAPI.host}/${SYSTEM_CONSTANTS.BILL.GET_REVENUE_YEAR}?startYear=${data.start}&endYear=${data.end}`;
        if (data.ward && data.ward != -1) {
            api = api + '&ward=' + data.ward
        }
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as BillYearResponse[]) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static getWaterUsageMonth(data: {year: number, ward?: number}): Observable<BillDataResponse[] | null> {
        var api = `${BillAPI.host}/${SYSTEM_CONSTANTS.BILL.GET_WATER_USAGE_MONTH}?year=${data.year}`;
        if (data.ward && data.ward != -1) {
            api = api + '&ward=' + data.ward
        }
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as BillDataResponse[]) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static getWaterUsageYear(data:{start: number, end: number, ward?: number}): Observable<BillYearResponse[] | null> {
        var api = `${BillAPI.host}/${SYSTEM_CONSTANTS.BILL.GET_WATER_USAGE_YEAR}?startYear=${data.start}&endYear=${data.end}`;
        if (data.ward && data.ward != -1) {
            api = api + '&ward=' + data.ward
        }
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as BillYearResponse[]) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}