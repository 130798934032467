import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import OverviewAPI, { OverviewResponse } from "../../api/overview.api";
import { RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface OverviewState {
    loading: boolean;
    overviewData: OverviewResponse| null
    errorMessage: string | null;
}

const initState: OverviewState = {
    loading: false,
    overviewData: null,
    errorMessage: null
};
const overviewSlice = createSlice({
    name: 'overview',
    initialState: initState,
    reducers: {
        getOverview(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getOverviewSuccess(state, action: PayloadAction<OverviewResponse>) {
            state.loading = false;
            state.overviewData = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const overview$: RootEpic = (action$) =>
    action$.pipe(
        filter(getOverview.match),
        switchMap((re) => {
            return OverviewAPI.get().pipe(
                mergeMap((res: any) => {
                    return [
                        overviewSlice.actions.getOverviewSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    overviewSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const OverviewEpics = [overview$];
export const {
    getOverview,
    getOverviewSuccess,
} = overviewSlice.actions;
export const overviewReducer = overviewSlice.reducer;