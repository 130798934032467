import { Dropdown, Button, Row, Typography, MenuProps } from "antd"
import React, { ReactNode, useEffect, useState } from "react";
import {DownOutlined} from "@ant-design/icons"
const {Text} = Typography;
export interface ItemTypeProp {
    label: ReactNode | string;
    key: string;
    icon?: ReactNode;
    danger?: boolean;
    disabled?: boolean;
  }
export const DropdownComponent = (props :{label: string; value?: number| null | string | ReactNode; items: {onClick: (value: ItemTypeProp) => void; items: MenuProps['items']}; flex?:number, borderWidth?: number}) => {

    const[value, setValue] = useState<number| null | string | ReactNode>(props.value);
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    return (<Dropdown 
        menu={{
            items: props.items.items, 
            onClick: (x) => {
                if (props.items.items){
                    var element = props.items.items.find(y => y?.key == x.key);
                    if (element){
                        setValue((element as ItemTypeProp).label); 
                    }
                    props.items.onClick(element as ItemTypeProp);
                }
                
            }}} 
        dropdownRender={(menu)=> (
            <div style={{overflowY: 'scroll', maxHeight: '50vh'}}>
            {React.cloneElement(menu as React.ReactElement)}
            </div>
        )}
        >
            <Button style={{flex: props.flex, margin: '0px 10px 0px 0px', width: '100%', borderWidth: props.borderWidth === undefined? 1 : props.borderWidth}}>
                <Row style={{justifyContent: 'space-between'}}>
                    <Text style={{color: value ? '#222222' : '#2222225A'}}>{value ?? props.label}</Text>
                    <DownOutlined />
                </Row>
            </Button>
        </Dropdown>)
}