import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import LocationAPI, { Area, LocationResponse } from "../../api/location.api";
import { RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface LocationState {
    loading: boolean;
    province: Area[]| null
    district: Area[]| null
    ward: Area[]| null
    area: Area[]| null
    line: Area[] | null
    priceCatagory: Area[] | null
    errorMessage: string | null;
}

const initState: LocationState = {
    loading: false,
    province: null,
    area: null,
    district: null,
    ward: null,
    errorMessage: null,
    line: null,
    priceCatagory: null
};
const locationSlice = createSlice({
    name: 'location',
    initialState: initState,
    reducers: {
        getProvince(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getProvinceSuccess(state, action: PayloadAction<LocationResponse>) {
            state.loading = false;
            state.province = action.payload.data;
        },
        getDistrict(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getDistrictSuccess(state, action: PayloadAction<LocationResponse>) {
            state.loading = false;
            state.district = action.payload.data;
        },
        getWard(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getWardSuccess(state, action: PayloadAction<LocationResponse>) {
            state.loading = false;
            state.ward = action.payload.data;
        },
        getArea(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getAreaSuccess(state, action: PayloadAction<LocationResponse>) {
            state.loading = false;
            state.area = action.payload.data;
        },
        getLine(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getLineSuccess(state, action: PayloadAction<LocationResponse>) {
            state.loading = false;
            state.line = action.payload.data;
        },
        getPriceCatagory(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getPriceCatagorySuccess(state, action: PayloadAction<LocationResponse>) {
            state.loading = false;
            state.priceCatagory = action.payload.data;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const province$: RootEpic = (action$) =>
    action$.pipe(
        filter(getProvince.match),
        switchMap((re) => {
            return LocationAPI.province().pipe(
                mergeMap((res: any) => {
                    return [
                        locationSlice.actions.getProvinceSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    locationSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const district$: RootEpic = (action$) =>
    action$.pipe(
        filter(getDistrict.match),
        switchMap((re) => {
            return LocationAPI.district().pipe(
                mergeMap((res: any) => {
                    return [
                        locationSlice.actions.getDistrictSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    locationSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const ward$: RootEpic = (action$) =>
    action$.pipe(
        filter(getWard.match),
        switchMap((re) => {
            return LocationAPI.ward().pipe(
                mergeMap((res: any) => {
                    return [
                        locationSlice.actions.getWardSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    locationSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const area$: RootEpic = (action$) =>
    action$.pipe(
        filter(getArea.match),
        switchMap((re) => {
            return LocationAPI.area().pipe(
                mergeMap((res: any) => {
                    return [
                        locationSlice.actions.getAreaSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    locationSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const priseCatagory$: RootEpic = (action$) =>
    action$.pipe(
        filter(getPriceCatagory.match),
        switchMap((re) => {
            return LocationAPI.priseCatagory().pipe(
                mergeMap((res: any) => {
                    return [
                        locationSlice.actions.getPriceCatagorySuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    locationSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const line$: RootEpic = (action$) =>
    action$.pipe(
        filter(getLine.match),
        switchMap((re) => {
            return LocationAPI.line().pipe(
                mergeMap((res: any) => {
                    return [
                        locationSlice.actions.getLineSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    locationSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const LocationEpics = [province$, area$, district$, ward$, line$, priseCatagory$];
export const {
    getProvince,
    getProvinceSuccess,
    getArea,
    getAreaSuccess,
    getDistrict,
    getDistrictSuccess,
    getWard,
    getWardSuccess,
    getLine,
    getPriceCatagory
} = locationSlice.actions;
export const locationReducer = locationSlice.reducer;