import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import reportAPI, { ReportResponse } from "../../api/Report.api";
import { Pagination, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface ReportState {
    loading: boolean;
    reportData: ReportResponse| null
    createreport: ReportResponse | null,
    approvereport: ReportResponse| null,
    rejectSchdule: ReportResponse | null,
    errorMessage: string | null;
    pagination: Pagination | null;
}

const initState: ReportState = {
    loading: false,
    reportData: null,
    errorMessage: null,
    createreport: null,
    rejectSchdule: null,
    approvereport: null,
    pagination: null
};
const reportSlice = createSlice({
    name: 'report',
    initialState: initState,
    reducers: {
        getReport(state, action: PayloadAction<{page: number, size: number, status?: number}>) {
            state.loading = true;
        },
        getReportSuccess(state, action: PayloadAction<ReportResponse>) {
            state.loading = false;
            state.reportData = action.payload;
            state.pagination = action.payload.meta.pagination;
        },
        approveReport(state, action: PayloadAction<{id: string, page: number, size: number, status?: number}>) {
            state.loading = true;
        },
        aprroveReportSuccess(state, action: PayloadAction<ReportResponse>) {
            state.loading = false;
            state.approvereport = action.payload;
        },
        rejectReport(state, action: PayloadAction<{id: string, page: number, size: number, status?: number}>) {
            state.loading = true;
        },
        rejectReportSuccess(state, action: PayloadAction<ReportResponse>) {
            state.loading = false;
            state.rejectSchdule = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const report$: RootEpic = (action$) =>
    action$.pipe(
        filter(getReport.match),
        switchMap((re) => {
            return reportAPI.get(re.payload.page, re.payload.size, re.payload.status).pipe(
                mergeMap((res: any) => {
                    return [
                        reportSlice.actions.getReportSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    reportSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const rejectreport$: RootEpic = (action$) =>
    action$.pipe(
        filter(rejectReport.match),
        switchMap((re) => {
            return reportAPI.reject(re.payload.id).pipe(
                mergeMap((res: any) => {
                    return reportAPI.get(re.payload.page, re.payload.size, re.payload.status).pipe(
                        mergeMap((res: any) => {
                            return [
                                reportSlice.actions.getReportSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            reportSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    reportSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const approvereport$: RootEpic = (action$) =>
    action$.pipe(
        filter(approveReport.match),
        switchMap((re) => {
            return reportAPI.approve(re.payload.id).pipe(
                mergeMap((res: any) => {
                    return reportAPI.get(re.payload.page, re.payload.size, re.payload.status).pipe(
                        mergeMap((res: any) => {
                            return [
                                reportSlice.actions.getReportSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            reportSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    reportSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const reportEpics = [report$, approvereport$, rejectreport$];
export const {
    getReport,
    getReportSuccess,
    rejectReport,
    rejectReportSuccess,
    approveReport,
    aprroveReportSuccess
} = reportSlice.actions;
export const reportReducer = reportSlice.reducer;