import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import InvoiceAPI, { Invoices } from "../../api/vnpt";

interface InvoicesState {
    loading: boolean;
    invoiceData: any| null
    errorMessage: string | null;
}

const initState: InvoicesState = {
    loading: false,
    invoiceData: null,
    errorMessage: null
};
const invoiceSlice = createSlice({
    name: 'Invoices',
    initialState: initState,
    reducers: {
        ImportAndPublishInv(state, action: PayloadAction<Invoices>) {
            state.loading = true;
        },
        ImportAndPublishInvSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.invoiceData = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const ImportAndPublishInv$: RootEpic = (action$) =>
    action$.pipe(
        filter(ImportAndPublishInv.match),
        switchMap((re) => {
            return InvoiceAPI.ImportAndPublishInv(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        invoiceSlice.actions.ImportAndPublishInvSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    invoiceSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const InvoicesEpics = [ImportAndPublishInv$];
export const {
    ImportAndPublishInv,
    ImportAndPublishInvSuccess,
} = invoiceSlice.actions;
export const invoiceReducer = invoiceSlice.reducer;