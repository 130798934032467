import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Pagination, User } from "../common/define-types";
import { Shift } from "./Operator.api";
export interface Schedule {
    date?: string,
    note?: string,
    createdAt?: Date,
    updatedAt?: Date,
    day?: number,
    month?: number,
    year?: number,
    shift?: {
        data?: {
            id?: 1,
            attributes?: Shift
        }
    }
}
export interface ScheduleResponse {
    data: {
        id?: number,
        attributes?: {
            rejectReason?: string,
            status?: boolean | null,
            createdAt?: Date,
            updatedAt?: Date,
            userRequest?: {
                data?: {
                    id?: number,
                    attributes?: User
                }
            },
            userChange?: {
                data?: {
                    id?: number,
                    attributes?: User
                }
            },
            currentSchedule?: {
                data?: {
                    id?: number,
                    attributes?: Schedule
                }
            },
            changeSchedule?: {
                data?: {
                    id?: number,
                    attributes?: Schedule
                }
            }
        }
    }[],
    meta: {
        pagination: Pagination
    }
}
export interface ScheduleRequest {
    data:{
        userRequestId: number,
        userChangeId: number,
        currentScheduleId: number,
        changeScheduleId: number
    }
}

export default class ScheduleAPI {
    static host = SYSTEM_CONSTANTS.HOST;
    static get(page: number, size: number, status?: number): Observable<ScheduleResponse | null> {
        var api = `${ScheduleAPI.host}/${SYSTEM_CONSTANTS.SCHEDULE.GET}?populate[1]=currentSchedule.shift&populate[0]=userRequest&populate[2]=userChange&populate[3]=changeSchedule.shift&pagination[page]=${page}&pagination[pageSize]=${size}`;
        if(status != null && status != -1) {
            api = api + '&filters[status]' + (status == 2? '[$null]=1' : ('=' + status));
        }
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ScheduleResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static approve(id: string): Observable<ScheduleResponse | null> {
        const api = `${ScheduleAPI.host}/${SYSTEM_CONSTANTS.SCHEDULE.APPROVE(id)}`;
        return HttpClient.put(api, null, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ScheduleResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static reject(id: string, rejectReason?: string): Observable<ScheduleResponse | null> {
        const api = `${ScheduleAPI.host}/${SYSTEM_CONSTANTS.SCHEDULE.REJECT(id)}`;
        const body = {
            data:{
                rejectReason: rejectReason
            }
        }
        return HttpClient.put(api, body, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ScheduleResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static create(body: ScheduleRequest): Observable<ScheduleResponse | null> {
        const api = `${ScheduleAPI.host}/${SYSTEM_CONSTANTS.SCHEDULE.POST}`;
        return HttpClient.post(api, body, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ScheduleResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}