import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ExportBillAPI, { ExportBillResponse, ExportBillQuery, ExportBill } from "../../api/ExportBill.api";
import { RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface ExportBillState {
    loading: boolean;
    ExportBillData: ExportBillResponse| null;
    errorMessage: string | null;
}

const initState: ExportBillState = {
    loading: false,
    ExportBillData: null,
    errorMessage: null,
};
const ExportBillSlice = createSlice({
    name: 'ExportBill',
    initialState: initState,
    reducers: {
        getExportBill(state, action: PayloadAction<ExportBillQuery>) {
            state.loading = true;
        },
        createExportBill(state, action: PayloadAction<{query:ExportBillQuery, body: ExportBill}>) {
            state.loading = true;
        },
        getExportBillSuccess(state, action: PayloadAction<ExportBillResponse>) {
            state.loading = false;
            state.ExportBillData = action.payload;
        },
        
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const ExportBill$: RootEpic = (action$) =>
    action$.pipe(
        filter(getExportBill.match),
        switchMap((re) => {
            return ExportBillAPI.getExportBill(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        ExportBillSlice.actions.getExportBillSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    ExportBillSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const CreateExportBill$: RootEpic = (action$) =>
    action$.pipe(
        filter(createExportBill.match),
        switchMap((re) => {
            return ExportBillAPI.createExportBill(re.payload.body).pipe(
                mergeMap((res: any) => {
                    return ExportBillAPI.getExportBill(re.payload.query).pipe(
                        mergeMap((res1: any) => {
                            return [
                                ExportBillSlice.actions.getExportBillSuccess(res1),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            ExportBillSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    ExportBillSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const ExportBillEpics = [ExportBill$, CreateExportBill$];
export const {
    getExportBill,
    getExportBillSuccess,
    createExportBill,
} = ExportBillSlice.actions;
export const ExportBillReducer = ExportBillSlice.reducer;