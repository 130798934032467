import { Col, Pagination, Row, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownComponent } from "../../../components/Dropdown/DropdownComponent";
import { approveReport, getReport, rejectReport } from "../../../store/controls/Report.slice";
import { useSelectorRoot } from "../../../store/store";
import { ReportRequestItem } from "./ReportRequestItem";
import { Report } from "../../../api/Report.api";

export const ReportRequest = () => {
   
    
    const {reportData, loading, pagination} = useSelectorRoot(state => state.report);
    const [length, setLength] = useState(pagination?.total ?? 10);
    const [size, setSize] = useState(pagination?.pageSize ?? 10);
    const [currentPage, setCurrentPage] = useState(pagination?.page ?? 1);
    const {isSuccess} = useSelectorRoot(state => state.login);
    const dispatch = useDispatch();
    var [dataSource, setDataSource]= useState<Report[] | undefined>([]);
    const [status, setStatus] = useState<number>();
    useEffect(() => {
        setDataSource(reportData?.data.map(x => ({...x.attributes, id: x.id})));
    }, [reportData])
    useEffect(() => {
        dispatch(getReport({page: currentPage, size: size, status: status}));
    }, [isSuccess])
    const onUpdate = (value: boolean, item: Report) => {
        if(value){
            dispatch( approveReport({id: item.id?.toString() ?? '', page: currentPage, size: size}))
        }else {
            dispatch(rejectReport({id: item.id?.toString() ?? '', page: currentPage, size: size}))
        }
        
    }
    useEffect(() => {
        setLength(pagination?.total ?? 10);
        setSize(pagination?.pageSize ?? 10);
        setCurrentPage(pagination?.page ?? 1);
    }, [pagination]);
    const statusLts = [{
        key: 1,
        label: "Đã từ chối"
        },
        {
            key: 2,
        label: "Đã phê duyệt"
        },
        {
            key: 0,
        label: "Chờ phê duyệt"
        }
    ];
    return (
    <Content>
        <Spin spinning={loading}>
        <Row style={{margin: 10}}>
            <DropdownComponent items={{items: statusLts.concat([{label: "Tất cả", key: -1}]), onClick: (value) => {
                    setStatus(Number(value.key));
                    dispatch(getReport({page: currentPage, size: size, status: Number(value.key)}));
                }}} label={'Chọn trạng thái'} flex={0.3} ></DropdownComponent>
        </Row>
        <Row style={{width: "100%", height: "calc(100vh - 200px)", overflowY: "scroll"}}>
        { dataSource?.map(x => <Col xl={24} md={24} sm={24} style={{padding: 12}}><ReportRequestItem data={x} onSelect={(value) => onUpdate(value, x)}></ReportRequestItem></Col>)}
        </Row>
        
    
        <Row style={{justifyContent: 'flex-end', flex: 1, marginTop: 10, width: "100%", }}>
            <Pagination defaultCurrent={1} total={length} pageSize={size} onChange={(page, pageSize) => {
                setCurrentPage(page); setSize(pageSize);
                 dispatch(getReport({page: page, size: pageSize, status: status}));
                }} style={{justifyContent: 'flex-end'}}></Pagination>
        </Row>
        </Spin>
    </Content>);
    
}