import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ClientAPI, { ClientBillResponse, ClientRequest, ClientResponse, DetailResponse, QueryRequest, UpdateBillRequest } from "../../api/Client.api";
import { Pagination, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError,} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { message } from "antd";
import Utils from "../../common/Utils";

interface ClientState {
    loading: boolean;
    clientData: ClientResponse| null
    createClientDate: ClientResponse | null;
    updateClientDate: ClientResponse | null;
    errorMessage: string | null;
    pagination: Pagination | null;
    clientBill: ClientBillResponse | null;
    paginationBill: Pagination | null;
    detailClient: DetailResponse | null
    updateError: {message: string, code: number} | null,
    search?: string
}

const initState: ClientState = {
    loading: false,
    clientData: null,
    createClientDate: null,
    updateClientDate: null,
    errorMessage: null,
    pagination: null,
    clientBill: null,
    paginationBill: null,
    detailClient: null,
    updateError: null,
    search: ''
};
const clientSlice = createSlice({
    name: 'client',
    initialState: initState,
    reducers: {
        getClient(state, action: PayloadAction<QueryRequest>) {
            state.loading = true;
            state.search = action.payload.search;
            console.log('search',action.payload.search )
            action.payload.search != undefined && action.payload.search != null && Utils.setLocalStorage('search', action.payload.search);
        },
        getClientSuccess(state, action: PayloadAction<ClientResponse>) {
            state.loading = false;
            state.clientData = action.payload;
            state.pagination = action.payload.meta.pagination;
        },
        getBillClient(state, action: PayloadAction<{id: string, query: QueryRequest}>) {
            state.loading = true;
        },
        getBillClientSuccess(state, action: PayloadAction<ClientBillResponse>) {
            state.loading = false;
            state.clientBill = action.payload;
            state.paginationBill = action.payload.meta.pagination;
        },
        updateBillClient(state, action: PayloadAction<{id: string, billId: string, query: QueryRequest, data: UpdateBillRequest}>) {
            state.loading = true;
        },
        createClient(state, action: PayloadAction<{data: ClientRequest, query: QueryRequest}>) {
            state.loading = true;
        },
        createClientSuccess(state, action: PayloadAction<ClientResponse>) {
            state.loading = false;
            state.createClientDate = action.payload;
        },
        updateClient(state, action: PayloadAction<{data: ClientRequest, query: QueryRequest}>) {
            state.loading = true;
        },
        updateClientSuccess(state, action: PayloadAction<ClientResponse>) {
            state.loading = false;
            state.updateClientDate = action.payload;
        },
        deleteClient(state, action: PayloadAction<{id: string, query: QueryRequest}>) {
            state.loading = true;
        },
        getDetail(state, action: PayloadAction<{id: number}>) {
            state.loading = true;
        },
        getDetailSuccess(state, action: PayloadAction<DetailResponse>) {
            state.loading = false;
            state.detailClient = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
        failUpdate(state, action: PayloadAction<{message: string, code: number}>) {
            state.updateError = action.payload
        }
    }}
);

const client$: RootEpic = (action$) =>
    action$.pipe(
        filter(getClient.match),
        switchMap((re) => {
            return ClientAPI.get(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        clientSlice.actions.getClientSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    clientSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

const createClient$: RootEpic = (action$) =>
    action$.pipe(
        filter(createClient.match),
        switchMap((re) => {
            return ClientAPI.create(re.payload.data).pipe(
                mergeMap((res: any) => {
                    return ClientAPI.get(re.payload.query).pipe(
                        mergeMap((res: any) => {
                            return [
                                clientSlice.actions.getClientSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            clientSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    clientSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

const updateClient$: RootEpic = (action$) =>
    action$.pipe(
        filter(updateClient.match),
        switchMap((re) => {
            return ClientAPI.update(re.payload.data).pipe(
                mergeMap((res: any) => {
                    return ClientAPI.get(re.payload.query).pipe(
                        mergeMap((res: any) => {
                            return [
                                clientSlice.actions.getClientSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            clientSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    clientSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const deleteClient$: RootEpic = (action$) =>
    action$.pipe(
        filter(deleteClient.match),
        switchMap((re) => {
            return ClientAPI.delete(re.payload.id).pipe(
                mergeMap((res: any) => {
                    return ClientAPI.get(re.payload.query).pipe(
                        mergeMap((res: any) => {
                            return [
                                clientSlice.actions.getClientSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            clientSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    clientSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const clientBill$: RootEpic = (action$) =>
    action$.pipe(
        filter(getBillClient.match),
        switchMap((re) => {
            return ClientAPI.getBill(re.payload.id, re.payload.query).pipe(
                mergeMap((res: any) => {
                    return [
                        clientSlice.actions.getBillClientSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    clientSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

const updateClientBill$: RootEpic = (action$) =>
    action$.pipe(
        filter(updateBillClient.match),
        switchMap((re) => {
            return ClientAPI.updateBill(re.payload.billId, re.payload.data).pipe(
                mergeMap((res: any) => {
                    return ClientAPI.getBill(re.payload.id, re.payload.query).pipe(
                        mergeMap((res: any) => {
                            return [
                                clientSlice.actions.getBillClientSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            clientSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    clientSlice.actions.failUpdate({message:"Có lỗi xảy ra vui lòng thử lại sau", code: e.status}),
                ]),
            )
        })
)

const getDetail$: RootEpic = (action$) =>
action$.pipe(
    filter(getDetail.match),
    switchMap((re) => {
        return ClientAPI.getDetail(re.payload.id).pipe(
            mergeMap((res: any) => {
                return [
                    clientSlice.actions.getDetailSuccess(res),
                ];
            }),
            catchError((e: AjaxError) => [
                clientSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
            ]),
        )
    })
)

export const ClientEpics = [client$, createClient$, updateClient$, getDetail$, deleteClient$, clientBill$, updateClientBill$];
export const {
    getClient,
    getClientSuccess,
    createClient,
    createClientSuccess,
    updateClient,
    updateClientSuccess,
    deleteClient,
    getBillClient,
    getDetail,
    updateBillClient
} = clientSlice.actions;
export const clientReducer = clientSlice.reducer;