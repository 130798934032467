import { MenuProps, Row, Col, Card, Button, Table, Pagination, Image, Input, Typography, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import { Operator, OperatorCreateRequestData, OperatorUpdateRequestData } from "../../../api/Operator.api";
import { FilterItem, User } from "../../../common/define-types";
import Utils from "../../../common/Utils";
import { DropdownComponent, ItemTypeProp } from "../../../components/Dropdown/DropdownComponent";
import fileAnalytics from "../../../image/icon/file-analytics.svg";
import saveSvg from "../../../image/icon/save.svg";
import { createOperator, getOperator, updateOperator } from "../../../store/controls/Operator.slice";
import { getUser } from "../../../store/controls/User.slice";
import { useSelectorRoot } from "../../../store/store";
import dayjs from "dayjs";
import { Contract } from "../../../api/Client.api";
import SYSTEM_CONSTANTS from "../../../common/constants";
interface ShiftItem {
  
  date: number,
  file?: Contract, 
  shiftId: number

  nameUser: string, 
  key?: number, 
  currentKey?: number,
  currentId?: number,

  nameUser2?: string, 
  key2?: number, 
  currentKey2?: number,  
  currentId2?: number,

  nameUser3?: string, 
  key3?: number, 
  currentKey3?: number, 
  currentId3?: number,
}
interface DataType {
    key: number;
    Date: number;
    shift1: ShiftItem;
    shift2: ShiftItem;
    shift3: ShiftItem;
    shift4: ShiftItem;
  }
  const {Text} = Typography;
export const Shift = () => {
  const [visible, setVisible] = useState(false);
  const [imageReview, setImageReview] = useState('')

    const dispatch = useDispatch();
    const [month, setMonth] = useState<MenuProps['items']>(Utils.arrayRange(1, 12, 1).map(x =>({key: '' + x , label: '' + x})));
    const [years, setYears] = useState<MenuProps['items']>(Utils.arrayRange(1900, new Date().getFullYear(), 1).map(x =>({key: '' + x, label: '' + x})));
    const {operatorData, loading} = useSelectorRoot(state => state.operator)
    const {userData} = useSelectorRoot(state => state.user);
    const {isSuccess} = useSelectorRoot(state => state.login)
    const [selectedMonth, setSelectedMonth] = useState<number>((new Date()).getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState<number>((new Date()).getFullYear());
    const [users, setUsers] = useState<User[] | null>(userData);
    
  
    const [data, setData] = useState<DataType[]>([]);
    useEffect(() => {
      if(isSuccess){
        dispatch(getOperator({year: selectedYear, month: selectedMonth}));
        dispatch(getUser({page: 1, size: 100, role: 7}))
      }
    }, [isSuccess]);
    useEffect(() => {
      setUsers(userData);
    }, [userData]);
    useEffect(() => {setMonth(Utils.arrayRange(1, 
      selectedYear == (new Date()).getFullYear() ?
      (new Date()).getMonth() == 12? 2 : (new Date()).getMonth() == 11? 1 : (new Date()).getMonth() + 3
      : 12,
       1).map(x =>({key: '' + x , label: '' + x})))}, [selectedYear]);
    const createShift = (x: Operator[], index: number, shiftId: number) => {
      
      let arrayShifMember = x.filter(y => y.shift.data.id == shiftId);
      let first = arrayShifMember[0];
      if(index == 25 && shiftId == 1){
        console.log(x);
      }
      let reusult: ShiftItem = {
        date: index,
        nameUser: first?.operatorTechnicians?.data[0]?.attributes.fullName ?? '', 
        file: x.find(y => y.shift.data.id == shiftId && y.handoverDocument != null && y.handoverDocument.data != null)?.handoverDocument?.data, 
        currentKey: first?.operatorTechnicians?.data[0]?.id, 
        currentId: first?.id,
        key: first?.operatorTechnicians?.data[0]?.id,
        shiftId: shiftId,
      };
      if (arrayShifMember.length > 1) {
        reusult.key2 = arrayShifMember[1]?.operatorTechnicians?.data[0]?.id;
        reusult.currentKey2 = arrayShifMember[1]?.operatorTechnicians?.data[0]?.id;
        reusult.nameUser2 = arrayShifMember[1]?.operatorTechnicians?.data[0]?.attributes.fullName ?? '';
        reusult.currentId2 = arrayShifMember[1]?.id;

        if (arrayShifMember.length > 2) {
          reusult.key3 = arrayShifMember[2]?.operatorTechnicians?.data[0]?.id;
          reusult.currentKey3 = arrayShifMember[2]?.operatorTechnicians?.data[0]?.id;
          reusult.nameUser3 = arrayShifMember[2]?.operatorTechnicians?.data[0]?.attributes.fullName ?? '';
          reusult.currentId3 = arrayShifMember[2]?.id;
        }
      }
      return reusult;
    }
    useEffect(() => {
      var groupData = operatorData?.data
      .map(x => ({...x.attributes, id: x.id}))
      .reduce((group: Operator[][], product) => {
        const { day } = product;
        group[day] = group[day] ?? [];
        group[day].push(product);
        return group;
      }, []);
      var dataSet: DataType[] = (groupData ?? []).map((x: Operator[], index) => ({
        key: (index),
        Date: (index),
        shift1: createShift(x, index, 1),
        shift2: createShift(x, index, 2),
        shift3:createShift(x, index, 3),
        shift4: createShift(x, index, 4)
      }));
      var length = [4, 6, 9, 11].some(x => x == selectedMonth) ? 30 : selectedMonth == 2? (selectedYear % 4 == 0 && !(selectedYear % 100 == 0 && selectedYear % 400 != 0)) ? 29 : 28 : 31;
      const result = dataSet.map(x => x);
      for(var i = 1; i < length + 1; i++){
        if (!dataSet.some(x => x.Date == i)){
          result.push({
            key: i,
            Date: i,
            shift1: {
              date: i,
              nameUser: '',
              nameUser2: '',
              nameUser3: '',
              shiftId: 1
            },
            shift2: {
              date: i,
              nameUser: '',
              nameUser2: '',
              nameUser3: '',
              shiftId: 2
            },
            shift3: {
              date: i,
              nameUser: '',
              nameUser2: '',
              nameUser3: '',
              shiftId: 3
            },
            shift4: {
              date: i,
              nameUser: '',
              nameUser2: '',
              nameUser3: '',
              shiftId: 4
            },

          })
        }
      }
      setData(result.sort((a,b) => a.Date - b.Date));
    }, [operatorData])
    const updateShift = (shift: number, change: ItemTypeProp, date: number, sttUser: number) => {
      var updateData = data.map(x => {
        if(x.Date == date) {
          switch(shift) {
            case 1: 
              switch (sttUser) {
                case 1:
                  x.shift1.key = Number(change.key);
                  x.shift1.nameUser = Number(change.key) >= 0?change.label as string : '';
                  break;
                case 2:
                  x.shift1.key2 = Number(change.key);
                  x.shift1.nameUser2 = Number(change.key) >= 0?change.label as string : '';
                  break;
                case 3:
                  x.shift1.key3 = Number(change.key);
                  x.shift1.nameUser3 = Number(change.key) >= 0?change.label as string : '';
                  break;
              }
              break;
            case 2: 
              switch (sttUser) {
                case 1:
                  x.shift2.key = Number(change.key);
                  x.shift2.nameUser = Number(change.key) >= 0?change.label as string : '';
                  break;
                case 2:
                  x.shift2.key2 = Number(change.key);
                  x.shift2.nameUser2 = Number(change.key) >= 0?change.label as string : '';
                  break;
                case 3:
                  x.shift2.key3 = Number(change.key);
                  x.shift2.nameUser3 = Number(change.key) >= 0?change.label as string : '';
                  break;
              }
              break;
            case 3: 
              switch (sttUser) {
                case 1:
                  x.shift3.key = Number(change.key);
                  x.shift3.nameUser = Number(change.key) >= 0?change.label as string : '';
                  break;
                case 2:
                  x.shift3.key2 = Number(change.key);
                  x.shift3.nameUser2 = Number(change.key) >= 0?change.label as string : '';
                  break;
                case 3:
                  x.shift3.key3 = Number(change.key);
                  x.shift3.nameUser3 = Number(change.key) >= 0?change.label as string : '';
                  break;
              }
              break;
            case 4: 
              switch (sttUser) {
                case 1:
                  x.shift4.key = Number(change.key);
                  x.shift4.nameUser = Number(change.key) >= 0?change.label as string : '';
                  break;
                case 2:
                  x.shift4.key2 = Number(change.key);
                  x.shift4.nameUser2 = Number(change.key) >= 0?change.label as string : '';
                  break;
                case 3:
                  x.shift4.key3 = Number(change.key);
                  x.shift4.nameUser3 = Number(change.key) >= 0?change.label as string : '';
                  break;
              }
              break;
          }
        }
        return x;
      });
      setData(updateData);
    }
    const addRequestInsert = (shift: ShiftItem, dateNumber: number, dataRquest: {requestInsert: OperatorCreateRequestData[], requestUpdate: OperatorUpdateRequestData[]}) => {
      //insert 
      if (shift.currentKey == undefined && shift.key != undefined && shift.shiftId && shift.key != -1) {
        dataRquest.requestInsert.push({
          shift: {
            connect: [shift.shiftId],
          },
          date: dayjs( new Date(selectedYear, selectedMonth - 1, dateNumber)).format('YYYY-MM-DD'),
          operatorTechnicians: {
            connect: [shift.key]
          }
        })
      } else if (shift.currentKey != undefined && shift.key != undefined && shift.currentKey != undefined && shift.key != shift.currentKey) {
        dataRquest.requestUpdate.push({
          id: shift.currentId,
          date: dayjs( new Date(selectedYear, selectedMonth - 1, dateNumber)).format('YYYY-MM-DD'),
          operatorTechnicians: {
            connect: shift.key >= 0 ? [shift.key] : undefined,
            disconnect: [shift.currentKey]
          }
        })
      }
      //insert 
      if (shift.currentKey2 == undefined && shift.key2 != undefined && shift.shiftId && shift.key2 != -1) {
        dataRquest.requestInsert.push({
          shift: {
            connect: [shift.shiftId],
          },
          date: dayjs( new Date(selectedYear, selectedMonth - 1, dateNumber)).format('YYYY-MM-DD'),
          operatorTechnicians: {
            connect: [shift.key2]
          }
        })
      } else if (shift.currentKey2 != undefined && shift.key2 != undefined && shift.currentKey2 != undefined && shift.key2 != shift.currentKey2) {
        dataRquest.requestUpdate.push({
          id: shift.currentId2,
          date: dayjs( new Date(selectedYear, selectedMonth - 1, dateNumber)).format('YYYY-MM-DD'),
          operatorTechnicians: {
            connect: shift.key2 >= 0 ? [shift.key2] : undefined,
            disconnect: [shift.currentKey2]
          }
        })
      }
      //insert 
      if (shift.currentKey3 == undefined && shift.key3 != undefined && shift.shiftId && shift.key3 != -1) {
        dataRquest.requestInsert.push({
          shift: {
            connect: [shift.shiftId],
          },
          date: dayjs( new Date(selectedYear, selectedMonth - 1, dateNumber)).format('YYYY-MM-DD'),
          operatorTechnicians: {
            connect: [shift.key3]
          }
        })
      } else if (shift.currentKey3 != undefined && shift.key3 != undefined && shift.currentKey3 != undefined && shift.key3 != shift.currentKey3) {
        dataRquest.requestUpdate.push({
          id: shift.currentId3,
          date: dayjs( new Date(selectedYear, selectedMonth - 1, dateNumber)).format('YYYY-MM-DD'),
          operatorTechnicians: {
            connect: shift.key3 >= 0 ? [shift.key3] : undefined,
            disconnect: [shift.currentKey3]
          }
        })
      }
      return dataRquest;
    }
    const save = () => {
      
      var requestData:{requestInsert: OperatorCreateRequestData[], requestUpdate: OperatorUpdateRequestData[]}  = {
        requestInsert: [],
        requestUpdate: []
      };
      for(var item of data) {
        if (item == undefined) {
          continue;
        }
        requestData = addRequestInsert(item.shift1, item.Date, requestData)
        requestData = addRequestInsert(item.shift2, item.Date, requestData)
        requestData = addRequestInsert(item.shift3, item.Date, requestData)
        requestData = addRequestInsert(item.shift4, item.Date, requestData)
      }
      console.log('request ', requestData);
      if(requestData.requestInsert.length > 0)dispatch(createOperator({data: requestData.requestInsert}));
      if(requestData.requestUpdate.length > 0)dispatch(updateOperator({data: requestData.requestUpdate}));
    }
    const renderCell = (text: ShiftItem) => {
      return (
        <Row>
              <Col xl={20} md={20} sm={22}>
                <Row style={{marginBottom: 10}}>
                  <DropdownComponent label={text?.nameUser}
                    value={text?.nameUser}
                    borderWidth={0}
                    items={{
                      items: [{label: 'Trống', key: -1}].concat(users?.map( x => ({label: x.fullName ?? "", key: x.id ?? ""})) ?? []),
                      onClick: (value) => {updateShift(text.shiftId, value, text.date, 1)}
                    }}/>
                </Row>
                <Row style={{marginBottom: 10}}>
                  <DropdownComponent label={text?.nameUser2 ?? ''}
                    value={text?.nameUser2}
                    borderWidth={0}
                    items={{
                      items: [{label: 'Trống', key: -1}].concat(users?.map( x => ({label: x.fullName ?? "", key: x.id ?? ""})) ?? []),
                      onClick: (value) => {updateShift(text.shiftId, value, text.date, 2)}
                    }}/>
                </Row>
                <Row>
                  <DropdownComponent label={text?.nameUser3 ?? ''}
                    value={text?.nameUser3}
                    borderWidth={0}
                    items={{
                      items: [{label: 'Trống', key: -1}].concat(users?.map( x => ({label: x.fullName ?? "", key: x.id ?? ""})) ?? []),
                      onClick: (value) => {updateShift(text.shiftId, value, text.date, 3)}
                    }}/>
                </Row>
              
              </Col>
              <Col xl={4} md={4} sm={2} style={{justifyItems: 'center', alignItems: 'center', alignContent: 'center', display: 'flex'}}>
                <Button type="text" disabled={!text.file} onClick={() => {
                  setImageReview(SYSTEM_CONSTANTS.HOST + text.file?.attributes?.url);
                  setVisible(true);
                }}>
                  <ReactSVG src={fileAnalytics} style={{marginLeft: 5}}></ReactSVG>
                </Button>
              
              </Col>
            </Row> 
      )
    }
      const columns: ColumnsType<DataType> = [
        {
          title: 'Ngày',
          dataIndex: 'Date',
          key: 'Date',
          render: (text) => <><Text style={{color: '#2222225A'}}>{text}</Text></>,
        },
        {
          title: <><Text style={{color: '#222222'}}>{'CA 1'}</Text><br/><Text style={{color: '#2222225A'}}>{'(6:00 - 14:00)'}</Text></>,
          dataIndex: 'shift1',
          key: 'shift1',
          render: renderCell
        },
        {
          title: <><Text style={{color: '#222222'}}>{'CA 2'}</Text><br/><Text style={{color: '#2222225A'}}>{'(14:00 - 22:00)'}</Text></>,
          dataIndex: 'shift2',
          key: 'shift2',
          render: renderCell
        },
        {
          title: <><Text style={{color: '#222222'}}>{'CA 3'}</Text><br/><Text style={{color: '#2222225A'}}>{'(22:00 - 6:00)'}</Text></>,
          dataIndex: 'shift3',
          key: 'shift3',
          render: renderCell
        },
        {
            title: <><Text style={{color: '#222222'}}>{'CA HC'}</Text><br/><Text style={{color: '#2222225A'}}>{'(7h -11h; 13h30 - 17h30)'}</Text></>,
            dataIndex: 'shift4',
            key: 'shift4',
            render: renderCell
          },
      ];
    return (
    <Content style={{padding: "0px 0px 0px 15px"}} >
      <Spin spinning={loading}>
       <Card title={
            <>
            <Row style={{margin: '20px 8px 16px 0px'}}>
            <Text style={{fontSize: 22, fontWeight: 600}}>{'Bộ lọc tìm kiếm'}</Text>
            </Row>
            <Row style={{margin: '20px 8px 16px 0px'}}>
            <DropdownComponent items={
              {items: years, onClick: (value) => {
                setSelectedYear(Number(value.key))
                dispatch(getOperator({year: Number(value.key), month: selectedMonth}))
              }}
            } label={'Chọn năm'} flex={0.3} value={selectedYear}></DropdownComponent>
            <DropdownComponent items={
              {items: month, onClick: (value) => {
                setSelectedMonth(Number(value.key))
                dispatch(getOperator({year: selectedYear, month: Number(value.key)}))
              }}
            } label={'Chọn tháng'} flex={0.3} value={selectedMonth}></DropdownComponent>
                <div style={{flex: 1}}></div>
                <Button
                    type="primary"
                    style={{justifyContent: "center", alignItems: 'center'}}
                    onClick={() => {save()}}
                    >
                      <Row>
                      <ReactSVG src={saveSvg} style={{marginRight: 5, width: 18, height: 18}}></ReactSVG>
                      {'Lưu thay đổi'}
                      </Row>
                      
                </Button>
            </Row>
            </>
        }>
            <Row style={{paddingTop: '20px', minHeight: '55vh'}}>
                <Table 
                    scroll={{x: '60vh', y: '50vh'}}
                    style={{flex: 1}}
                    columns={columns} 
                    dataSource={data}
                    pagination={false} 
                    />
            </Row>
            
       </Card>
       <Image
        width={200}
        style={{ display: 'none' }}
        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
        preview={{
          visible,
          scaleStep: 0.5,
          src: imageReview,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
      </Spin>
    </Content>);
}