
import { MenuProps, Card, Row, Typography, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Utils from "../../../common/Utils";
import { DropdownComponent } from "../../../components/Dropdown/DropdownComponent";
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getOperatorReport } from "../../../store/controls/Operator.slice";
import { useSelectorRoot } from "../../../store/store";
const { Text } = Typography;
ChartJS.register(...registerables);
export const OperatorReport = () => {
  const dispatch = useDispatch();
  const { report, loading } = useSelectorRoot((state) => state.operator);
  const { isSuccess } = useSelectorRoot((state) => state.login);
  const years: MenuProps["items"] = Utils.arrayRange(
    1900,
    new Date().getFullYear(),
    1
  )
    .map((x) => ({ key: "" + x, label: "" + x }))
    .reverse();
  const [month, setMonth] = useState<MenuProps["items"]>(
    Utils.arrayRange(1, 12, 1).map((x) => ({ key: "" + x, label: "Tháng " + x }))
  );
  const [day, setDay] = useState<MenuProps["items"]>(
    Utils.arrayRange(0, 31, 1).map((x) => ({ key: "" + x, label: x == 0 ? "Tất cả" : "Ngày " + x }))
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth()
  );
  const [selectedDay, setSelectedDay] = useState<number>(
    0
  );
  const [header, setHeader] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [header1, setHeader1] = useState<any[]>([]);
  const [data1, setData1] = useState<any[]>([]);
  const [operatorReport, setOperatorReport] = useState(report);

  const getName = (attName: string) => {
    switch (attName) {
      
      case "roughWater":
        return "Nước thô";
      case "pipelineDischargeWater":
        return "Nước xả đường ống";
      case "filteredWater":
        return "Nước sau lọc (m3)";
      case "sinkTime":
        return "Giờ rửa bồn";
      default:
        return '';
    }
  };
  const getName1 = (attName: string) => {
    switch (attName) {
      case "pumpingHour":
        return "Giờ bơm";
      case "consumedCloAmount":
        return "Lượng CLO tiêu thụ";
      case "consumedAlumAmount":
        return "Lượng phèn tiêu thụ";
      case "electricityConsumption":
        return "Điện tiêu thụ";
      default:
        return '';
    }
  };
  useEffect(() => {
    setOperatorReport(report);
    var headerItems = [];
    var dataItems = [];
    var headerItems1 = [];
    var dataItems1 = [];
    for (var att in report) {
      let title = getName(att);
      if (title != '')
      {
        headerItems.push(title);
        dataItems.push((report as any)[att]);
      }
      else
      {
        title = getName1(att);
        if (title != '')
        {
          headerItems1.push(title);
          dataItems1.push((report as any)[att]);
        }
      }
    }
    setData(dataItems);
    setHeader(headerItems);
    setData1(dataItems1);
    setHeader1(headerItems1);
  }, [report]);
  useEffect(() => {
    dispatch(getOperatorReport({ month: selectedMonth, year: selectedYear, day: selectedDay }));
  }, []);
  const dataTest = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Sales',
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56, 55, 40]
      }
    ]
  };
  const options = {
    scales: {
      y: {
        type: 'category',
      },
    },
  };
  return (
    <div
      style={{
        overflowY: "scroll",
        width: "100%",
        height: "calc(100vh - 150px)",
        padding: "15px 15px 30px 15px",
      }}
    >
      <Spin spinning={loading}>
        <Card style={{ width: "100%", marginBottom: 30 }}>
          <Row style={{ marginBottom: "10px" }}>
            <Text style={{ fontSize: 18, fontWeight: 600 }}>
              {"Báo cáo vận hành theo trong tháng"}
            </Text>
            <div style={{ flex: 1 }}></div>
            <DropdownComponent
              items={{
                items: years,
                onClick: (value) => {
                  setSelectedYear(Number(value.key));
                  dispatch(
                    getOperatorReport({
                      year: Number(value.key),
                      month: selectedMonth,
                      day: selectedDay
                    })
                  );
                },
              }}
              label={"Chọn năm"}
              flex={0.3}
              value={selectedYear}
            ></DropdownComponent>
            <DropdownComponent
              items={{
                items: month,
                onClick: (value) => {
                  setSelectedMonth(Number(value.key));
                  dispatch(
                    getOperatorReport({
                      year: selectedYear,
                      month: Number(value.key),
                      day: selectedDay
                    })
                  );
                },
              }}
              label={"Chọn tháng"}
              flex={0.3}
              value={selectedMonth}
            ></DropdownComponent>
            <DropdownComponent
              items={{
                items: day,
                onClick: (value) => {
                  setSelectedDay(Number(value.key));
                  dispatch(
                    getOperatorReport({
                      year: selectedYear,
                      month: selectedMonth,
                      day: Number(value.key),
                    })
                  );
                },
              }}
              label={"Chọn ngày"}
              flex={0.3}
              value={selectedDay}
            ></DropdownComponent>
          </Row>
          <Bar
            options={{
                indexAxis: 'y',
                plugins: {
                  
                }
            }}
            title={'Báo cáo đo nước '}
            data={{
              labels: header,
              datasets: [
                {
                  label: "Số nước",
                  backgroundColor: "#329BF09A",
                  data: data,
                  hoverBackgroundColor: "#005BA5",
                  maxBarThickness: 28,
                  
                },
              ],
            }}
          />
          <Row style={{ marginBottom: "50px" }}></Row>
          <Bar
            options={{
                indexAxis: 'y',
            }}
            data={{
              labels: header1,
              datasets: [
                {
                  label: "Số liệu",
                  backgroundColor: "#329BF09A",
                  data: data1,
                  hoverBackgroundColor: "#005BA5",
                  maxBarThickness: 28,
                  inflateAmount: 'auto'
                },
              ],
            }}
          />
        </Card>
      </Spin>
    </div>
  );
};
