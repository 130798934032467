import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Pagination } from "../common/define-types";
import { Area } from "./location.api";
export interface Client {
    id?: string;
    name?: string,
    code?: string,
    phone?: string,
    longitude?: number,
    latitude?: number,
    address?: string,
    createdAt?: Date,
    updatedAt?: Date,
    locationApproveStatus?: string,
    contractDate?: Date | null,
    taxCode?: string,
    gaugesCode?: string,
    clientType?: number,
    cleaningFee?: boolean,
    city?: {
        data?: Area
    },
    district?: {
        data?: Area
    },
    ward?: {
        data?: Area
    },
    village?: {
        data?: Area
    },
    line?: {
        data?: Area
    },
    priceCategory?: {
        data: Area
    },
    contract?: {
        data?: Contract
    }
}
export interface ClientResponse {
    data: {
        id: number,
        attributes: Client
    }[],
    meta: {
        pagination: Pagination
    }
}
export interface ClientRequest {
    id?: string;
    data: {
        city?: {
            disconnect?: number[],
            connect?: number[]
        },
        district?: {
            disconnect?: number[],
            connect?: number[]
        },
        ward?: {
            disconnect?: number[],
            connect?: number[]
        },
        village?: {
            disconnect?: number[],
            connect?: number[]
        },
        line?: {
            disconnect?: number[],
            connect?: number[]
        },
        priceCategory?: {
            disconnect?: number[],
            connect?: number[]
        },
        name?: string,
        code?: string,
        phone?: string,
        longitude?: number,
        latitude?: number,
        address?: string,
        contractDate?: Date | null,
        contract?: any,
        id?: string,
        gaugesCode?: string,
        taxCode?: string,
        clientType?: number,
        cleaningFee?: boolean
    }
}
export interface Contract {
    id?: number,
        attributes?: {
            name?: string,
            alternativeText?: string,
            caption?: string,
            width?: string,
            height?: string,
            formats?: string,
            hash?: string,
            ext?: string,
            mime?: string,
            size?: number,
            url?: string,
            previewUrl?: string,
            provider?: string,
            provider_metadata?: string,
            createdAt?: Date,
            updatedAt?: Date
        }
}
export interface QueryRequest {
    size?: number, 
    page?: number,
    village?: string,
    province?: string, 
    ward?: string,
    district?: string,
    search?: string
}
export interface ClientBill {
    id?: string;
    year?: number,
    month?: number,
    closingDate?: Date,
    startingQuantity?: number,
    endingQuantity?: number,
    usage?: number,
    totalMoney?: number,
    paidMoney?: number,
    deptMoney?: number,
    note?: string,
    isPaid?: boolean,
    createdAt?: Date,
    updatedAt?: Date,
    isBlocked?: boolean,
    waterMeterImage?: {data?: Contract}
}
export interface ClientBillResponse {
    data?: {
        id?: string;
        attributes?: ClientBill
    }[];
    meta: {
        pagination: Pagination
    }
}

export interface DetailResponse {
    data: {
        id?: number,
        attributes?: Client;
    },
    meta: any;
}
export interface UpdateBillRequest {
    id?: number,
    startingQuantity?: number,
    endingQuantity?: number,
    usage?: number,
    totalMoney?: number,
    paidMoney?: number,
    deptMoney?: number,
    isPaid?: boolean,
    isManualUpdate?: boolean
}
export default class ClientAPI {
    static getDetail(id: number) {
        const api = `${ClientAPI.host}/${SYSTEM_CONSTANTS.CLIENT.DETAIL(id.toString())}?populate=city,district,ward,village,line,priceCategory,contract`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as DetailResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static host = SYSTEM_CONSTANTS.HOST;
    static get(data: QueryRequest): Observable<ClientResponse | null> {
        var api = `${ClientAPI.host}/${SYSTEM_CONSTANTS.CLIENT.GET}?populate=city,district,ward,village,line,priceCategory,contract&pagination[page]=${data.page}&pagination[pageSize]=${data.size}`;
        if(data.district && data.district?.length > 0 && data.district != "-1" ) {
            api = api + '&filters[district][id][$eq]=' +  data.district;
        }
        if( data.province && data.province.length > 0 && data.province != "-1") {
            api = api + '&filters[city][id][$eq]=' +  data.province;
        }
        if(data.village && data.village.length > 0 && data.village != "-1") {
            api = api + '&filters[village][id][$eq]=' +  data.village;
        }
        if(data.ward && data.ward.length > 0 && data.ward != "-1") {
            api = api + '&filters[ward][id][$eq]=' +  data.ward;
        }

        if(data.search && data.search.length > 0) { 
            api = api + '&filters[$or][0][name][$contains]=' +  data.search + '&filters[$or][1][code][$contains]=' +  data.search + '&filters[$or][2][gaugesCode][$contains]=' +  data.search;
        }
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ClientResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static create(body: ClientRequest): Observable<ClientResponse | null> {
        const api = `${ClientAPI.host}/${SYSTEM_CONSTANTS.CLIENT.CREATE}`;
        return HttpClient.post(api, body, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ClientResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static update(body: ClientRequest): Observable<ClientResponse | null> {
        const api = `${ClientAPI.host}/${SYSTEM_CONSTANTS.CLIENT.UPDATE(body.id ?? '')}`;
        return HttpClient.put(api, body, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ClientResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static delete(id: string): Observable<ClientResponse | null> {
        const api = `${ClientAPI.host}/${SYSTEM_CONSTANTS.CLIENT.UPDATE(id ?? '')}`;
        return HttpClient.delete(api, null, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ClientResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static getBill(id: string, data: QueryRequest) {
        var api = `${ClientAPI.host}/${SYSTEM_CONSTANTS.CLIENT.BILL}?filters[client][id]=${id}&pagination[page]=${data.page}&pagination[pageSize]=${data.size}&sort=year:desc,month:desc&filters[isBlocked]=false&populate=client,waterMeterImage`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ClientBillResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static updateBill(id: string, data: UpdateBillRequest) {
        var api = `${ClientAPI.host}/${SYSTEM_CONSTANTS.CLIENT.UPDATE_BILL(id)}`;
        return HttpClient.put(api,{
            data
        }, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ClientBillResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}