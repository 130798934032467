import { Card, Row, Button, Table, Pagination, Typography, Input, Modal, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import {PlusOutlined, FormOutlined, DeleteOutlined} from '@ant-design/icons'
import { useEffect, useState } from "react";
import Utils from "../../../common/Utils";
import { AddAdmin, AddAdminProps } from "./AddAdmin";
import { useSelectorRoot } from "../../../store/store";
import { useDispatch } from "react-redux";
import { deleteUser, getUser } from "../../../store/controls/User.slice";
const {Text} = Typography;
const { Search } = Input;

export const Admin = () => {
    
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [deleteUserData, setDeleteUser] = useState<DataType>()
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  
  const handleOk = () => {
    if (deleteUserData?.key) dispatch(deleteUser({data:deleteUserData?.key, page: currentPage, size: size}));
    setIsModalConfirmOpen(false);
  }
  const {userData, pagination, loading} = useSelectorRoot(state => state.user);
  const [data, setData] = useState<DataType[]>(userData?.filter(x => x.role?.id && x.role?.id > 3).map(x => ({
    key: x.id.toString() ?? '',
    Client: {title: x.fullName ?? '', phone: x.phone ?? '' },
    username: x.username ?? '',
    role: x.role?.description
  })) ?? []);
  const {isSuccess} = useSelectorRoot(state => state.login)
  const dispatch = useDispatch();
  const [length, setLength] = useState(pagination?.total ?? 10);
  const [size, setSize] = useState(pagination?.pageSize ?? 10);
  const [currentPage, setCurrentPage] = useState(pagination?.page ?? 1);
  const [selectUser, setSelectUser] = useState<AddAdminProps | null>();
  const [searchText, setSearchText] = useState<string>();
    const onSearch = (value: string) => {
      setSearchText(value);
      if(!loading) {
        dispatch(getUser({
          page: currentPage, 
          size: size,
          search: value,
          role: 7
        }));
      }
    };
  useEffect(() => {
    dispatch(getUser({
      page: currentPage, 
      size: size,
      search: searchText
    }));
  }, [isSuccess]);
  useEffect(() => {
    setData(userData?.filter(x => x.role?.id && x.role?.id > 3).map(x => ({
      key: x.id.toString() ?? '',
      Client: {title: x.fullName ?? '', phone: x.phone ?? '' },
      username: x.username ?? '',
      role: x.role?.description
    })) ?? [])
  }, [userData])
  useEffect(() => {
    setLength(pagination?.total ?? 10);
    setSize(pagination?.pageSize ?? 10)
  }, [pagination])
  const columns: ColumnsType<DataType> = [
    {
      title: 'Nhân viên',
      dataIndex: 'Client',
      key: 'Client',
      render: (text) => <><Text style={{fontSize: 15, fontWeight: 500}}>{text.title}</Text><br/><Text style={{color: '#2222225A'}}>{text.phone}</Text></>,
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      key: 'username',
      render: (text) => <><Text style={{color: '#2222225A'}}>{text}</Text></>
    },
    {
      title: 'Chức vụ',
      dataIndex: 'role',
      key: 'role',
      render: (text) => <><Text style={{color: '#2222225A'}}>{text}</Text></>
    },
    {
      title: 'Chọn',
      key: '',
      dataIndex: '',
      render: (_, item) => (
        <>
          <Button icon={<FormOutlined />} onClick={() => {
            var itemUser = userData?.filter(x => x.role?.id && x.role?.id > 3).find(x => x.id == Number(item.key));
            setSelectUser({
              id:itemUser?.id.toString(),
              username: itemUser?.username,
              name: itemUser?.fullName,
              email: itemUser?.email,
              phone: itemUser?.phone,
              role: itemUser?.role?.id.toString()
            });
            showModal();
          }} style={{marginRight: 20}}></Button>
          <Button icon={<DeleteOutlined /> } onClick={() => {
            setDeleteUser(item);
            setIsModalConfirmOpen(true)
          }}></Button>
        </>
      ),
    },
  ];
    return (
    <Content style={{padding: "0px 0px 0px 15px"}} >
      <Spin spinning={loading}>
       <Card title={
            <>
            <Row style={{margin: '20px 8px 16px 0px'}}>
            <Text style={{fontSize: 22, fontWeight: 600}}>{'Bộ lọc tìm kiếm'}</Text>
            </Row>
           
            </>
        }>
            <Row align={'middle'}>
                <Search
                    placeholder="Nhập từ khóa tìm kiếm"
                    allowClear
                    size="large"
                    onSearch={onSearch}
                    
                    style={{flex: 1, paddingRight: '16px'}}
                    />
                {/* <DropdownComponent items={{items: items, onClick: (value) => {setSize(Number(value.key))}}} label={'Chọn trạng thái'} flex={0.3}></DropdownComponent> */}
                <Button
                    type="primary"
                    icon={<PlusOutlined></PlusOutlined>}
                    onClick={() => {
                      setSelectUser(null);
                      showModal();
                    }}
                    >
                      
                      {'Thêm nhân viên'}
                      
                </Button>
            </Row>
            <Row style={{paddingTop: '20px', minHeight: '55vh', paddingBottom: 20}}>
                <Table 
                    scroll={{x: '60vh', y: '50vh'}}
                    style={{flex: 1}}
                    columns={columns} 
                    dataSource={data}
                    pagination={false} 
                    />
            </Row>
            <Row style={{justifyContent: 'flex-end', flex: 1}}>
              <Pagination defaultCurrent={1} total={length} pageSize={size} onChange={(page, pageSize) => {setCurrentPage(page); setSize(pageSize);}} style={{justifyContent: 'flex-end'}}></Pagination>
            </Row>
       </Card>
       <Modal title={<></>} open={isModalOpen} onCancel={handleCancel}  footer={[]} width={"150vh"} closeIcon={<></>}>
          <AddAdmin data={selectUser} onClickAdd={() => {
            handleCancel();
          }}></AddAdmin>
       </Modal>
       <Modal title="Xóa người dùng" open={isModalConfirmOpen} onOk={handleOk} onCancel={() => {setIsModalConfirmOpen(false)}}>
        <p>Bạn có chắc muốn xóa người dùng {deleteUserData?.Client.title}</p>
      </Modal>
      </Spin>
    </Content>);
}

interface DataType {
    key: string;
    Client: {title: string; phone: string;}
    username?: string;
    role?: string;
  }
  
  
  
  