import { Col, Pagination, Row, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getLocationApprove, approveLocationApprove, rejectLocationApprove } from "../../../store/controls/LocationApprove.slice";
import { useSelectorRoot } from "../../../store/store";
import { DataItem, ItemRequest } from "./ItemRequest";

export const PositionRequest = () => {
    const dispatch = useDispatch();
    const {locationApproveData, loading, pagination} = useSelectorRoot(state => state.locationApproved);
    const [length, setLength] = useState(pagination?.total);
    const [size, setSize] = useState(pagination?.pageSize ?? 10);
    const [currentPage, setCurrentPage] = useState(pagination?.page ?? 1);
    const {isSuccess} =useSelectorRoot(state => state.login)
    var [dataSource, setDataSource]= useState<DataItem[]>([]);
    useEffect(() => {
        dispatch(getLocationApprove({
            page: currentPage,
            size: size
        }))
    }, [currentPage, dispatch, isSuccess, size])
    const [load, setLoad] = useState(loading);
    useEffect(() => {
        if(loading) {
            setDataSource([]);
        }
        setLoad(loading);
      }, [loading])
    useEffect(() => {
        
        setDataSource(locationApproveData?.data.map(x => ({
            id: x.id,
            name: x.attributes.client.data?.attributes.name,
            KTV: x.attributes.userRequest.data?.attributes.fullName,
            position: {lat:x.attributes.latitude, lng: x.attributes.longitude },
            area: x.attributes.client.data?.attributes.village?.data?.attributes.name,
            district: x.attributes.client.data?.attributes.district?.data?.attributes.name,
            province: x.attributes.client.data?.attributes.city?.data?.attributes.name,
            ward: x.attributes?.client?.data?.attributes?.ward?.data?.attributes.name,
            address: x.attributes.client.data?.attributes.address,
            status: x.attributes.status
        })) ?? [])
    }, [locationApproveData]);
    useEffect(() => {
        setLength(pagination?.total);
        setCurrentPage(pagination?.page?? 1);
        setSize(pagination?.pageSize ?? 10);
    }, [pagination]);
    const onUpdate = (value: DataItem) => {
        dispatch(approveLocationApprove({
            id: value.id.toString(),
            page: currentPage,
            size: size
        }))
    }
    const onReupdate = (value: DataItem) => {
        dispatch(rejectLocationApprove(
            {
                id: value.id.toString(),
                page: currentPage,
                size: size
            }
        ))
    }
    return (
        <Content>
            <Spin spinning={load}>
            <Row style={{width: "100%", height: "calc(100vh - 200px)", overflowY: "scroll"}}>
            { dataSource?.map(x => <ItemRequest data={x} onUpdate={onUpdate} onReupdate={onReupdate}></ItemRequest>)}
            </Row>
            
        
            <Row style={{justifyContent: 'flex-end', flex: 1, marginTop: 10, width: "100%", }}>
                <Pagination defaultCurrent={1} total={length} pageSize={size} onChange={(page, pageSize) => {
                    setCurrentPage(page); setSize(pageSize);
                    dispatch(getLocationApprove({
                        page: page,
                        size: pageSize
                    }))
                    }} style={{justifyContent: 'flex-end'}}></Pagination>
            </Row>
            </Spin>
        </Content>
    
    );
}