import { Button, Card, Col, Input, MenuProps, Modal, Pagination, Row, Spin, Table, Tag, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import { DropdownComponent, ItemTypeProp } from "../../../components/Dropdown/DropdownComponent";
import Utils from "../../../common/Utils";
import { FilterItem } from "../../../common/define-types";
import { ColumnsType } from "antd/es/table/interface";
import { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import exportSvg from "../../../image/icon/export.svg";
import {PlusOutlined, FormOutlined, DeleteOutlined} from '@ant-design/icons'
import { NewUserPopup } from "./NewUserPopup";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { pushBreadcrum } from "../../../store/slice/controlSlice";
import { createBreadcrum, RootClaim } from "../../Routes";
import { useSelectorRoot } from "../../../store/store";
import { createClient, deleteClient, getClient } from "../../../store/controls/Client.slice";
import { CSVLink, CSVDownload } from "react-csv";
import ClientAPI, { Client } from "../../../api/Client.api";
const {Text} = Typography;
const { Search } = Input;
var listUser : Client[] = [];
var textSearch = '';
Utils.getValueLocalStorage('search').then(value => {
  textSearch = value;
});
export const UserManager = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const headers = [
      { label: "Họ và tên", key: "name" },
      { label: "Số điện thoại", key: "phone" },
      { label: "Tỉnh/Thành phố", key: "city" },
      { label: "Huyện/Quận", key: "district" },
      { label: "xã/phường", key: "ward" },
      { label: "thôn/xóm", key: "village" },
      { label: "Địa chỉ", key: "adress" },
      { label: "Mã hợp đồng", key: 'code'},
      { label: "Mã hố van", key: 'gaugesCode'},
      { label: "Ngày ký hợp đồng", key: 'contractDate'},
      { label: "Ngày tạo", key: 'createdAt'}
    ];
    const columns: ColumnsType<DataType> = [
      {
        title: 'Khách hàng',
        dataIndex: 'Client',
        key: 'Client',
        render: (text) => <><Text style={{fontSize: 15, fontWeight: 500}}>{text.title}</Text><br/><Text style={{color: '#2222225A'}}>{text.phone}</Text></>,
      },
      {
        title: 'Mã khách hàng',
        dataIndex: 'Client',
        key: 'ClientCode',
        render: (text) => <><Text style={{color: '#2222225A'}}>{text.clientCode}</Text></>
      },
      {
        title: 'Mã đồng hồ',
        dataIndex: 'Client',
        key: 'GaugesCode',
        render: (text) => <><Text style={{color: '#2222225A'}}>{text.gaugesCode}</Text></>
      },
      {
        title: 'Phường/xã/thị trấn',
        dataIndex: 'Ward',
        key: 'Ward',
        render: (text) => <><Text style={{color: '#2222225A'}}>{text}</Text></>
      },
      {
        title: 'Chọn',
        key: '',
        dataIndex: 'key',
        render: (id, item) => (
          <>
            <Button icon={<FormOutlined />} onClick={() => {edit(id)}} style={{marginRight: 20}}></Button>
            <Button icon={<DeleteOutlined /> } onClick={() => {
              setAgree(item);
              setIsModalConfirmOpen(true);
            }}></Button>
          </>
        ),
      },
    ];
    const {clientData, pagination, loading, search} = useSelectorRoot(state => state.client);
    const {isSuccess} = useSelectorRoot(state => state.login);
    const [data, setData] = useState<DataType[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchText, setSearchText] = useState<string | undefined>(search);
    const [listExport, setListExport] = useState<Client[]>();
    const [load, setLoad] = useState(loading);
    useEffect(() => {
      setLoad(loading);
    }, [loading]);
    useEffect(() => {
      setSearchText(search);
    }, [search])
    const onSearch = (value: string) => {
      setSearchText(value);
      textSearch = value;
      if(!loading) dispatch(getClient({
        page: currentPage, 
        size: size,
        ward: selectedWard?.key,
        district: selectDistrict?.key,
        village: selectedArea?.key,
        province: selectedProvince?.key,
        search: value
      }));
    };
    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const edit = (id: string) => {
      var data = createBreadcrum('/userdetail/' + id)
      dispatch(pushBreadcrum(data));
      history.push('/userdetail/' + id);
    }

    const [agree, setAgree] = useState<DataType>()
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const handleOk = () => {
      dispatch(deleteClient({
        id: agree?.key ?? '',
        query: {
          page: currentPage, 
          size: size,
          ward: selectedWard?.key,
          district: selectDistrict?.key,
          village: selectedArea?.key,
          province: selectedProvince?.key,
        }
      }))
        setIsModalConfirmOpen(false);
    }

    const [length, setLength] = useState(pagination?.total ?? 10);
    const [size, setSize] = useState(pagination?.pageSize ?? 10);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
      var searchStorage = Utils.getValueLocalStorage('search').then(value => {
        console.log('search', value);
        textSearch = value;
        setSearchText(value);
        dispatch(getClient({
          page: currentPage, 
          size: size,
          ward: selectedWard?.key,
          district: selectDistrict?.key,
          village: selectedArea?.key,
          province: selectedProvince?.key,
          search: value ?? ''
        }));
      });
      
    }, [isSuccess]);
    useEffect(() => {
      setData(
        clientData?.data.map(x => (
          {
        key: x.id.toString() ?? '',
        Client: {title:x.attributes?.name ?? '', phone: x.attributes?.phone ?? '', clientCode: x.attributes?.code ?? '', gaugesCode: x.attributes?.gaugesCode ?? ''},
        Province: x.attributes?.city?.data?.attributes?.name,
        District: x.attributes?.district?.data?.attributes?.name,
        Ward: x.attributes?.ward?.data?.attributes?.name
        })) ?? [])
    }, [clientData])
    useEffect(() => {
      setLength(pagination?.total ?? 10);
      setSize(pagination?.pageSize ?? 10)
    }, [pagination])

    const {district, area, province, ward} = useSelectorRoot(state => state.location);
    const [listArea, setListArea] = useState(area?.map(x => ({label: x.attributes.name, key: x.attributes.name})) ?? []);
    const [listProvince, setListProvince] = useState(province?.map(x => ({label: x.attributes.name, key: x.attributes.name})) ?? []);
    const [listDistrict, setListDistrict] = useState(district?.map(x => ({label: x.attributes.name, key: x.attributes.name})) ?? []);
    const [listWard, setListWard] = useState(ward?.map(x => ({label: x.attributes.name, key: x.attributes.name})) ?? []);

    const [selectDistrict, setSelectDistrict] = useState<ItemTypeProp>();
    const [selectedWard, setSelectedWard] = useState<ItemTypeProp>();
    const [selectedProvince, setSelectedProvince] = useState<ItemTypeProp>();
    const [selectedArea, setSelectedArea] = useState<ItemTypeProp>();

    useEffect(() => {
      setListDistrict(district?.map(x => ({label: x.attributes.name, key: x.id.toString() })).concat([{label: "Tất cả", key: "-1"}]) ?? []);
    }, [district]);
    useEffect(() => {
      setListProvince(province?.map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? []);
    }, [province]);
    useEffect(() => {
      setListArea(area?.map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? []);
    }, [area]);
    useEffect(() => {
      setListWard(ward?.map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? []);
    }, [ward]);
    useEffect(() => {
      setListWard(ward?.filter(x => x.attributes.district.data.id.toString() == selectDistrict?.key || selectDistrict?.key == '-1').map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? [])
    }, [selectDistrict])
    useEffect(() => {
      setListArea(area?.filter(x => x.attributes.ward.data.id.toString() == selectedWard?.key || selectedWard?.key == '-1').map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? [])
    }, [selectedWard])
    useEffect(() => {
      setListDistrict(district?.filter(x => x.attributes.city.data.id.toString() == selectedProvince?.key || selectedProvince?.key == '-1').map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? [])
    }, [selectedProvince])
    return (
    <Content style={{padding: "0px 0px 0px 15px"}} >
      <Spin spinning={load}>
       <Card title={
            <>
            <Row style={{margin: '20px 8px 16px 0px'}}>
                <Text style={{fontSize: 22, fontWeight: 600}}>{'Có '+ length + ' khách hàng'}</Text>
            </Row>
            <Row style={{margin: '20px 8px 16px 0px'}}>
                <DropdownComponent items={{items: listProvince, onClick: (value) => {
                  setSelectedProvince(value);
                  dispatch(getClient({
                    page: currentPage, 
                    size: size,
                    ward: selectedWard?.key,
                    district: selectDistrict?.key,
                    village: selectedArea?.key,
                    province: value.key,
                    search: searchText
                  }));
                  }}} label={'Chọn tỉnh/thành phố'} value={selectedProvince?.label} flex={1}></DropdownComponent>
                <DropdownComponent items={{items: listDistrict, onClick: (value) => {
                  setSelectDistrict(value);
                  dispatch(getClient({
                    page: currentPage, 
                    size: size,
                    ward: selectedWard?.key,
                    district: value.key,
                    village: selectedArea?.key,
                    province: selectedProvince?.key,
                    search: searchText
                  }));
                  }}} label={'Chọn quận/huyện'} value={selectDistrict?.label} flex={1}></DropdownComponent>
                <DropdownComponent items={{items: listWard, onClick: (value) => {
                  setSelectedWard(value);
                  dispatch(getClient({
                    page: currentPage, 
                    size: size,
                    ward: value.key,
                    district: selectDistrict?.key,
                    village: selectedArea?.key,
                    province: selectedProvince?.key,
                    search: searchText
                  }));
                  }}} label={'Chọn xã/phường/thị trấn'} value={selectedWard?.label} flex={1}></DropdownComponent>
                <DropdownComponent items={{items: listArea, onClick: (value) => {
                  setSelectedArea(value);
                  dispatch(getClient({
                    page: currentPage, 
                    size: size,
                    ward: selectedWard?.key,
                    district: selectDistrict?.key,
                    village: value.key,
                    province: selectedProvince?.key,
                    search: searchText
                  }));
                  }}} label={'Chọn thôn/xóm'} value={selectedArea?.label} flex={1}></DropdownComponent>
            </Row>
            </>
        }>
            <Row align={'middle'}>
                <Search
                    placeholder="Nhập từ khóa tìm kiếm"
                    allowClear
                    size="large"
                    value={textSearch}
                    onChange={(e) => {
                      console.log(e.target.value)
                      textSearch = e.target.value;
                      setSearchText(e.target.value)
                    }}
                    onSearch={onSearch}
                    style={{flex: 1, paddingRight: '16px'}}
                    />
                    {/* <DropdownComponent items={{items: listSize, onClick: (value) => {setSize(Number(value.key))}}} label={'Số phần tử'} flex={0.2}></DropdownComponent> */}
                <Button
                    type="primary"
                    style={{marginRight: '10px'}}
                    onClick={() => {
                      var conutPage = pagination?.pageCount ?? 1;
                      if (listUser.length < (pagination?.total ?? 0)){
                        setLoad(true)
                        ClientAPI.get({
                          page: 1, 
                          ward: selectedWard?.key,
                          district: selectDistrict?.key,
                          village: selectedArea?.key,
                          province: selectedProvince?.key,
                          search: searchText,
                          size: 500
                        }).subscribe(async (json) => {
                          conutPage = json?.meta.pagination.pageCount ?? 1;
                          listUser = json?.data.map(x => x.attributes) ?? [];
                          
                          for(var i = 2; i < conutPage + 1; i++) {
                            var data = await ClientAPI.get({
                              page: i, 
                              ward: selectedWard?.key,
                              district: selectDistrict?.key,
                              village: selectedArea?.key,
                              province: selectedProvince?.key,
                              search: searchText,
                              size: 500
                            }).toPromise();
                            listUser = listUser.concat(data?.data?.map(x => x.attributes) ?? [])
                            console.log(listUser.length);
                          }
                          setListExport(listUser);
                          setTimeout(() => {
                            setLoad(false)
                            document.getElementById('exportCXV')?.click()
                          }, 2000)
                          
                        });
                      }
                    }}
                    >
                      <Row>
                      <ReactSVG src={exportSvg} style={{marginRight: 5, width: 18, height: 18}}></ReactSVG>
                      {'Xuất file'}
                      </Row>
                      
                </Button>
                <CSVLink
                style={{display: 'none'}}
                  headers={headers}
                  filename={`Danh sách khách hàng${selectedProvince? ' ' + selectedProvince : ''}${selectedWard?' ' + selectedWard : ''}${selectDistrict?' ' + selectDistrict : ''}${selectedArea? ' ' + selectedArea : ''}.csv`}
                  data={listExport?.map(x => ({
                    id: x.id,
                    name: x.name,
                    code: x.code,
                    phone: x.phone,
                    longitude: x.longitude,
                    latitude: x.latitude,
                    address: x.address,
                    createdAt: x.createdAt,
                    updatedAt: x.updatedAt,
                    locationApproveStatus: x.locationApproveStatus,
                    contractDate: x.contractDate,
                    taxCode: x.taxCode,
                    gaugesCode: x.gaugesCode,
                    city: x.city?.data?.attributes.name,
                    ward: x.ward?.data?.attributes.name,
                    village: x.village?.data?.attributes.name,
                    district: x.district?.data?.attributes.name,
                  })) ?? []}
                  id="exportCXV"
                />
                <Button
                    type="primary"
                    icon={<PlusOutlined></PlusOutlined>}
                    onClick={showModal}
                    >
                      
                      {'Thêm khách hàng'}
                      
                </Button>
            </Row>
            <Row style={{paddingTop: '20px', minHeight: '55vh', paddingBottom: 20}}>
                <Table 
                    scroll={{x: '60vh', y: '50vh'}}
                    style={{flex: 1}}
                    columns={columns} 
                    dataSource={data}
                    pagination={false} 
                    />
            </Row>
            <Row style={{justifyContent: 'flex-end', flex: 1}}>
              <Pagination defaultCurrent={1} total={length} pageSize={size} onChange={(page, pageSize) => {
                setCurrentPage(page); setSize(pageSize);
                dispatch(getClient({
                  page: page, 
                  size: pageSize,
                  ward: selectedWard?.key,
                  district: selectDistrict?.key,
                  village: selectedArea?.key,
                  province: selectedProvince?.key,
                  search: searchText
                }));
                }} style={{justifyContent: 'flex-end'}}></Pagination>
            </Row>
       </Card>
       <Modal title={<></>} open={isModalOpen} onCancel={handleCancel}  footer={[]} width={"150vh"} closeIcon={<></>}>
          <NewUserPopup onCreate={(value) => {
            dispatch(createClient({data: value, query: {
              page: currentPage, 
                  size: size,
                  ward: selectedWard?.key,
                  district: selectDistrict?.key,
                  village: selectedArea?.key,
                  province: selectedProvince?.key,
            }}));
            handleCancel();
          }}></NewUserPopup>
       </Modal>
       <Modal title="Xóa người dùng" open={isModalConfirmOpen} onOk={handleOk} onCancel={() => {setIsModalConfirmOpen(false)}}>
        <p>Bạn có chắc muốn xóa người dùng {agree?.Client?.title}</p>
      </Modal>
      </Spin>
    </Content>);
}

interface DataType {
    key?: string;
    Client?: {title?: string; phone?: string; clientCode? : string}
    Province?: string;
    Ward?: string;
    District?: string;
  }
  