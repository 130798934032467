import { Button, Card, Col, Form, Input, Row, Typography } from "antd";
import { useState } from "react";
import { TileLayer, Marker, Popup, MapContainer } from "react-leaflet";

import { ReactSVG } from "react-svg";
import exportSvg from "../../../image/icon/export.svg";
const {Text} = Typography;

export interface DataItem {
    id: string | number,
    name?: string,
        KTV?: string,
        position: {lat: number, lng: number},
        area?: string,
        district?: string,
        province?: string,
        ward?: string,
        address?: string;
        status?: number;
}

export const ItemRequest = (props: {data: DataItem, onUpdate: (value: DataItem) => void, onReupdate: (value: DataItem) => void}) => {
    const [data, setData ] = useState(props.data);
    const [disabled, SetDisabled] = useState(true);
    return (
        <Card style={{width: '100%', marginBottom: 25}}>
            <Row style={{justifyContent: 'center', alignItems: 'center', marginBottom: 10}}>
                <Col sm={12} xl={12} md={12}>
                    <Text style={{fontSize: 18, fontWeight: 500, color: '#2222225A'}}>{data.KTV}</Text>
                    <br/>
                    <Text style={{fontSize: 18, fontWeight: 500, color: '#222222'}}>{data.name}</Text>
                </Col>
                <Col sm={12} xl={12} md={12} >
                    <Row style={{justifyContent: "flex-end", flex: 1}}>
                    {
                        props.data.status == 1 ?
                        <Button type="primary" style={{backgroundColor: '#FF43431F', color: '#FF4343', cursor: "default"}}>Yêu cầu cập nhật lại</Button>
                        : <>
                            <Button danger type="primary" style={{ marginRight: 5 }} onClick={() => props.onReupdate(data)}>
                                <Row>
                                    <ReactSVG src={exportSvg} style={{ marginRight: 5, width: 18, height: 18 }}></ReactSVG>
                                    {'Yêu cầu cập nhật lại'}
                                </Row>
                            </Button>
                            <Button type="primary" style={{ marginLeft: 10 }} onClick={() => {
                                            props.onUpdate(data)
                                            }}>
                                    <Row>
                                        <ReactSVG src={exportSvg} style={{ marginRight: 5, width: 18, height: 18 }} ></ReactSVG>
                                        {"Cập nhật"}
                                    </Row>
                                </Button>
                                </>
                    }
                    </Row>
                </Col>
                
            </Row>
            <Row>
                <Col xl={12} md={12} sm={24}>
                    <Form
                        layout="vertical"
                        name="ItemRequest"
                        requiredMark={false}
                    >
                        <Row>
                            <Col xl={12} md={12} sm={24}>
                                <Form.Item
                                label="Vĩ độ"
                                name="latitute"
                                rules={[
                                    { required: true, message: 'Vĩ độ' },
                                ]}
                                style={{flex: 1, marginRight: 25}}
                                >
                                    <Input placeholder={data.position.lat.toString() ?? 'Vĩ độ'} value={data.position.lat} disabled={disabled}/>
                                </Form.Item>
                            </Col>
                            <Col xl={12} md={12} sm={24}>
                                <Form.Item
                                label="Kinh độ"
                                name="longtitute"
                                style={{flex: 1, marginRight: 25}}
                                rules={[{ required: true, message: 'Kinh độ' }]}
                                >
                                    <Input placeholder={data.position.lng.toString() ?? 'Kinh dộ'} value={data.position.lng} disabled={disabled}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} md={12} sm={24}>
                                <Form.Item
                                label="Thành phố/tỉnh"
                                name="province"
                                rules={[
                                    { required: true, message: 'Thành phố/tỉnh' },
                                ]}
                                style={{flex: 1, marginRight: 25}}
                                >
                                    <Input placeholder={data.province ?? 'Thành phố/tỉnh'} value={data.province} disabled={disabled}/>
                                </Form.Item>
                            </Col>
                            <Col xl={12} md={12} sm={24}>
                                <Form.Item
                                label="Huyện/quận"
                                name="district"
                                style={{flex: 1, marginRight: 25}}
                                rules={[{ required: true, message: 'Huyện/quận' }]}
                                >
                                    <Input placeholder={data.district ?? 'Huyện/quận'} value={data.district} disabled={disabled}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} md={12} sm={24}>
                                <Form.Item
                                label="Phường/xã/thị trấn"
                                name="ward"
                                rules={[
                                    { required: true, message: 'Phường/xã/thị trấn' },
                                ]}
                                style={{flex: 1, marginRight: 25}}
                                >
                                    <Input placeholder={data.ward ?? 'Phường/xã/thị trấn'} value={data.ward} disabled={disabled}/>
                                </Form.Item>
                            </Col>
                            <Col xl={12} md={12} sm={24}>
                                <Form.Item
                                label="Thôn/khu phố"
                                name="area"
                                style={{flex: 1, marginRight: 25}}
                                rules={[{ required: true, message: 'Thôn/khu phố' }]}
                                >
                                    <Input placeholder={data.area ?? 'Thôn/khu phố'} value={data.area} disabled={disabled}/>
                                </Form.Item>
                            </Col>
                            
                        </Row>
                        <Row>
                            <Form.Item
                            label="Địa chỉ"
                            name="address"
                            rules={[
                                { required: true, message: 'Địa chỉ' },
                            ]}
                            style={{flex: 1, marginRight: 25}}
                            >
                                <Input placeholder={data.address ?? 'Địa chỉ'} value={data.address} disabled={disabled}/>
                            </Form.Item>
                        </Row>
                    </Form>
                </Col>
                <Col xl={12} md={12} sm={24} style={{minHeight: '316px'}}>
                    <MapContainer center={data.position} zoom={18} scrollWheelZoom={false} style={{width: '100%', height: '100%'}}>
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={data.position}>
                        </Marker>
                    </MapContainer>
                </Col>
            </Row>
        </Card>
    );
}