import { Row, Col, Input, Button, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import exportSvg from "../../../image/icon/export.svg";
import { createUser, updateUser } from "../../../store/controls/User.slice";
import { useSelectorRoot } from "../../../store/store";
const {Text} = Typography;

export interface AddOperatorItem {
    id?: string;
    username?: string;
    password?: string;
    name?: string;
    email?: string;
    phone?: string;
}
export const AddOperator = (props: {data?: AddOperatorItem | null, onUpdate?: () => void }) => {
    const dispatch = useDispatch();
    const [newUser, setNewUser] = useState<AddOperatorItem>(props.data ?? {});
    const {pagination} = useSelectorRoot(state => state.user)
    useEffect(() => {
        setNewUser(props.data ?? {})
    }, [props.data])
   
  return (
    <div style={{width: "100%", maxHeight: "75vh"}}>
        <Row style={{justifyContent: 'center', alignItems: 'center', marginBottom: 16, marginRight: 26}}>
                <Col sm={24} xl={12} md={12}>
                    <Text style={{fontSize: 20, fontWeight: 500, color: '#222222'}}>{"Thông tin vận hành"}</Text>
                    
                </Col>
                <div style={{flex: 1}}></div>
                
                <Button type="primary" onClick={() => {
                    if (props.data == null){
                        dispatch(createUser({
                            page: pagination?.page ?? 1,
                            size: pagination?.pageSize ?? 10,
                            role: 7,
                            data: {
                                confirmed: true,
                                username: newUser.username,
                                password: newUser.password,
                                fullName: newUser.name,
                                email: newUser.email,
                                phone: newUser.phone,
                                role: {
                                    connect: [{id: 7}]
                                }
                            }
                        }))
                    } else {
                        dispatch(updateUser({
                            
                            page: pagination?.page ?? 1,
                            size: pagination?.pageSize ?? 10,
                            role: 7,
                            data: {
                                id: props.data.id,
                                confirmed: true,
                                username: newUser.username,
                                password: newUser.password,
                                fullName: newUser.name,
                                email: newUser.email,
                                phone: newUser.phone,
                            }
                        }))
                    }
                    props.onUpdate && props.onUpdate();
                    }}>
                    <Row>
                      <ReactSVG src={exportSvg} style={{marginRight: 5, width: 18, height: 18}}></ReactSVG>
                      {props.data != null? 'Lưu thay đổi' : 'Thêm người dùng'}
                    </Row>
                </Button>
        </Row>
        <div style={{width: "100%", maxHeight: "calc(75vh - 50px)", overflowY: "auto"}}>
                <Row>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Tên đăng nhập'}</Text>
                        <Input placeholder={'Nhập tên đăng nhập'}
                        value={newUser.username}
                        onChange={(text) => setNewUser({ 
                                ...newUser, 
                                username: text.target.value
                            })}></Input>
                    </Col>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Mật khẩu'}</Text>
                        <Input.Password placeholder={'Nhập mật khẩu'} onChange={(text) => setNewUser({ 
                                ...newUser, 
                                password: text.target.value
                            })} ></Input.Password>
                    </Col>
                </Row>
               
                <Row>
                    <Col xl={8} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Họ và tên'}</Text>
                        <Input placeholder={'Nhập họ và tên'}
                        value={newUser.name}
                        onChange={(text) => setNewUser({ 
                                ...newUser, 
                                name: text.target.value
                            })}></Input>
                    </Col>
                    <Col xl={8} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Địa chỉ email'}</Text>
                        <Input placeholder={'Nhập email'} type="email"
                        value={newUser.email}
                         onChange={(text) => setNewUser({ 
                                ...newUser, 
                                email: text.target.value
                            })} ></Input>
                    </Col>
                    <Col xl={8} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Số điện thoại'}</Text>
                        <Input placeholder={'Nhập số điện thoại'} type="number"
                        value={newUser.phone}
                        onChange={(text) => setNewUser({ 
                                ...newUser, 
                                phone: text.target.value
                            })} ></Input>
                    </Col>
                </Row>
                
        </div>
    </div> );
}