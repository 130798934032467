import { Button, Col, DatePicker, Input, Row, Typography, notification, AutoComplete, Checkbox } from "antd";
import { useCallback, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import exportSvg from "../../../image/icon/export.svg";
import {EyeOutlined, UploadOutlined} from "@ant-design/icons";
import { TileLayer, Marker, MapContainer, useMapEvents, Popup, useMap } from "react-leaflet";
import { LatLng, LatLngExpression, LeafletMouseEvent, LocationEvent } from "leaflet";
import { FilterItem } from "../../../common/define-types";
import { DropdownComponent } from "../../../components/Dropdown/DropdownComponent";
import { useSelectorRoot } from "../../../store/store";
import { useDispatch } from "react-redux";
import { createClient } from "../../../store/controls/Client.slice";
import { ClientRequest, Contract } from "../../../api/Client.api";
import dayjs from "dayjs";
import SYSTEM_CONSTANTS from "../../../common/constants";
import HttpClient from "../../../api/http-client";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
const {Text} = Typography;
interface UserData {
    name?: string;
    codeContract?: string;
    phone?: string;
    city?: string;
    district?: string;
    ward?: string;
    area?: string;
    address?: string;
    priceCatagory?: string;
    dateContract?: Date | null;
    taxCode?: string;
    waterCode?: string;
    line?: string;
    point?: {
        lat: number;
        lng: number;
    },
    contract?: Contract;
    clientType?: number;
    cleaningFee?: boolean
    
}
var isSearching = false;
export const NewUserPopup = (props: {data?: UserData, onCreate: (value: ClientRequest) => void, maxHeightContent?: string} ) => {
    const dispatch = useDispatch();
    const [newUser, setNewUser] = useState<UserData>(props.data ?? {});
    const [searchText, setSearchText] = useState<string| undefined>();
    const {district, line, priceCatagory, area, province, ward} = useSelectorRoot(state => state.location);
    const [listArea, setListArea] = useState<{label: string, key: string }[]>([]);
    const [listProvince, setListProvince] = useState<{label: string, key: string }[]>([]);
    const [listDistrict, setListDistrict] = useState<{label: string, key: string }[]>([]);
    const [listWard, setListWard] = useState<{label: string, key: string }[]>([]);
    const [listline, setListLine] = useState<{label: string, key: string }[]>([]);
    const [listPriseCatagory, setPriceCatagory] = useState<{label: string, key: string }[]>([]);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [resultSearch, setResultSearch] = useState<SearchResult<any>[]>([]);
    const [api, contextHolder] = notification.useNotification();
    
    const onSearch = (value: string) => {
        const provider = new OpenStreetMapProvider();
        setSearchText(value);
        if (isSearching) return;
        isSearching = true;
        provider.search({ query: value }).then((result) => {
            isSearching = false;
            setResultSearch(result.map(x => ({
                ...x,
                value: x.label
            })));
        });
    }

    const onSelect = (value: string, item: SearchResult<any>) => {
        setSearchText(value);
        setNewUser({
            ...newUser,
            point: {
                lat: item.y,
                lng: item.x
            }
        });
    }

  
    const   changeHandler = (event: any) => {
        var file = event.target.files[0] as File;
        if (!file) {
            return;
        }
        if (file.size > 10 * 1024 * 1024) {
            api['error']({
                message: 'Tải tệp tin lỗi',
                description: "Không thể tải tệp tin lớn hơn 10MB",
              });
            return;
        }
        if (![".pdf",".doc",".docx"].includes(file.name.substring(file.name.lastIndexOf('.')).toLocaleLowerCase())) {
            api['error']({
                message: 'Tải tệp tin lỗi',
                description: "Không thể tải tệp tin khác định dạng .pdf,.doc,.docx",
              });
            return;
        }
		setSelectedFile(file);
        const formData = new FormData();

		formData.append('files', file);
        formData.append('fileInfo', `{\"name\": \"${file.name}\"}`)
        HttpClient.upload(SYSTEM_CONSTANTS.HOST + '/api/upload', formData).subscribe(
            (fileInfo: any) => {
                setNewUser({
                    ...newUser,
                    contract: {
                       id: fileInfo[0].id,
                       attributes: fileInfo[0]
                    }
                });
            }
        );
		
	};
    useEffect(() => {
        setListDistrict(district?.map(x => ({label: x.attributes.name, key: x.id.toString() })) ?? []);
      }, [district]);
      useEffect(() => {
        setListProvince(province?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
      }, [province]);
      useEffect(() => {
        setListArea(area?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
      }, [area]);
      useEffect(() => {
        setListWard(ward?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
      }, [ward]);
    useEffect(() => {
        setListLine(line?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
    }, [line]);
    useEffect(() => {
        setPriceCatagory(priceCatagory?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
    }, [priceCatagory]);
    useEffect(() => {
        setNewUser(props.data ?? {});
    }, [props.data])
   
  return (
    <div style={{width: "100%"}}>
        {contextHolder}
        <Row style={{justifyContent: 'center', alignItems: 'center', marginBottom: 16, marginRight: 26}}>
                <Col sm={24} xl={12} md={12}>
                    <Text style={{fontSize: 20, fontWeight: 500, color: '#222222'}}>{"Thông tin khách hàng"}</Text>
                    
                </Col>
                <div style={{flex: 1}}></div>
                
                <Button type="primary" onClick={() => {
                    props.onCreate({
                        data: {
                            city: newUser.city ? {connect: [Number(newUser.city)]} : undefined,
                            district: newUser.district ? {connect: [Number(newUser.district)]} : undefined,
                            ward: newUser.ward ? {connect: [Number(newUser.ward)]} : undefined,
                            village: newUser.area? { connect: [Number(newUser.area)]} : undefined,
                            line: newUser.line ? {connect: [Number(newUser.line)]} : undefined,
                            priceCategory: newUser.priceCatagory ? {connect: [Number(newUser.priceCatagory)]} : undefined,
                            name: newUser.name ?? '',
                            code: newUser.codeContract ?? '',
                            phone: newUser.phone ?? '',
                            longitude: newUser.point?.lng,
                            latitude: newUser.point?.lat,
                            address: newUser.address ?? '',
                            contractDate: newUser.dateContract,
                            gaugesCode: newUser.waterCode,
                            contract: newUser.contract?.id,
                            clientType: newUser.clientType,
                            cleaningFee: newUser.cleaningFee,
                            taxCode: newUser.taxCode
                        }
                    })
                    }}>
                    <Row>
                      <ReactSVG src={exportSvg} style={{marginRight: 5, width: 18, height: 18}} ></ReactSVG>
                      {props.data ? "Lưu thay đổi" : 'Thêm khách hàng'}
                    </Row>
                </Button>
            </Row>
        <div style={{width: "100%", maxHeight: props.maxHeightContent ?? "calc(75vh - 50px)", overflowY: "auto"}}>
            <Row>
                <Col xl={12} md={24} sm={24} style={{ marginBottom: 16}}>
            <Row>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Tên khách hàng'}</Text>
                    <Input placeholder={'Nhập tên khách hàng'} 
                    value={newUser.name}
                    onChange={
                        (text) => setNewUser({ 
                            ...newUser, 
                            ...{name: text.target.value}
                        })
                    }></Input>
                </Col>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Mã hợp đồng'}</Text>
                    <Input placeholder={'Nhập mã hợp đồng'} 
                    value={newUser.codeContract}
                    onChange={
                        (text) => setNewUser({ 
                            ...newUser, 
                            ...{codeContract: text.target.value}
                        })
                    }></Input>
                </Col>
            </Row>
            <Row>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Tỉnh/thành phố'}</Text>
                    <DropdownComponent label={'Tỉnh/thành phố'} 
                    value={listProvince.find(x => x.key == newUser.city)?.label}
                    items={
                        {items: listProvince, 
                            onClick: 
                            (text) => {setNewUser({ 
                                ...newUser, 
                                ...{city: text.key}
                            });},
                        }
                    }></DropdownComponent>
                </Col>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Huyện'}</Text>
                    <DropdownComponent label={'Huyện'} 
                    value={listDistrict.find(x => x.key == newUser.district)?.label}
                    items={
                         {items: listDistrict, 
                            onClick: 
                            (text) => {setNewUser({ 
                                ...newUser, 
                                ...{district: text.key}
                            });},
                        }
                    }></DropdownComponent>
                </Col>
            </Row>
            <Row>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Xã/phường/thị trấn'}</Text>
                    <DropdownComponent label={'Xã/phường/thị trấn'} 
                    value={listWard.find(x => x.key == newUser.ward)?.label}
                    items={
                        {
                            items: listWard,
                            onClick: (text) => {setNewUser({ 
                                ...newUser, 
                                ...{ward: text.key}
                            });},
                        }
                    }></DropdownComponent>
                </Col>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Thôn/khu phố'}</Text>
                    <DropdownComponent label={'Thôn/khu phố'} 
                    value={listArea.find(x => x.key == newUser.area)?.label}
                    items={
                         {
                            items: listArea,
                            onClick: (text) => {setNewUser({ 
                                ...newUser, 
                                ...{area: text.key}
                            });},
                        }
                    }></DropdownComponent>
                </Col>
            </Row>
            <Row>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Số điện thoại'}</Text>
                    <Input placeholder={'Nhập số điện thoại'} 
                    value={newUser.phone}
                    onChange={
                        (text) => setNewUser({ 
                            ...newUser, 
                            ...{phone: text.target.value}
                        })
                    }></Input>
                </Col>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Địa chỉ'}</Text>
                    <Input placeholder={'Nhập địa chỉ'} 
                    value={newUser.address}
                    onChange={
                        (text) => setNewUser({ 
                            ...newUser, 
                            ...{address: text.target.value}
                        })
                    }></Input>
                </Col>
            </Row>
            <Row>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Bảng giá áp dụng'}</Text>
                    <DropdownComponent label={'Bảng giá áp dụng'}
                    value={listPriseCatagory.find(x => x.key == newUser.priceCatagory)?.label}
                    items={
                        {
                            items: listPriseCatagory,
                            onClick: (text) => {setNewUser({ 
                                ...newUser, 
                                ...{priceCatagory: text.key}
                            });},
                        }
                    }></DropdownComponent>
                </Col>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Thuộc tuyến'}</Text>
                    <DropdownComponent label={'Thuộc tuyến'} 
                    value={listline.find(x => x.key == newUser.line)?.label}
                    items={
                         {
                            items: listline,
                            onClick: (text) => {setNewUser({ 
                                ...newUser, 
                                ...{line: text.key}
                            });},
                        }
                    }></DropdownComponent>
                </Col>
            </Row>
                <Row>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Mã đồng hồ'}</Text>
                        <Input placeholder={'Nhập mã đồng hồ'} 
                        value={newUser.waterCode}
                        onChange={(text) =>  {
                            setNewUser({ 
                                ...newUser, 
                                waterCode: text.target.value
                            });
                            }}></Input>
                    </Col>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Ngày lập hợp đồng'}</Text>
                        <br/>
                        <DatePicker
                        style={{flex: 1, width: "100%"}}
                        placeholder={'Nhập ngày lập hợp đồng'} 
                        inputReadOnly={true}
                        defaultValue={dayjs('01-01-2023', 'DD-MM-YYYY')}
                        value={newUser.dateContract ? dayjs(newUser.dateContract) : null}
                        onChange={(date, dateString) => {
                            setNewUser({ 
                                ...newUser, 
                                dateContract: date?.toDate()
                            });
                        }} />
                        
                    </Col>
                </Row>
                <Row style={{marginRight: 26, marginBottom: 16}}>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Mã số thuế'}</Text>
                        <Input placeholder={'Nhập mã số thuế'} 
                        value={newUser.taxCode}
                        onChange={(text) =>  {
                            setNewUser({ 
                                ...newUser, 
                                taxCode: text.target.value
                            });
                            }}></Input>
                    </Col>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Tài liệu hợp đồng'}</Text>
                        <Row>
                            <Button style={{flex: 1, cursor: "default", borderColor: "#DBDADE", color: "#222222"}} danger>
                                <Row>
                                    <Text>{newUser.contract ? newUser.contract.attributes?.name : "Chọn tài liệu"}</Text>
                                    <view style={{flex: 1}}></view>
                                    {newUser.contract?.attributes?.url ? (<EyeOutlined disabled={!newUser.contract?.attributes?.url} style={{marginRight: 10, marginLeft: 10, cursor: !newUser.contract?.attributes?.url? "default" : "pointer"}} onClick={() =>{
                                        var link = SYSTEM_CONSTANTS.HOST + newUser.contract?.attributes?.url
                                        if (link != null) {
                                            window.open(link, '_blank', 'noopener,noreferrer');
                                        }
                                    }}/>) : null}
                                    <UploadOutlined style={{cursor: "pointer"}} onClick={() => {
                                        var fielInput  = document.getElementById("fileInput");
                                        if (fielInput) {
                                            fielInput.click();
                                        }
                                    }}/>
                                    <input id="fileInput" accept={".pdf,.doc,.docx"} type="file" name="file" onChange={changeHandler}  style={{display: "none"}}/>
                                </Row>
                            </Button>
                        </Row>
                        
                    </Col>
                </Row>
                <Row> 
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Loại khách hàng'}</Text>
                    <DropdownComponent label={'Loại khách hàng'}
                    value={[{label: "Có hợp đồng", key: 1}, {label: "Không có hợp đồng", key: 2}].find(x => x.key == newUser.clientType)?.label}
                    items={
                        {
                            items: [{label: "Có hợp đồng", key: 1}, {label: "Không có hợp đồng", key: 2}],
                            onClick: (text) => {setNewUser({ 
                                ...newUser, 
                                ...{clientType: Number(text.key)}
                            });},
                        }
                    }></DropdownComponent>
                </Col>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Khu vực thu phí môi trường'}</Text>
                    <Row style={{paddingTop: 5}}>
                    <Checkbox 
                    checked={newUser.cleaningFee}
                    defaultChecked={newUser.cleaningFee}
                    onChange={(value) => {
                        setNewUser({ 
                            ...newUser, 
                            ...{cleaningFee: value.target.checked}
                        })
                    }}>Thu phí 10%</Checkbox>
                    </Row>
                   
                </Col>
            </Row>
            </Col>
            <Col xl={12} md={24} sm={24}>
                <Row >
                    <Col xl={24} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{"Nhập địa chỉ tìm kiếm"}</Text>
                        <AutoComplete
                            placeholder={"Nhập tên địa chỉ"}
                            options={resultSearch}
                            style={{ width: "100%" }}
                            onSelect={(text, value) => {onSelect(text, value)}}
                            onSearch={onSearch}
                        />
                        

                    </Col>
                </Row>
                <Row>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{"Vĩ độ"}</Text>
                        <Input type="number" placeholder={"Nhập vĩ độ"} value={newUser?.point?.lat} 
                        onChange={(event) => {
                            setNewUser({
                                ...newUser,
                                point: {
                                    lat: Number(event.target.value),
                                    lng: newUser.point?.lng ?? 0
                                }
                            })
                        }}></Input>
                    </Col>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{"Kinh độ"}</Text>
                        <Input type="number" placeholder={"Nhập kinh độ"}
                        onChange={(event) => {
                            setNewUser({
                                ...newUser,
                                point: {
                                    lat: newUser.point?.lat ?? 0,
                                    lng: Number(event.target.value)
                                }
                            })
                        }} value={newUser?.point?.lng}></Input>
                    </Col>
                </Row>
                <Row>
                <Col xl={12} md={12} sm={24} style={{minHeight: '316px', minWidth: '100%'}}>
                    <MapContainer 
                        center={newUser.point?.lng && newUser.point?.lat ? newUser?.point : {lat: 19.806843, lng:105.784848}} 
                        zoom={15} 
                        style={{width: '100%', height: '100%'}}>
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <LocationMarker 
                        position={newUser.point?.lng && newUser.point?.lat ? newUser.point : {lat: 19.806843, lng:105.784848}}
                        
                        onClick={(e) => {
                            setNewUser({
                                ...newUser,
                                point: {
                                    lat: e.latlng.lat,
                                    lng: e.latlng.lng
                                }
                            })
                        }}/>
                    </MapContainer>
                </Col>
                </Row>
            </Col>
        </Row>
        </div>
    </div>
  );
};

function LocationMarker({onClick, isLocation, position}: {onClick: (e: LeafletMouseEvent) => void, isLocation?: boolean, position?: any}) {
    const [locationData, setPosition] = useState<LatLng | null>(null)
    useEffect(() => {
        setPosition(position ?? null);
        // if(isLocation)map.locate()
        // else if(position) {
        //     map.flyTo(position, map.getZoom())
        // }
        map.flyTo(position, map.getZoom())
    }, [position])
    const map = useMapEvents({
      click(e) {
        onClick(e);
      },
      locationfound: (e: LocationEvent) => {
        if(isLocation) {
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
        } 
        
        
      },
    })
  
    return locationData === null ? null : (
      <Marker position={locationData}>
        <Popup>{isLocation? "You are here" : "Vị trí khách hàng"}</Popup>
      </Marker>
    )
  }