const SYSTEM_CONSTANTS = {
    HOST:'https://operation-api.nuocsachlssv.vn',
    IDENTITY: {
        LOGIN: 'api/auth/local',
        FORGOT: 'users',
        PROFILE: (id: number) => `api/users/${id}?populate=role`,
        ROLE: 'api/users-permissions/roles',
    },
    OVERVIEW: {
        GET: 'api/overview-status'
    },
    LOCATION: {
        PROVINCE: 'api/cities',
        DISTRICT: 'api/districts',
        WARD: 'api/wards',
        AREA: 'api/villages',
        LINE: 'api/lines',
        PRISE_CATAGORY: 'api/price-categories'
    },
    ZONE: {
        GET: 'api/zones',
        POST: 'api/zones',
        PUT: (id: string) => 'api/zones/' + id,
        DELETE: (id: string) => 'api/zones/' + id
    },
    BILL: {
        GET: 'api/water-bills/overview-status',
        GET_REVENUE_MONTH: 'api/water-bills/revenue/month-report',
        GET_WATER_USAGE_MONTH: 'api/water-bills/water-usage/month-report',
        GET_REVENUE_YEAR: 'api/water-bills/revenue/year-report',
        GET_WATER_USAGE_YEAR: 'api/water-bills/water-usage/year-report'
    },
    LOCATION_APROVE: {
        GET: 'api/approve-location-requests',
        REJECT: (id: string) => `api/approve-location-requests/${id}/reject`,
        APPROVE: (id: string) => `api/approve-location-requests/${id}/approve`,
    },
    VAN_PIT: {
        GET: 'api/valve-pits',
        POST: 'api/valve-pits'
    },
    OPERATOR: {
        GET: 'api/operator-schedules',
        CREATE: 'api/operator-schedules/bulk-create',
        UPDATE: 'api/operator-schedules/bulk-update',
        REPORT: 'api/operator-schedules/month-report'
    },
    CLIENT: {
        GET: 'api/clients',
        DETAIL: (id: string) => `api/clients/${id}`,
        CREATE: 'api/clients',
        UPDATE: (id: string) => `api/clients/${id}`,
        BILL: 'api/water-bills',
        UPDATE_BILL: (id:string) => `api/water-bills/${id}`
    },
    SCHEDULE: {
        GET: 'api/schedule-change-requests',
        POST: 'api/schedule-change-requests',
        APPROVE: (id: string) => `api/schedule-change-requests/${id}/approve`,
        REJECT: (id: string) => `api/schedule-change-requests/${id}/reject`,
        
    },
    CONTRACT: {
        GET: 'api/technical-inspection-reports',
        POST: 'api/technical-inspection-reports',
        APPROVE: (id: string) => `api/technical-inspection-reports/${id}/approve`,
        REJECT: (id: string) => `api/technical-inspection-reports/${id}/reject`,
        
    },
    USER: {
        GET: 'api/users',
        ADD: 'api/users',
        UPDATE: (id: string) => 'api/users/' + id,
        DELETE: (id: string) => 'api/users/' + id,
        ROLE: 'api/users-permissions/roles',
    },
    WAREHOUSE: {
        GET: 'api/warehouses'
    },
    MATERIALS: {
        GET: 'api/materials',
        GET_MATERIAL_IN_HOUSE: 'api/material-warehouses',
        GET_INVERTER_REPORT: 'api/import-export-bills/inventoryReport'
    },
    ExportBill: {
        GET: 'api/import-export-bills',
        CREATE: 'api/import-export-bills/createBill'
    }
};

export default SYSTEM_CONSTANTS;
