import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Pagination, User } from "../common/define-types";
import { Shift } from "./Operator.api";
import { Client, Contract } from "./Client.api";
export interface Report {
    id?: number;
    title?: string,
    content?: string,
    status?: number,
    createdAt?: Date,
    updatedAt?: Date,
    image?: {
        data?: Contract
    },
    technician?: {
        data?: {
            id?: number;
            attributes?: User
        }
    },
    client?: {
        data?: {
            id?: number;
            attributes?: Client
        }
    }
}
export interface ReportResponse {
    data: {
        id?: number,
        attributes?: Report
    }[],
    meta: {
        pagination: Pagination
    }
}
export default class ReportAPI {
    static host = SYSTEM_CONSTANTS.HOST;
    static get(page: number, size: number, status?: number): Observable<ReportResponse | null> {
        var api = `${ReportAPI.host}/${SYSTEM_CONSTANTS.CONTRACT.GET}?populate=image,technician,client&pagination[page]=${page}&pagination[pageSize]=${size}`;
        if(status != null && status != -1) api = api + '&filters[status][$eq]=' + status;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ReportResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static approve(id: string): Observable<ReportResponse | null> {
        const api = `${ReportAPI.host}/${SYSTEM_CONSTANTS.CONTRACT.APPROVE(id)}`;
        return HttpClient.put(api, null, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ReportResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static reject(id: string): Observable<ReportResponse | null> {
        const api = `${ReportAPI.host}/${SYSTEM_CONSTANTS.CONTRACT.REJECT(id)}`;
        return HttpClient.put(api, null, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ReportResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}