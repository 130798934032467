import { Row, Col, Input, Button, Typography } from "antd";
import { type } from "os";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import { DropdownComponent, ItemTypeProp } from "../../../components/Dropdown/DropdownComponent";

import exportSvg from "../../../image/icon/export.svg";
import { createUser, updateUser } from "../../../store/controls/User.slice";
import { useSelectorRoot } from "../../../store/store";
export interface NewMeasureWaterPersonItem {
    id?: string;
    username?: string;
    password?: string;
    name?: string;
    email?: string;
    area?: string;
    phone?: string;
}
const {Text} = Typography;
export const NewMeasureWaterPerson = (props: {data?: NewMeasureWaterPersonItem | null, onUpdate?: () => void }) => {
    const [newUser, setNewUser] = useState<NewMeasureWaterPersonItem>(props.data ?? {});
    const dispatch = useDispatch();
    const {zoneData} =useSelectorRoot(state => state.zone);
    const {userData, pagination} = useSelectorRoot(state => state.user);
    const [selectZone, setSelectZone] = useState(props.data?.area);
    const [items, setItems] = useState(zoneData?.map(x => ({key: x.id, label: x.attributes.name })));
    useEffect(() => {
        setItems(zoneData?.map(x => ({key: x.id, label: x.attributes.name })))
    }, [zoneData])
    useEffect(() => {
        setNewUser(props.data ?? {})
        setSelectZone(props.data?.area)
    }, [props.data])
   
  return (
    <div style={{width: "100%", maxHeight: "75vh"}}>
        <Row style={{justifyContent: 'center', alignItems: 'center', marginBottom: 16, marginRight: 26}}>
                <Col sm={24} xl={12} md={12}>
                    <Text style={{fontSize: 20, fontWeight: 500, color: '#222222'}}>{"Thông tin KTV đo nước"}</Text>
                    
                </Col>
                <div style={{flex: 1}}></div>
                
                <Button type="primary" onClick={() => {
                    if (props.data == null){
                        dispatch(createUser({
                            page: pagination?.page ?? 1,
                            size: pagination?.pageSize ?? 10,
                            role: 6,
                            data: {
                                confirmed: true,
                                username: newUser.username,
                                password: newUser.password,
                                fullName: newUser.name,
                                email: newUser.email,
                                phone: newUser.phone,
                                role: {
                                    connect: [{id: 6}]
                                },
                                zone: {
                                    connect:[Number(newUser.area)]
                                }
                            }
                        }))
                    } else {
                        var temZone = props.data.area != newUser.area ? {
                            connect: [Number(newUser.area)] ,
                            disconnect: [Number(props.data.area)]
                        } : undefined;
                        dispatch(updateUser({
                            
                            page: pagination?.page ?? 1,
                            size: pagination?.pageSize ?? 10,
                            role: 6,
                            data: {
                                id: props.data.id,
                                confirmed: true,
                                username: newUser.username,
                                password: newUser.password,
                                fullName: newUser.name,
                                email: newUser.email,
                                phone: newUser.phone,
                                zone: temZone
                            }
                        }))
                    }
                    props.onUpdate && props.onUpdate();
                    }}>
                    <Row>
                      <ReactSVG src={exportSvg} style={{marginRight: 5, width: 18, height: 18}}></ReactSVG>
                      {props.data ? 'Lưu thay đổi' : "Thêm mới"}
                    </Row>
                </Button>
        </Row>
        <div style={{width: "100%", maxHeight: "calc(75vh - 50px)", overflowY: "auto"}}>
                <Row>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Tên đăng nhập'}</Text>
                        <Input placeholder={'Nhập tên đăng nhập'} 
                        value={newUser.username}
                        onChange={(text) => setNewUser({ 
                                ...newUser, 
                                username: text.target.value
                            })}></Input>
                    </Col>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Mật khẩu'}</Text>
                        <Input.Password placeholder={'Nhập mật khẩu'} 
                        onChange={(text) => setNewUser({ 
                                ...newUser, 
                                password: text.target.value
                            })} ></Input.Password>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Họ và tên'}</Text>
                        <Input placeholder={'Nhập họ và tên'} 
                        value={newUser.name}
                        onChange={
                            (text) => {setNewUser({ 
                                ...newUser, 
                                name: text.target.value
                            })}
                        }></Input>
                    </Col>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Số điện thoại'}</Text>
                        <Input placeholder={'Nhập số điện thoại'}
                        value={newUser.phone}
                        onChange={
                            (text) => setNewUser({ 
                                ...newUser, 
                                phone: text.target.value
                            })
                        } type={"number"}></Input>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{"Địa chỉ email"}</Text>
                        <Input placeholder={"Nhập email"} 
                        value={newUser.email}
                        onChange={(e) => setNewUser({ 
                                ...newUser, 
                                email: e.target.value
                            })}
                            type="email"
                            ></Input>
                    </Col>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{"Khu vực đo nước"}</Text>
                        <DropdownComponent label={"Khu vực đo nước"} 
                        value={zoneData?.find(x => x.id == Number(selectZone))?.attributes.name}
                        items={{
                                  onClick: function (value: ItemTypeProp): void {
                                    setSelectZone(value.key)
                                      setNewUser({
                                        ...newUser,
                                        area: value.key
                                      })
                                  },
                                  items: items
                              }} ></DropdownComponent>
                    </Col>
                </Row>
        </div>
    </div> );
}