import { Button, Card, Col, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOverview } from "../../store/controls/Overview.slice";
import { useSelectorRoot } from "../../store/store";
import Utils from '../../common/Utils';
import { ImportAndPublishInv } from "../../store/controls/Invoices";
import { Invoices } from "../../api/vnpt";
interface CarItem {
    label: string;
    value: string | number;
    click?: () => void;
}
const {Text} = Typography;
export const ControlTable = () => {
    const currentMonth = (new Date().getMonth() + 1);
    const currentDay = new Date().getDate();
    const displayMonth = currentDay < 10 ? currentMonth - 1 : currentMonth;
    const {overviewData} = useSelectorRoot(state => state.overview);
    const {isSuccess} = useSelectorRoot(state => state.login);
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        if (isSuccess) dispatch(getOverview());
    },[isSuccess]);
    const data: CarItem[] = [
        {
            label: 'Khách hàng ',
            value: overviewData?.totalClient ?? 0,
            click: () => {
                history.push('/quanlyuser');
            }
        },
        {
            label: 'Kỹ thuật viên đo nước',
            value: overviewData?.waterMeterTechnicianQuantity ?? 0,
            click: () => {
                history.push('/ktvdonuoc');
            }
        },
        {
            label: 'Kỹ thuật viên vận hành nhà máy',
            value: overviewData?.operatorTechinicianQuantity ?? 0,
            click: () => {
                history.push('/vanhanh');
            }
        },
        {
            label: 'Yêu cầu duyệt vị trí khách hàng',
            value: overviewData?.locationConfirmRequestQuantity ?? 0,
            click: () => {
                history.push('/quanlyyeucau');
            }
        },
        {
            label: 'Yêu cầu đổi ca KTV vận hành nhà máy',
            value: overviewData?.scheduleChangeRequestQuantity ?? 0,
            click: () => {
                history.push('/doicatruc');
            }
        },
        {
            label: 'Khách chưa thanh toán tháng '+displayMonth,
            value: overviewData?.unpaidClientQuantity ?? 0,
            click: () => {
                history.push('/chart');
            }
        },
        {
            label: 'Doanh thu tháng '+displayMonth+' (VNĐ)',
            value: Utils.formatVNDCash(overviewData?.monthRevenue ? String(overviewData.totalDept) : '') ?? 0,
            click: () => {
                history.push('/doanhthu');
            }
        },
        {
            label: 'Công nợ (VNĐ)',
            value: Utils.formatVNDCash(overviewData?.totalDept ? String(overviewData.totalDept) : '')?? 0,
            click: () => {
                history.push('/chart');
            }
        },
        {
            label: 'Lượng nước tiêu thụ tháng '+displayMonth,
            value: overviewData?.waterUsage ?? 0,
            click: () => {
                history.push('/luongnuoc');
            }
        }
    ]
    const renderItem = (item: CarItem, flex: number) => (
    <Col xs={24} sm={24} md={flex} lg={flex} xl={flex}>
    {/* <Button onClick={() => {
        const invoicesData: Invoices = {
            Inv: 
                [{
                    key: "OKPK235268ILS",
                    Invoice: {
                        CusCode: "W1.001A",
                        CusName: "Hoàng Thị Nga",
                        CusAddress: "Thôn Lam Sơn - Thị trấn Lam Sơn",
                        CusPhone: "0338396753",
                        CusTaxCode: "",
                        PaymentMethod: "Tiền mặt/Chuyển khoản",
                        KindOfService: "Cước Tháng 07/2023",
                        GrossValue: 0,
                        FromDate: "15/06/2023",
                        ToDate: "10/07/2023",
                        SoHo: 1,
                        ChiSoCu: 71,
                        ChiSoMoi: 74,
                        SoCT: 141000893,
                        DNSD: 3,
                        MDSD: "SHLS",
                        Products: {Product: [{
                                    Code: "",
                                    Remark: "",
                                    ProdName: "Tiền nước",
                                    ProdUnit: "m3",
                                    ProdQuantity: 3,
                                    ProdPrice: 6600,
                                    Total: 19800
                                }, {
                                    Code: "",
                                    Remark: "",
                                    ProdName: "Tiền nước",
                                    ProdUnit: "m3",
                                    ProdQuantity: 3,
                                    ProdPrice: 6600,
                                    Total: 19800
                                }]},
                        GrossValue_NonTax: 0,
                        GrossValue0: 0,
                        GrossValue5: 19800,
                        GrossValue10: 0,
                        GrossValue8: 0,
                        VatAmount0: 0,
                        VatAmount5: 990,
                        VatAmount10: 0,
                        VatAmount8: 0,
                        Total: 19800,
                        DiscountAmount: 0,
                        VATRate: 5,
                        VATAmount: 990,
                        Amount: 22770,
                        AmountInWords: "Hai mươi hai nghìn bảy trăm bảy mươi đồng",
                        PaymentStatus: 1,
                        Extra: "",
                        ResourceCode: "",
                        ArisingDate: "31/07/2023",
                        Extra1: 10,
                        PHI_BVMT: 1980
                    }
                }]
            
        };
        dispatch(ImportAndPublishInv(invoicesData))
    }}>CLick</Button> */}
        <Card style={{margin: "13px 13px 13px 13px"}}>
            <Col style={{minHeight: 100}} span={24}>
            <Row>
            <Text style={{fontSize: 15, fontWeight: 400, color: "#222222A0"}}>
                {item.label}
            </Text>
            </Row>
            
            <Row>
            <Text style={{fontSize: 22, fontWeight:700, color: "#222222"}}>
                {item.value}
            </Text>
            </Row>
            <Row style={{height: "100%"}}></Row>
            <Row onClick={() => item.click && item.click()} style={{width: '100%', flex: 1, height: '100%', alignItems: 'flex-end', alignSelf: "flex-end"}}>
                <Text style={{flex: 1, color: "#005BA5", fontSize: 15}}>
                    Xem danh sách
                </Text>
                <Text style={{color: "#005BA5"}}>{">"}</Text>
            </Row>
            </Col>
            
        </Card>
    </Col>);
    return (
        <Content>
            <Row>
            {
            data.map((x, i) => (i < (data.length - 2) || data.length % 3 === 0? 
                renderItem(x, 8) 
                : data.length % 3 === 2 ? renderItem(x, 12) :  renderItem(x, 24 )
             ))
             }
            </Row>
            
    
    </Content>
    );
}