import { Col, Pagination, Row, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownComponent } from "../../../components/Dropdown/DropdownComponent";
import { approveSchedule, getSchedule, rejectSchedule } from "../../../store/controls/Schedule.slice";
import { useSelectorRoot } from "../../../store/store";
import { ShiftItem, ShiftRequestItem } from "./ShiftRequestItem";

export const ChangeShiftRequest = () => {
    const [length, setLength] = useState(10);
    const [size, setSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const {scheduleData,loading, pagination} = useSelectorRoot(state => state.schedule);
    const {isSuccess} = useSelectorRoot(state => state.login);
    const dispatch = useDispatch();
    var [dataSource, setDataSource]= useState<ShiftItem[] | undefined>([]);
    const [status, setStatus] = useState<number>();
    useEffect(() => {
        setDataSource(scheduleData?.data.map(x => ({
            id: x?.id?.toString(),
            name: x.attributes?.userRequest?.data?.attributes?.fullName ?? "",
            changedPerson: x.attributes?.userChange?.data?.attributes?.fullName,
            currentShift: `${x.attributes?.currentSchedule?.data?.attributes?.shift?.data?.attributes?.name ?? ''} - ${x.attributes?.currentSchedule?.data?.attributes?.date}`,
            changeShift: `${x.attributes?.changeSchedule?.data?.attributes?.shift?.data?.attributes?.name ?? ''} - ${x.attributes?.changeSchedule?.data?.attributes?.date}`,
            status: x.attributes?.status,
            rejectReason: x.attributes?.rejectReason
        })));
    }, [scheduleData])
    useEffect(() => {
        dispatch(getSchedule({page: currentPage, size: size, status: status}));
    }, [isSuccess])
    const onUpdate = (value: boolean, item: ShiftItem, rejectReason?: string) => {
        if(value){
            dispatch( approveSchedule({id: item.id ?? '', page: currentPage, size: size}))
        }else {
            dispatch(rejectSchedule({id: item.id ?? '', page: currentPage, size: size, rejectReason}))
        }
        
    }
    useEffect(() => {
        setLength(pagination?.total ?? 10);
        setSize(pagination?.pageSize ?? 10);
        setCurrentPage(pagination?.page ?? 1);
    }, [pagination]);
    const statusLts = [{
        key: 0,
        label: "Đã từ chối"
        },
        {
            key: 1,
        label: "Đã phê duyệt"
        },
        {
            key: 2,
        label: "Chờ phê duyệt"
        }
    ];
    return (
    <Content>
        <Spin spinning={loading}>
        <Row style={{margin: 10}}>
            <DropdownComponent items={{items: statusLts.concat([{label: "Tất cả", key: -1}]), onClick: (value) => {
                    setStatus(Number(value.key));
                    dispatch(getSchedule({page: currentPage, size: size, status: Number(value.key)}));
                }}} label={'Chọn trạng thái'} flex={0.3} ></DropdownComponent>
        </Row>
        <Row style={{width: "100%", height: "calc(100vh - 200px)", overflowY: "scroll"}}>
        { dataSource?.map(x => <Col xl={12} md={24} sm={24} style={{padding: 12}}><ShiftRequestItem data={x} onSelect={(value, rejectReason) => onUpdate(value, x, rejectReason)}></ShiftRequestItem></Col>)}
        </Row>
        
    
        <Row style={{justifyContent: 'flex-end', flex: 1, marginTop: 10, width: "100%", }}>
            <Pagination defaultCurrent={1} total={length} pageSize={size} onChange={(page, pageSize) => {
                setCurrentPage(page); setSize(pageSize);
                dispatch(getSchedule({page: currentPage, size: size, status: status}));
                }} style={{justifyContent: 'flex-end'}}></Pagination>
        </Row>
        </Spin>
    </Content>);
    
}