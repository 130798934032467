import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Pagination as Pagination } from "../common/define-types";
interface LocationItem {
    id: number,
    attributes: {
        name: string,
        createdAt: Date,
        updatedAt: Date
    }
}
export interface Area {
    id: number,
    attributes: {
        city: {data: LocationItem},
        district: {data: LocationItem},
        ward: {data: LocationItem},
        name: string,
        createdAt: Date,
        updatedAt: Date
    }
}
export interface LocationResponse {
    data: Area[],
    meta: {
        pagination: Pagination 
    }
}
export default class LocationAPI {
    static line() {
        const api = `${LocationAPI.host}/${SYSTEM_CONSTANTS.LOCATION.LINE}?pagination[pageSize]=1000`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as LocationResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static priseCatagory() {
        const api = `${LocationAPI.host}/${SYSTEM_CONSTANTS.LOCATION.PRISE_CATAGORY}?pagination[pageSize]=100`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as LocationResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static host = SYSTEM_CONSTANTS.HOST;
    static district(): Observable<LocationResponse | null> {
        const api = `${LocationAPI.host}/${SYSTEM_CONSTANTS.LOCATION.DISTRICT}?pagination[pageSize]=1000&populate=city`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as LocationResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static province(): Observable<LocationResponse | null> {
        const api = `${LocationAPI.host}/${SYSTEM_CONSTANTS.LOCATION.PROVINCE}?pagination[pageSize]=1000`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as LocationResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static ward(): Observable<LocationResponse | null> {
        const api = `${LocationAPI.host}/${SYSTEM_CONSTANTS.LOCATION.WARD}?pagination[pageSize]=1000&populate=district`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as LocationResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static area(): Observable<LocationResponse | null> {
        const api = `${LocationAPI.host}/${SYSTEM_CONSTANTS.LOCATION.AREA}?pagination[pageSize]=1000&populate=ward`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as LocationResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}