import {ajax, AjaxError, AjaxResponse} from 'rxjs/ajax';
import {catchError, map, retry} from 'rxjs/operators';
import {throwError} from 'rxjs';
import SYSTEM_CONSTANTS from '../common/constants';

export interface VNPTBody {
    Account: string;
    ACpass: string;
    xmlInvData: string;
    username: string;
    password: string;
    pattern: string;
    serial: string;
    convert: number;
}

interface Product {
    Code: string;
    Remark: string;
    ProdName: string;
    ProdUnit: string;
    ProdQuantity: number;
    ProdPrice: number;
    Total: number;
}

interface Invoice {
    CusCode: string;
    CusName: string;
    CusAddress: string;
    CusPhone: string;
    CusTaxCode: string;
    PaymentMethod: string;
    KindOfService: string;
    GrossValue: number;
    FromDate: string;
    ToDate: string;
    SoHo: number;
    ChiSoCu: number;
    ChiSoMoi: number;
    SoCT: number;
    DNSD: number;
    MDSD: string;
    Products: {Product: Product[]};
    GrossValue_NonTax: number;
    GrossValue0: number;
    GrossValue5: number;
    GrossValue10: number;
    GrossValue8: number;
    VatAmount0: number;
    VatAmount5: number;
    VatAmount10: number;
    VatAmount8: number;
    Total: number;
    DiscountAmount: number;
    VATRate: number;
    VATAmount: number;
    Amount: number;
    AmountInWords: string;
    PaymentStatus: number;
    Extra: string;
    ResourceCode: string;
    ArisingDate: string;
    Extra1: number;
    PHI_BVMT: number;
}

interface Inv {
    key: string;
    Invoice: Invoice;
}

export interface Invoices {
    Inv: Inv[];
}

// Hàm chuyển đổi một object JavaScript đại diện cho một lớp thành một chuỗi XML
export var convertClassToXml = (className: string, classObject: any, isShowxmlns = false) => {
    let xmlString = `<${className} ${isShowxmlns ? "xmlns=\"http://tempuri.org/\"": ""}>`;

    // Duyệt qua các thuộc tính của lớp và thêm chúng vào chuỗi XML
    for (let key in classObject) {
        if (classObject.hasOwnProperty(key)) {
            const value = classObject[key];
            if (Array.isArray(value)) {
                // Xử lý trường hợp thuộc tính là một mảng
                value.forEach(item => {
                    xmlString += convertClassToXml(key, item);
                });
            } else if (typeof value === 'object') {
                // Xử lý trường hợp thuộc tính là một object
                xmlString += convertClassToXml(key, value);
            } else {
                // Xử lý trường hợp thuộc tính là một giá trị đơn
                xmlString += `<${key}>${value}</${key}>`;
            }
        }
    }

    xmlString += `</${className}>`;

    return xmlString;
}

const callApi = (url:string, data: any, action: string) => {
    const vnptBody: VNPTBody = {
        Account: 'htxhoalocadmin',
        ACpass: 'Einv@oi@vn#pt20',
        username: 'htxhoalocservice',
        password: 'Einv@oi@vn#pt20',
        pattern: 'string',
        serial: 'string',
        convert: 1,
        xmlInvData: convertClassToXml('Invoices', data)
    };
    const soapXml = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
          ${convertClassToXml(action, vnptBody, true)}
      </soap:Body>
    </soap:Envelope>`;

    return ajax({
        url,
        method:'POST', 
        body: soapXml, 
        headers: {
        'Content-Type': 'text/xml; charset=utf-8',
            'SOAPAction': `http://tempuri.org/${action}`
        }
    }).pipe(
        map((res: AjaxResponse<any>) =>
            res.response
        ),
        catchError(err => {
            console.log('------------------');
            console.log(url);
            data && console.log(JSON.stringify(data));
            console.log('------------------');
            return throwError(() => err);
        }),
    );
};

export default class InvoiceAPI {
    static host = 'https://htxhoaloc-tt78admindemo.vnpt-invoice.com.vn/PublishService.asmx';
    static ImportAndPublishInv(data: Invoices) {
        const api = `${InvoiceAPI.host}`;
        
        return callApi(api, data, 'ImportAndPublishInv').pipe(
            map(
                res => res  || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}