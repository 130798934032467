import { Card, Row, Col, Button, Typography, Modal, Input } from "antd";
import { ReactSVG } from "react-svg";
import { CheckOutlined } from '@ant-design/icons'
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
export interface ShiftItem {
    id?: string,
    name?: string,
    changedPerson?: string,
    currentShift?: string,
    changeShift?: string,
    status?: boolean | null,
    rejectReason?: string
}
const {Text} = Typography;
export const ShiftRequestItem = (props: {data: ShiftItem, onSelect: (value: boolean, rejectReason?: string) => void}) => {
    const [rejectReason, setRejectReason] = useState<string>("")
    const [agree, setAgree] = useState<boolean>(false)
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const handleOk = () => {
        props.onSelect(agree, rejectReason);
        setIsModalConfirmOpen(false);
    }
    return ( 
    <Card style={{width: '100%'}}>
        <Row style={{justifyContent: 'center', alignItems: 'center'}}>
            <Col sm={12} xl={12} md={12}>
                <Text style={{fontSize: 18, fontWeight: 500, color: '#222222'}}>{props.data.name}</Text>
                
            </Col>
            <Col sm={12} xl={12} md={12}>
            <Row style={{justifyContent: "flex-end", flex: 1}}>
            {
                props.data.status == true ?
                    <Button type="primary" style={{backgroundColor: '#28C76F1F', color: '#28C76F', cursor: "default"}}>Đã đồng ý</Button>
                : props.data.status == false?
                    <Button type="primary" style={{backgroundColor: '#FF43431F', color: '#FF4343', cursor: "default"}}>Không đồng ý</Button>
                :
                    <>
                    <Button type="primary" icon={<CheckOutlined></CheckOutlined>} style={{backgroundColor: '#28C76F'}} onClick={() => {
                        setAgree(true);
                        setIsModalConfirmOpen(true);
                    }}>
                    {'Đồng ý'}
                    </Button>
                    <Button danger type="primary" icon={<CheckOutlined></CheckOutlined>} style={{marginLeft: 10}} onClick={() => {
                        setAgree(false);
                        setIsModalConfirmOpen(true);
                    }}>
                    {'Không đồng ý'}
                    </Button>
                    </>
            }
            </Row>
            
            </Col>
           
        </Row>
        <Row style={{marginTop: 16}}>
            <Col>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#222222'}}>{'Người được đổi ca'}</Text>
                <br/>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#2222225A'}}>{props.data.changedPerson}</Text>
            </Col>
            
        </Row>
        <Row style={{marginTop: 16}}>
            <Col xl={12} md={12} sm={24}>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#222222'}}>{'Ca hiện tại'}</Text>
                <br/>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#2222225A'}}>{props.data.currentShift}</Text>
            </Col>
            <Col xl={12} md={12} sm={24}>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#222222'}}>{'Ca đổi'}</Text>
                <br/>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#2222225A'}}>{props.data.changeShift}</Text>
            </Col>
            <Col xl={12} md={12} sm={24}>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#222222'}}>{'Lý do hủy yêu cầu'}</Text>
                <br/>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#2222225A'}}>{props.data.rejectReason}</Text>
            </Col>
        </Row>
        <Modal title="Yêu cầu đổi ca" open={isModalConfirmOpen} onOk={handleOk} onCancel={() => {setIsModalConfirmOpen(false)}}>
            {
                agree ? <p>Bạn có chắc muốn {agree? "đồng ý" : "không đồng ý"} yêu cầu đổi ca trực này</p> : 
                (
                    <div className="shift-request-reject-wrapper">
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Lý do từ chối'}</Text>
                        <TextArea style={{height: "80px"}} placeholder={'Nhập lý do từ chối'} 
                        onChange={
                            (text) => setRejectReason(text.target.value)
                        }></TextArea>
                    </div>
                    
                )
            }
        
      </Modal>
    </Card>);
}

