import { Button, Card, Input, MenuProps, Pagination, Row, Table, Typography, Image, Tag, Modal, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import {PlusOutlined, FormOutlined, DeleteOutlined} from '@ant-design/icons'
import { ReactSVG } from "react-svg";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import Utils from "../../../common/Utils";
import exportSvg from "../../../image/icon/export.svg";
import { DropdownComponent } from "../../../components/Dropdown/DropdownComponent";
import { FilterItem } from "../../../common/define-types";
import { AddWaterVan, WaterVanData } from "./AddWaterVan";
import { useSelectorRoot } from "../../../store/store";
import { useDispatch } from "react-redux";
import { createVanPit, deleteVanPit, getVanPit, updateVanPit } from "../../../store/controls/VanPit.slice";
import VanPitAPI, { ValvePit } from "../../../api/VanPit.api";
import SYSTEM_CONSTANTS from "../../../common/constants";
import { CSVLink } from "react-csv";

const {Text} = Typography;
const { Search } = Input;
var listExport: ValvePit[] = []
export const WaterVan = () => {
  const headers = [
    { label: "Tên", key: "name" },
    { label: "Trạng thái", key: "status" },
    { label: "Tỉnh/Thành phố", key: "city" },
      { label: "Huyện/Quận", key: "district" },
      { label: "xã/phường", key: "ward" },
      { label: "thôn/xóm", key: "village" },
      { label: "Địa chỉ", key: "adress" },
    { label: "Ngày tạo", key: 'createdAt'}
  ];
    const dispatch = useDispatch();
    const {district, area, province, ward} = useSelectorRoot(state => state.location);

    const [listArea, setListArea] = useState([{key: 'Ấp 1', label: 'Ấp 1'}, {key: 'Ấp 2', label: 'Ấp 2'}, {key: 'Ấp 3', label: 'Ấp 3'}]);
    const [listProvince, setListProvince] = useState([{key: 'Thanh Hoá', label: 'Thành Hoá'}, {key: 'Hà Nội', label: 'Hà Nội'}, {key: 'Huế', label: 'Huế'}]);
    const [listDistrict, setListDistrict] = useState([{key: 'Xuân Hồng', label: 'Xuân Hồng'}, {key: 'Đức trọc', label: 'Dức trọc'}, {key: 'Hữu Khang', label: 'Hữu Khang'}]);
    const [listWard, setListWard] = useState([{key: 'Phú Lộc', label: 'Phú Lộc'}, {key: 'Liên Hoa', label: 'Liên Hoa'}, {key: 'Quang Trung', label: 'Quang Trung'}]);

    const [selectDistrict, setSelectDistrict] = useState<{label?: string, key?: string}>();
    const [selectedWard, setSelectedWard] = useState<{label?: string, key?: string}>();
    const [selectedProvince, setSelectedProvince] = useState<{label?: string, key?: string}>();
    const [selectedArea, setSelectedArea] = useState<{label?: string, key?: string}>();
    useEffect(() => {
      setListDistrict(district?.map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? []);
    }, [district]);
    useEffect(() => {
      setListProvince(province?.map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? []);
    }, [province]);
    useEffect(() => {
      setListArea(area?.map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? []);
    }, [area]);
    useEffect(() => {
      setListWard(ward?.map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? []);
    }, [ward]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const [deleteUserData, setDeleteUser] = useState<ValvePit>()
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const handleOk = () => {
    if (deleteUserData?.id) dispatch(deleteVanPit({
      id: deleteUserData.id ?? '',
      query: {
        page: pagination?.page ?? 1, 
        size: pagination?.pageSize ?? 10,
        ward: selectedWard?.key,
        district: selectDistrict?.key,
        village: selectedArea?.key,
        status: selectedStatus.key,
        province: selectedProvince?.key,
      }
    }));
    setIsModalConfirmOpen(false);
  }
    const [selectedStatus, setSelectedStatus] = useState({key: '-1', label: "Tất cả"});
    const {isSuccess} = useSelectorRoot(state => state.login);
   
    const {vanPitData, pagination, loading} = useSelectorRoot(state => state.valPit);
    const [data, setData] = useState(vanPitData?.data.map(x => x.attributes));
    const [length, setLength] = useState(pagination?.total ?? 10);
    const [size, setSize] = useState(pagination?.pageSize ?? 10);
    const [currentPage, setCurrentPage] = useState(pagination?.page?? 1);
    const [selectVal, setSelectVal] = useState<WaterVanData | null>();
    const [searchText, setSearchText] = useState<string>();
    const [lstExport, setLstExport] = useState<ValvePit[]>();
    
  const [load, setLoad] = useState(loading);
  useEffect(() => {
    setLoad(loading);
  }, [loading])
    const onSearch = (value: string) => {
      setSearchText(value);
      if(!loading) 
      dispatch(getVanPit({
        page: currentPage, 
        size: size,
        status: selectedStatus.key,
        ward: selectedWard?.key,
        district: selectDistrict?.key,
        village: selectedArea?.key,
        province: selectedProvince?.key,
        search: value
      }));
    };
    useEffect(() => {
      dispatch(getVanPit({
        page: currentPage, 
        size: size,
        status: selectedStatus.key,
        ward: selectedWard?.key,
        district: selectDistrict?.key,
        village: selectedArea?.key,
        province: selectedProvince?.key,
        search: searchText
      }));
    }, [isSuccess]);
    useEffect(() => {
      setData(vanPitData?.data.map(x => ({...x.attributes, id: x.id.toString()})))
    }, [vanPitData])
    useEffect(() => {
      setLength(pagination?.total ?? 10);
      setSize(pagination?.pageSize ?? 10)
    }, [pagination])
    useEffect(() => {
      setListWard(ward?.filter(x => x.attributes.district.data.id.toString() == selectDistrict?.key || selectDistrict?.key == '-1').map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? [])
    }, [selectDistrict])
    useEffect(() => {
      setListArea(area?.filter(x => x.attributes.ward.data.id.toString() == selectedWard?.key || selectedWard?.key == '-1').map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? [])
    }, [selectedWard])
    useEffect(() => {
      setListDistrict(district?.filter(x => x.attributes.city.data.id.toString() == selectedProvince?.key || selectedProvince?.key == '-1').map(x => ({label: x.attributes.name, key: x.id.toString()})).concat([{label: "Tất cả", key: "-1"}]) ?? [])
    }, [selectedProvince])
    const columns: ColumnsType<ValvePit> = [
      {
        title: 'Hố van',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <><Text style={{color: '#2222225A'}}>{text}</Text></>,
      },
      {
        title: 'Thành phố',
        dataIndex: 'city',
        key: 'city',
        render: (text) => <><Text style={{color: '#2222225A'}}>{text?.data?.attributes.name}</Text></>
      },
      {
        title: 'Huyện',
        dataIndex: 'district',
        key: 'district',
        render: (text) => <><Text style={{color: '#2222225A'}}>{text?.data?.attributes.name}</Text></>
      },
      {
        title: 'Phường/xã/thị trấn',
        dataIndex: 'ward',
        key: 'ward',
        render: (text) => <><Text style={{color: '#2222225A'}}>{text?.data?.attributes.name}</Text></>
      },
      {
        title: 'Hình ảnh',
        dataIndex: 'image',
        key: 'image',
        render: (text) => text?.data?.attributes?.url ? <Image src={SYSTEM_CONSTANTS.HOST + text?.data?.attributes?.url} height={50} width={50}></Image> : <></>
      },
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        render: (status, item) => (
          <>
            
            <Tag color={items?.find(x => x?.key == status)?.color} key={status + item.name}>
                {items?.find(x => x?.key == status)?.label ?? status}
              </Tag>
          </>
        ),
      },
      {
        title: 'Chọn',
        key: '',
        dataIndex: '',
        render: (_, item) => (
          <>
            <Button icon={<FormOutlined />} onClick={() => {
              setSelectVal({
                id: item.id,
                name: item.name,
                city: item.city?.data?.id.toString(),
                district: item.district?.data?.id.toString(),
                ward: item.ward?.data?.id.toString(),
                area: item.village?.data?.id.toString(),
                address: item.address,
                point: {lng: item.longitude ?? 0, lat: item.latitude ?? 0},
                status: item.status?.toString()
              });
              showModal();
            }} style={{marginRight: 20}}></Button>
            <Button icon={<DeleteOutlined /> } onClick={() => {
              setIsModalConfirmOpen(true);
              setDeleteUser(item);
            }} ></Button>
          </>
        ),
      },
    ];
    return (
    <Content style={{padding: "0px 0px 0px 15px"}} >
      <Spin spinning={load}>
       <Card title={
            <>
            <Row style={{margin: '20px 8px 16px 0px'}}>
            <Text style={{fontSize: 22, fontWeight: 600}}>{'Bộ lọc tìm kiếm'}</Text>
            </Row>
            <Row style={{margin: '20px 8px 16px 0px'}}>
            <DropdownComponent items={{items: listProvince, onClick: (value) => {
                  setSelectedProvince({key: value.key, label: value.label as string});
                  dispatch(getVanPit({
                    page: currentPage, 
                    size: size,
                    status: selectedStatus.key,
                    ward: selectedWard?.key,
                    district: selectDistrict?.key,
                    village: selectedArea?.key,
                    province: value.key,
                    search: searchText
                  }));
                  }}} label={'Chọn tỉnh/thành phố'} value={selectedProvince?.label} flex={1}></DropdownComponent>
                <DropdownComponent items={{items: listDistrict, onClick: (value) => {
                  setSelectDistrict({key: value.key, label: value.label as string});
                  dispatch(getVanPit({
                    page: currentPage, 
                    size: size,
                    status: selectedStatus.key,
                    ward: selectedWard?.key,
                    district: value.key,
                    village: selectedArea?.key,
                    province: selectedProvince?.key,
                    search: searchText
                  }));
                  }}} label={'Chọn quận/huyện'} value={selectDistrict?.label} flex={1}></DropdownComponent>
                <DropdownComponent items={{items: listWard, onClick: (value) => {
                  setSelectedWard({key: value.key, label: value.label as string});
                  dispatch(getVanPit({
                    page: currentPage, 
                    size: size,
                    status: selectedStatus.key,
                    ward: value.key,
                    district: selectDistrict?.key,
                    village: selectedArea?.key,
                    province: selectedProvince?.key,
                    search: searchText
                  }));
                  }}} label={'Chọn xã/phường/thị trấn'} value={selectedWard?.label} flex={1}></DropdownComponent>
                <DropdownComponent items={{items: listArea, onClick: (value) => {
                  setSelectedArea({key: value.key, label: value.label as string});
                  dispatch(getVanPit({
                    page: currentPage, 
                    size: size,
                    status: selectedStatus.key,
                    ward: selectedWard?.key,
                    district: selectDistrict?.key,
                    village: value.key,
                    province: selectedProvince?.key,
                    search: searchText
                  }));
                  }}} label={'Chọn thôn/xóm'} value={selectedArea?.label} flex={1}></DropdownComponent>
            </Row>
            </>
        }>
            <Row align={'middle'}>
                <Search
                    placeholder="Nhập từ khóa tìm kiếm"
                    allowClear
                    size="large"
                    onSearch={onSearch}
                    
                    style={{flex: 1, paddingRight: '16px'}}
                    />
                    <DropdownComponent items={{items: items, onClick: (value) => {
                      setSelectedStatus({key: value.key, label: value.label as string})
                      dispatch(getVanPit({
                        page: currentPage, 
                        size: size,
                        ward: selectedWard?.key,
                        district: selectDistrict?.key,
                        village: selectedArea?.key,
                        status: value.key,
                        province: selectedProvince?.key,
                        search: searchText
                      }));
                      }}} label={'Chọn trạng thái'} flex={0.3}></DropdownComponent>
                <Button
                    type="primary"
                    style={{marginRight: '10px'}}
                    onClick={() => {
                      var conutPage = pagination?.pageCount ?? 1;
                      if (listExport.length < (pagination?.total ?? 0)){
                        setLoad(true)
                        VanPitAPI.get( 
                         {
                          page: 1, 
                          size: size,
                          ward: selectedWard?.key,
                          district: selectDistrict?.key,
                          village: selectedArea?.key,
                          status: selectedStatus.key,
                          province: selectedProvince?.key,
                          search: searchText
                          }
                        ).subscribe(async (json) => {
                          conutPage = json?.meta.pagination.pageCount ?? 1;
                          listExport = json?.data?.map((x) => {
                            return {...x.attributes}
                          }) ?? [];
                          
                          for(var i = 2; i < conutPage + 1; i++) {
                            var data = await VanPitAPI.get({
                              page: i, 
                              size: size,
                              ward: selectedWard?.key,
                              district: selectDistrict?.key,
                              village: selectedArea?.key,
                              status: selectedStatus.key,
                              province: selectedProvince?.key,
                              search: searchText
                              }).toPromise();
                            listExport = listExport.concat(data?.data.map(x => x.attributes) ?? [])
                            console.log(listExport.length);
                          }
                          setLstExport(listExport);
                          setTimeout(() => {
                            setLoad(false)
                            document.getElementById('exportCXV')?.click()
                          }, 1000)
                          
                        });
                      }
                    }}
                    >
                      <Row>
                      <ReactSVG src={exportSvg} style={{marginRight: 5, width: 18, height: 18}}></ReactSVG>
                      {'Xuất file'}
                      </Row>
                      
                </Button>
                <CSVLink
                style={{display: 'none'}}
                  headers={headers}
                  filename={`Danh sách hố van${selectedProvince ? ' ' + selectedProvince : ''}${selectDistrict ? ' ' + selectDistrict: ''}${selectedWard? ' ' + selectedWard : ''}${selectedArea ? ' ' + selectedArea: ''}.csv`}
                  data={lstExport?.map(x => ({
                    ...x,
                    status: items.find(y => y.key == x.id?.toString())?.label,
                    city: x.city?.data?.attributes.name,
                    ward: x.ward?.data?.attributes.name,
                    village: x.village?.data?.attributes.name,
                    district: x.district?.data?.attributes.name,
                  })) ?? []}
                  id="exportCXV"
                />
                <Button
                    type="primary"
                    icon={<PlusOutlined></PlusOutlined>}
                    onClick={() => {
                      setSelectVal(null);
                      showModal();
                    }}
                    >
                      
                      {'Thêm hố van'}
                      
                </Button>
            </Row>
            <Row style={{paddingTop: '20px', minHeight: '55vh', paddingBottom: 20}}>
                <Table 
                    scroll={{x: '50vh', y: '50vh'}}
                    style={{flex: 1}}
                    columns={columns} 
                    dataSource={data}
                    pagination={false} 
                    />
            </Row>
            <Row style={{justifyContent: 'flex-end', flex: 1}}>
              <Pagination defaultCurrent={1} total={length} pageSize={size} onChange={(page, pageSize) => {
                setCurrentPage(page); setSize(pageSize);
                dispatch(getVanPit({
                  page: page, 
                  size: pageSize,
                  ward: selectedWard?.key,
                  district: selectDistrict?.key,
                  village: selectedArea?.key,
                  status: selectedStatus.key,
                  province: selectedProvince?.key,
                  search: searchText
                }));
                }} style={{justifyContent: 'flex-end'}}></Pagination>
            </Row>
       </Card>
       <Modal title={<></>} open={isModalOpen} onCancel={handleCancel}  footer={[]} width={"150vh"} closeIcon={<></>}>
          <AddWaterVan data={selectVal} onAdd={(value) => {
            if(selectVal) {
              dispatch(updateVanPit({
                data: {
                  id: value.id,
                  data: {
                    city: {
                        connect: [Number(value.city)]
                    },
                    district: {
                        connect: [Number(value.district)]
                    },
                    ward: {
                        connect: [Number(value.ward)]
                    },
                    village: {
                        connect: [Number(value.area)]
                    },
                    address: value.address,
                    status: value.status ,
                    longitude: value.point?.lng,
                    latitude: value.point?.lat,
                    name: value.name,
                    image: value.image?.id?.toString()
                  }
                },
                query: {
                  page: pagination?.page ?? 1, 
                  size: pagination?.pageSize ?? 10,
                  ward: selectedWard?.key,
                  district: selectDistrict?.key,
                  village: selectedArea?.key,
                  status: selectedStatus.key,
                  province: selectedProvince?.key,
                },
            }))
              
            }else {
              dispatch(createVanPit({
                data: {
                  data: {
                    city: {
                        connect: [Number(value.city)]
                    },
                    district: {
                        connect: [Number(value.district)]
                    },
                    ward: {
                        connect: [Number(value.ward)]
                    },
                    village: {
                        connect: [Number(value.area)]
                    },
                    address: value.address,
                    status: value.status ,
                    longitude: value.point?.lng,
                    latitude: value.point?.lat,
                    name: value.name,
                    image: value.image?.id?.toString()
                  }
                },
                query: {
                  page: pagination?.page ?? 1, 
                  size: pagination?.pageSize ?? 10,
                  ward: selectedWard?.key,
                  district: selectDistrict?.key,
                  village: selectedArea?.key,
                  status: selectedStatus.key,
                  province: selectedProvince?.key,
                },
            }))
            }
           
            handleCancel()
          }}></AddWaterVan>
       </Modal>
       <Modal title="Xóa người dùng" open={isModalConfirmOpen} onOk={handleOk} onCancel={() => {setIsModalConfirmOpen(false)}}>
        <p>Bạn có chắc muốn xóa {deleteUserData?.name}</p>
      </Modal>
      </Spin>
    </Content>);
}
  const items = [
    {
      label: 'Hoạt động',
      key: '2',
      color: 'green'
    },
    {
      label: 'Đang sửa chữa',
      key: '1',
      color: 'volcano'
    },
    {
      key: '-1',
      label: "Tất cả"
    }
  ];