import { Card, Row, Col, Form, Input, Typography, Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import {DeleteOutlined} from '@ant-design/icons';
import { FilterItem } from "../../../common/define-types";
import { DropdownComponent, ItemTypeProp } from "../../../components/Dropdown/DropdownComponent";
import exportSvg from "../../../image/icon/export.svg";
import { useSelectorRoot } from "../../../store/store";
interface locationItem {
    name: string,
    id: number
}
export interface AreaItem {
    key?: string,
    title?: string | null,
        area?: locationItem[],
        district?: locationItem[],
        province?: locationItem[],
        ward?: locationItem[],
        address?: locationItem[]
}
const {Text} = Typography;
export const AreaItem = (props: {items: AreaItem | null, onUpdate: (value: AreaItem) => void, onDelete?: (value: AreaItem) => void}) => {
    const [data, setData ] = useState(props.items);
    const [disabled, SetDisabled] = useState(true);
    const {district, area, province, ward} = useSelectorRoot(state => state.location);

    const [listArea, setListArea] = useState<{label: string, key: string}[]>([]);
    const [listProvince, setListProvince] = useState<{label: string, key: string}[]>([]);
    const [listDistrict, setListDistrict] = useState<{label: string, key: string}[]>([]);
    const [listWard, setListWard] = useState<{label: string, key: string}[]>([]);
    

    const [selectDistrict, setSelectDistrict] = useState<locationItem[]>(props.items?.district ?? []);
    const [selectedWard, setSelectedWard] = useState<locationItem[]>(props.items?.ward ?? []);
    const [selectedProvince, setSelectedProvince] = useState<locationItem[]>(props.items?.province ?? []);
    const [selectedArea, setSelectedArea] = useState<locationItem[]>(props.items?.area ?? []);
    const [title, setTitle] = useState(props.items?.title);
    useEffect(() => {
        setSelectDistrict(props.items?.district ?? [])
        setSelectedProvince(props.items?.province ?? [])
        setSelectedWard(props.items?.ward ?? [])
        setSelectedArea(props.items?.area ?? [])
    }, [props.items]);
    useEffect(() => {
        setListDistrict(district?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
      }, [district]);
      useEffect(() => {
        setListProvince(province?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
      }, [province]);
      useEffect(() => {
        setListArea(area?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
      }, [area]);
      useEffect(() => {
        setListWard(ward?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
      }, [ward]);
    return (
        <Card style={{width: '100%', marginBottom: 25}}>
            <Row style={{justifyContent: 'center', alignItems: 'center'}}>
                <Col sm={24} xl={12} md={12}>
                    <Text style={{fontSize: 18, fontWeight: 500, color: '#222222'}}>{data?.title ?? "Thêm mới khu vực"}</Text>
                    
                </Col>
                <div style={{flex: 1}}></div>
                {props.items != null ?
                <Button type="primary" 
                danger 
                style={{marginRight: 10}}
                onClick={() => {
                    props.onDelete && props.onDelete({
                        key: props.items?.key,
                        title: title,
                        district: selectDistrict,
                        province: selectedProvince,
                        ward: selectedWard,
                        area: selectedArea
                    })
                }}>
                    <Row>
                    <DeleteOutlined style={{marginRight: 5, width: 18, height: 18}}/>
                      { 'Xóa'}
                    </Row>
                </Button>
                : null}
                <Button type="primary" onClick={() => {
                    props.onUpdate && props.onUpdate({
                        key: props.items?.key,
                        title: title,
                        district: selectDistrict,
                        province: selectedProvince,
                        ward: selectedWard,
                        area: selectedArea
                    })
                }}>
                    <Row>
                      <ReactSVG src={exportSvg} style={{marginRight: 5, width: 18, height: 18}}></ReactSVG>
                      {props.items == null ? "Thêm mới" :'Cập nhật'}
                    </Row>
                </Button>
            </Row>
            {
                data?.title == null?
                <Row>
                    <Col xl={24} md={24} sm={24} style={{marginBottom: 16}}>
                            <Text style={{fontSize: 13, fontWeight: 500}}>{"Tên khu vực"}</Text>
                            <Input placeholder={'Nhập tên khu vực'} 
                            value={title ?? ""}
                            onChange={
                                (text) => {setTitle(text.target.value)}
                            }></Input>
                        </Col>
                </Row>
                :null
            }
        <Row style={{flex: 1}}>
            <Col span={24} style={{marginBottom: 10}}>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#222222'}}>{'Chọn tỉnh/thành phố'}</Text>
            </Col>
            <Col span={24} style={{marginBottom: 16}}>
                <DropdownComponent items={{
                    items: listProvince,
                     onClick: (value) => {
                var data = selectedProvince?.filter(x => x.id != Number(value.key)).concat([{id: Number(value.key), name: value.label as string}])
                setSelectedProvince(data);
            }}} label={'Chọn tỉnh/thành phố'} flex={1}></DropdownComponent>
            </Col>
        </Row>
        <Row style={{flex: 1}}>
            {
                selectedProvince?.map(x => (
                    <Button
                        type="primary"
                        style={{marginLeft: 10}}
                        >
                            <Text style={{fontSize: 13, fontWeight: 400, color: 'white'}}>{x.name}</Text>
                            <Text style={{fontSize: 13, fontWeight: 400, color: 'white', marginLeft: 10}}
                            onClick={() => {
                                setSelectedProvince(selectedProvince?.filter(y => y.id != x.id));
                            }}
                            >{'X'}</Text>
                        </Button>
                ))
            }
            
        </Row>
        <Row style={{flex: 1}}>
            <Col span={24} style={{marginBottom: 10}}>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#222222'}}>{'Chọn quận/huyện'}</Text>
            </Col>
            <Col span={24} style={{marginBottom: 16}}>
                <DropdownComponent items={
                    {items: listDistrict, onClick: (value) => {
                        var data = selectDistrict?.filter(x => x.id != Number(value.key))?.concat([{id: Number(value.key), name: value.label as string}])
                        setSelectDistrict(data)
                    }}
                } label={'Chọn quận/huyện'} flex={1}></DropdownComponent>
            </Col>
        </Row>
        <Row style={{flex: 1}}>
            {
                selectDistrict?.map(x => (
                    <Button
                        type="primary"
                        style={{marginLeft: 10}}
                        >
                            <Text style={{fontSize: 13, fontWeight: 400, color: 'white'}}>{x.name}</Text>
                            <Text style={{fontSize: 13, fontWeight: 400, color: 'white', marginLeft: 10}}
                            onClick={() => {
                                setSelectDistrict(selectDistrict?.filter(y => y.id != x.id));
                            }}
                            >{'X'}</Text>
                        </Button>
                ))
            }
            
        </Row>
        <Row style={{flex: 1}}>
            <Col span={24} style={{marginBottom: 10}}>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#222222'}}>{'Chọn xã/phường/thị trấn'}</Text>
            </Col>
            <Col span={24} style={{marginBottom: 16}}>
                <DropdownComponent items={{items: listWard, onClick: (value) => {
                var data = selectedWard?.filter(x => x.id != Number(value.key)).concat([{id: Number(value.key), name: value.label as string}])
                setSelectedWard(data)
            }}} label={'Chọn xã/phường/thị trấn'} flex={1}></DropdownComponent>
            </Col>
        </Row>
        <Row style={{flex: 1}}>
            {
                selectedWard?.map(x => (
                    <Button
                        type="primary"
                        style={{marginLeft: 10}}
                        >
                            <Text style={{fontSize: 13, fontWeight: 400, color: 'white'}}>{x.name}</Text>
                            <Text style={{fontSize: 13, fontWeight: 400, color: 'white', marginLeft: 10}}
                            onClick={() => {
                                setSelectedWard(selectedWard?.filter(y => y.id != x.id));
                            }}
                            >{'X'}</Text>
                        </Button>
                ))
            }
            
        </Row>
        <Row style={{flex: 1}}>
            <Col span={24} style={{marginBottom: 10}}>
                <Text style={{fontSize: 13, fontWeight: 500, color: '#222222'}}>{'Chọn thôn/xóm'}</Text>
            </Col>
            <Col span={24} style={{marginBottom: 16}}>
                <DropdownComponent items={{items: listArea, onClick: (value) => {
                var data = selectedArea?.filter(x => x.id != Number(value.key)).concat([{id: Number(value.key), name: value.label as string}])
                setSelectedArea(data)
            }}} label={'Chọn thôn/xóm'} flex={1}></DropdownComponent>
            </Col>
        </Row>
        <Row style={{flex: 1}}>
            {
                selectedArea?.map(x => (
                    <Button
                        type="primary"
                        style={{marginLeft: 10}}
                        >
                            <Text style={{fontSize: 13, fontWeight: 400, color: 'white'}}>{x.name}</Text>
                            <Text style={{fontSize: 13, fontWeight: 400, color: 'white', marginLeft: 10}}
                            onClick={() => {
                                setSelectedArea(selectedArea?.filter(y => y.id != x.id));
                            }}
                            >{'X'}</Text>
                        </Button>
                ))
            }
            
        </Row>
            
        </Card>
    );
}