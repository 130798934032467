import { useEffect, useRef } from "react";
import { HTMLBase, convertData, invoiceHtml, onPrint } from "./PrintHelper";
var isPint = false;
export const Print = () => {
    const captureRef = useRef(null);
    const params = new URLSearchParams(window.location.search);
    
    
    useEffect(() => {
        setTimeout(() => {
            if(!isPint) {
                let data = document.getElementById("root");
                isPint = true;
            }
            
        }, 500);
    });
    
    
    var htmlString = convertData(HTMLBase(invoiceHtml), params);
    return (
        <div ref={captureRef} dangerouslySetInnerHTML={{ __html: htmlString }} />
    );
}