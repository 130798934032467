import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Pagination } from "../common/define-types";
interface Warehouse {
    wareHouseName: string,
    wareHouseAddress: string,
    createdAt: string,
    updatedAt: string
}
export interface Material {
    id?: number,
    materialCode: string,
    materialName: string,
    unit: string,
    materialPrice: number,
    createdAt: string,
    updatedAt: string,
    publishedAt: string,
    quantity: number
}
export interface WarehouseResponse {
    data: {
        id?: number,
        attributes?: Warehouse
    }[],
    meta: {
        pagination: Pagination
    }
}
export interface MaterialResponse {
    data: {
        id?: number,
        attributes?: Material
    }[],
    meta: {
        pagination: Pagination
    }
}
export interface MaterialWarehouse{
    warehouseId: number,
    quantity: number,
    createdAt: string,
    updatedAt: string,
    material: {
            data: {
                id?: number,
                attributes?: Material
            }
        }
}
export interface MaterialWarehouseResponse {
    data: {
        id?: number,
        attributes: MaterialWarehouse,
    }[],
    meta: {
        pagination: Pagination
    }
}
export interface InventoryReport {
    materialCode: string,
    materialName: string,
    materialUnit: string,
    beginningMerchandiseResult: {
        quanitity: number,
        value: number
        },
    importMerchandiseResult: {
        quanitity: number,
        value: number
        },
    exportMerchandiseResult: {
        quanitity: number,
        value: number
        },
    endingMerchandiseResult: {
        quanitity: number,
        value: number
        }
}
export interface MaterialInHouseQuery {
    size?: number, 
    page?: number,
    search?: string,
    warehouseId?: number,
    startDate?: Date,
    endDate?: Date,
    type?: number,
}
export default class WarehouseAPI {
    static host = SYSTEM_CONSTANTS.HOST;
    static getWarehouse(): Observable<WarehouseResponse | null> {
        const api = `${WarehouseAPI.host}/${SYSTEM_CONSTANTS.WAREHOUSE.GET}`;
        
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as WarehouseResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static getMaterial(): Observable<MaterialResponse | null> {
        const api = `${WarehouseAPI.host}/${SYSTEM_CONSTANTS.MATERIALS.GET}`;
        
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as MaterialResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static getMaterialInHouse(data: MaterialInHouseQuery): Observable<MaterialWarehouseResponse | null> {
        let api = `${WarehouseAPI.host}/${SYSTEM_CONSTANTS.MATERIALS.GET_MATERIAL_IN_HOUSE}?populate=material&pagination[pageSize]=${data.size ?? 10}&pagination[page]=${data.page}`;
        if(data.warehouseId  && data.warehouseId > 0) {
            api = `${api}&filters[warehouseId][$eq]=${data.warehouseId}`
        }
        console.log(data.search);
        if(data.search && data.search.length > 0) {
            api = `${api}&filters[$or][0][material][materialName][$contains]=${data.search}&filters[$or][1][material][materialCode][$contains]=${data.search}`
        }
        
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as MaterialWarehouseResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static getInventoryReport(data: MaterialInHouseQuery): Observable<InventoryReport[] | null> {
        let api = `${WarehouseAPI.host}/${SYSTEM_CONSTANTS.MATERIALS.GET_INVERTER_REPORT}?`;
        if(data.warehouseId  && data.warehouseId > 0) {
            api = `${api}wareHouseId=${data.warehouseId}`
        }
        if(data.startDate) {
            api = `${api}&fromDate=${data.startDate.toISOString()}`
        }
        if(data.endDate) {
            api = `${api}&toDate=${data.endDate.toISOString()}`
        }
        if(data.search && data.search.length > 0) {
            api = `${api}&filters[$or][0][materialName][$contains]=${data.search}&filters[$or][1][materialCode][$contains]=${data.search}`
        }
        
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as InventoryReport[]) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}