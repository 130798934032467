import { Button, Card, DatePicker, Input, MenuProps, Row, Spin, Table, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { ReactSVG } from "react-svg";
import exportSvg from "../../../image/icon/export.svg";
import { useSelectorRoot } from "../../../store/store";
import {InventoryReport}  from "../../../api/Warehouse.api";
import { DropdownComponent } from "../../../components/Dropdown/DropdownComponent";
import { getInventoryReport, getWarehouse } from "../../../store/controls/Warehouse.slice";
import { useDispatch } from "react-redux";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
const { Text } = Typography;
const { Search } = Input;
const { RangePicker } = DatePicker;
export const ExportBillReportScreen = () => {
    const dispatch = useDispatch();
    const headers = [
        { label: "Mã hàng", key: "materialCode" },
        { label: "Tên hàng", key: "materialName" },
        { label: "ĐVT", key: "materialUnit" },
        { label: "Số lượng đầu kỳ", key: "quality1" },
        { label: "Giá trị đầu kỳ", key: "value1" },
        { label: "Số lượng nhập", key: "quality2" },
        { label: "Giá trị nhập", key: "value2" },
        { label: "Số lượng xuất", key: "quality3" },
        { label: "Giá trị xuất", key: "value3" },
        { label: "Số lượng cuối kỳ", key: "quality4" },
        { label: "Giá trị cuối kỳ", key: "value4" },
        
      ];
      const columns: ColumnsType<InventoryReport> = [
        {
          title: "Mã hàng",
          dataIndex: "materialCode",
          key: "materialCode",
          render: (text, item) => (
            <div>
            <Text style={{ color: "#2222225A" }}>{text}</Text>
          </div>
          ),
        },
        {
          title: "Tên hàng",
          dataIndex: "materialName",
          key: "materialName",
          render: (text) => (
              <Text style={{ color: "#2222225A" }}>{text}</Text>
          ),
        },
        {
          title: "ĐVT",
          dataIndex: "materialUnit",
          key: "materialUnit",
          render: (text) => (
            <>
              <Text style={{ color: "#2222225A" }}>{text}</Text>
            </>
          ),
        },
        {
          title: "Đầu kỳ",
          key: "beginningMerchandiseResult",
          children:[
            {
                title: 'Số lượng',
                dataIndex: 'quanitity1',
                key: 'quanitity1',
                render: (text, record) => (
                    <>
                    <Text style={{ color: "#2222225A" }}>{record.beginningMerchandiseResult.quanitity}</Text>
                    </>
                ),
            },
            {
                title: 'Giá trị',
                dataIndex: 'value1',
                key: 'value1',
                render: (text, record) => (
                    <>
                    <Text style={{ color: "#2222225A" }}>{record.beginningMerchandiseResult.value}</Text>
                    </>
                ),
            }
          ]
        },
        {
            title: "Nhập kho",
            key: "importMerchandiseResult",
            children:[
              {
                  title: 'Số lượng',
                  dataIndex: 'quanitity2',
                  key: 'quanitity2',
                  render: (text, record) => (
                      <>
                      <Text style={{ color: "#2222225A" }}>{record.importMerchandiseResult.quanitity}</Text>
                      </>
                  ),
              },
              {
                  title: 'Giá trị',
                  dataIndex: 'value2',
                  key: 'value2',
                  render: (text, record) => (
                      <>
                      <Text style={{ color: "#2222225A" }}>{record.importMerchandiseResult.value}</Text>
                      </>
                  ),
              }
            ]
            
            
            
          },
          {
            title: "Xuất kho",
            key: "exportMerchandiseResult",
            children:[
              {
                  title: 'Số lượng',
                  dataIndex: 'quanitity3',
                  key: 'quanitity3',
                  render: (text, record) => (
                      <>
                      <Text style={{ color: "#2222225A" }}>{record.exportMerchandiseResult.quanitity}</Text>
                      </>
                  ),
              },
              {
                  title: 'Giá trị',
                  dataIndex: 'value3',
                  key: 'value3',
                  render: (text, record) => (
                      <>
                      <Text style={{ color: "#2222225A" }}>{record.exportMerchandiseResult.value}</Text>
                      </>
                  ),
              }
            ]
          },
          {
            title: "Cuối kỳ",
            key: "endingMerchandiseResult",
            children:[
              {
                  title: 'Số lượng',
                  dataIndex: 'quanitity4',
                  key: 'quanitity4',
                  render: (text, record) => (
                      <>
                      <Text style={{ color: "#2222225A" }}>{record.endingMerchandiseResult.quanitity}</Text>
                      </>
                  ),
              },
              {
                  title: 'Giá trị',
                  dataIndex: 'value4',
                  key: 'value4',
                  render: (text, record) => (
                      <>
                      <Text style={{ color: "#2222225A" }}>{record.endingMerchandiseResult.value}</Text>
                      </>
                  ),
              }
            ]
          },
      ];
      const { WarehouseData, loading, InventoryReportData } = useSelectorRoot(
        (state) => state.warehouse
      );
      const formatDate = (date: Date, formatHour: string) => {
        return moment(moment(date).utc(true).format('DD/MM/YYYY') + ' ' + formatHour, 'DD/MM/YYYY hh:mm:ss').utc(true).toDate()
      }
      const [data, setData] = useState<InventoryReport[] | null>(null)
      const [exportExportBillReport, setExportBillReport] = useState<any[]>([]);
      const [rangeDates, setRangeDates] = useState<Date[]>([formatDate(moment().utc(true).toDate(), '00:00:00'), formatDate(moment().utc(true).toDate(), '23:59:59')]);
  const [searchText, setSearchText] = useState<string>();
  const [selectedHouse, setSelectedHouse] = useState<number>(1);
  const [houses, setHouses] = useState<MenuProps["items"]>(
    WarehouseData?.data
      .map((x) => ({
        key: "" + x.id,
        label: "" + x.attributes?.wareHouseName,
      })) ?? []
  );
  useEffect(() => {
    setHouses(WarehouseData?.data
        .map((x) => ({
          key: "" + x.id,
          label: "" + x.attributes?.wareHouseName,
        })) ?? [])
  }, [WarehouseData])
  useEffect(() => {
    setData((InventoryReportData ?? []).filter(x => !searchText || (searchText && (x.materialName.includes(searchText) || x.materialCode.includes(searchText))) ));
  }, [InventoryReportData]);
  useEffect(() => {
    dispatch(
        getInventoryReport({
          search: searchText,
          warehouseId: selectedHouse,
          startDate: rangeDates[0],
            endDate: rangeDates[1],
        })
      );
  }, [searchText, rangeDates, selectedHouse])
  useEffect(() => {
    dispatch(
        getInventoryReport({warehouseId: selectedHouse,startDate: rangeDates[0],
            endDate: rangeDates[1],})
      );
    dispatch(getWarehouse())
  }, [])
  
  const onExportBill = () => {
    setExportBillReport(data?.map(
        x => ({
            ...x,
            quality1: x.beginningMerchandiseResult.quanitity,
            value1: x.beginningMerchandiseResult.value,
            quality2: x.importMerchandiseResult.quanitity,
            value2: x.importMerchandiseResult.value,
            quality3: x.exportMerchandiseResult.quanitity,
            value3: x.exportMerchandiseResult.value,
            quality4: x.endingMerchandiseResult.quanitity,
            value5: x.endingMerchandiseResult.value,
        })
    ) ?? []);
    setTimeout(() => {
        document.getElementById("exportCXV")?.click();
    }, 500)
    
  };
    return (
        <Content style={{ padding: "0px 0px 0px 15px" }}>
      <Spin spinning={loading}>
       
        <Card
          title={
            <>
              <Row style={{ margin: "20px 8px 16px 0px" }}>
                <Text style={{ fontSize: 22, fontWeight: 600 }}>
                  {"Có " + data?.length + " hàng"}
                </Text>
              </Row>
            </>
          }
        >
          <Row align={"middle"} style={{ marginBottom: 20 }}>
            <Search
              placeholder="Nhập từ khóa tìm kiếm"
              allowClear
              size="large"
              onSearch={
                (value: string) => {
                setSearchText(value);
            }
            }
              style={{ flex: 1, paddingRight: "16px" }}
            />
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Text style={{ marginRight: 20 }}>Ngày tạo phiếu</Text>
            <RangePicker style={{ marginRight: 20 }} defaultValue={[dayjs(rangeDates[0]),dayjs(rangeDates[1]),]} onChange={(value) =>{
              var dateRange: Date[] = [];
              if (value?.[0]){
                dateRange.push(formatDate(value?.[0]?.toDate(), '00:00:00'))
              }
              if (value?.[1]){
                dateRange.push(formatDate(value?.[1]?.toDate(), '23:59:59'))
              }
              setRangeDates(dateRange);
              
            }}/>
            <DropdownComponent
              items={{
                items: houses,
                onClick: (value) => {
                  setSelectedHouse(Number(value.key));
                },
              }}
              label={"Chọn kho"}
              value={
                WarehouseData?.data.find((x) => x?.id == selectedHouse)
                  ?.attributes?.wareHouseName ?? "Tất cả kho"
              }
              flex={1}
            ></DropdownComponent>
            <div>
              
              <Button
                type="primary"
                style={{ justifyContent: "center", alignItems: "center" }}
                onClick={onExportBill}
              >
                <Row>
                  <ReactSVG
                    src={exportSvg}
                    style={{ marginRight: 5, width: 18, height: 18 }}
                  ></ReactSVG>
                  {"Xuất file"}
                </Row>
              </Button>
              <CSVLink
                style={{ display: "none" }}
                headers={headers}
                filename={`Báo cáo phiếu xuất và nhập.csv`}
                data={
                  exportExportBillReport
                }
                id="exportCXV"
              />
            </div>
          </Row>
          <Row
            style={{ paddingTop: "20px", minHeight: "55vh", paddingBottom: 20 }}
          >
            <Table
              scroll={{ x: "60vh", y: "50vh" }}
              style={{ flex: 1 }}
              columns={columns}
              dataSource={data ?? []}
              pagination={false}
            />
          </Row>
          <Row style={{ justifyContent: "flex-end", flex: 1 }}>
            
          </Row>
        </Card>
      </Spin>
         </Content>
    );
}
