import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import LocationApproveAPI, { LocationApproveResponse } from "../../api/LocationApprove.api";
import { Pagination, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface LocationApproveState {
    loading: boolean;
    locationApproveData: LocationApproveResponse| null
    approveDataResponse: LocationApproveResponse| null;
    rejectDataResponse: LocationApproveResponse| null;
    errorMessage: string | null;
    pagination: Pagination | null;
}

const initState: LocationApproveState = {
    loading: false,
    locationApproveData: null,
    approveDataResponse: null,
    rejectDataResponse: null,
    errorMessage: null,
    pagination: null
};
const locationApproveSlice = createSlice({
    name: 'locationApprove',
    initialState: initState,
    reducers: {
        getLocationApprove(state, action: PayloadAction<{page: number, size: number}>) {
            state.loading = true;
        },
        getLocationApproveSuccess(state, action: PayloadAction<LocationApproveResponse>) {
            state.loading = false;
            state.locationApproveData = action.payload;
            state.pagination = action.payload.meta.pagination;
        },
        approveLocationApprove(state, action: PayloadAction<{page: number, size: number, id: string}>) {
            state.loading = true;
            
        },
        approveLocationApproveSuccess(state, action: PayloadAction<LocationApproveResponse>) {
            state.loading = false;
            state.locationApproveData = action.payload;
            state.pagination = action.payload.meta.pagination;
        },
        rejectLocationApprove(state, action: PayloadAction<{page: number, size: number, id: string}>) {
            state.loading = true;
        },
        rejectLocationApproveSuccess(state, action: PayloadAction<LocationApproveResponse>) {
            state.loading = false;
            state.locationApproveData = action.payload;
            state.pagination = action.payload.meta.pagination;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const locationApprove$: RootEpic = (action$) =>
    action$.pipe(
        filter(getLocationApprove.match),
        switchMap((re) => {
            return LocationApproveAPI.get(re.payload.page, re.payload.size).pipe(
                mergeMap((res: any) => {
                    return [
                        locationApproveSlice.actions.getLocationApproveSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    locationApproveSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

const approveLocationApprove$: RootEpic = (action$) =>
    action$.pipe(
        filter(approveLocationApprove.match),
        switchMap((re) => {
            return LocationApproveAPI.approve(re.payload.id).pipe(
                mergeMap((res: any) => {
                    return LocationApproveAPI.get(re.payload.page, re.payload.size).pipe(
                        mergeMap((res: any) => {
                            return [
                                locationApproveSlice.actions.approveLocationApproveSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            locationApproveSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    );
                }),
                catchError((e: AjaxError) => [
                    locationApproveSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const rejectLocationApprove$: RootEpic = (action$) =>
    action$.pipe(
        filter(rejectLocationApprove.match),
        switchMap((re) => {
            return LocationApproveAPI.rejct(re.payload.id).pipe(
                mergeMap((res: any) => {
                    return LocationApproveAPI.get(re.payload.page, re.payload.size).pipe(
                        mergeMap((res: any) => {
                            return [
                                locationApproveSlice.actions.rejectLocationApproveSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            locationApproveSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    );
                    
                }),
                catchError((e: AjaxError) => [
                    locationApproveSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const LocationApproveEpics = [locationApprove$, approveLocationApprove$, rejectLocationApprove$];
export const {
    getLocationApprove,
    getLocationApproveSuccess,
    approveLocationApprove,
    approveLocationApproveSuccess,
    rejectLocationApprove,
    rejectLocationApproveSuccess
} = locationApproveSlice.actions;
export const locationApproveReducer = locationApproveSlice.reducer;