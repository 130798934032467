import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
export interface OverviewResponse {
    totalClient: number,
    waterMeterTechnicianQuantity: number,
    operatorTechinicianQuantity: number,
    locationConfirmRequestQuantity: number,
    scheduleChangeRequestQuantity: number,
    unpaidClientQuantity: number,
    unRecordedWaterBillQuantity: number,
    monthRevenue: number,
    waterUsage: number,
    totalDept: number
}
export default class OverviewAPI {
    static host = SYSTEM_CONSTANTS.HOST;
    static get(): Observable<OverviewResponse | null> {
        const api = `${OverviewAPI.host}/${SYSTEM_CONSTANTS.OVERVIEW.GET}?populate=totalClient,waterMeterTechnicianQuantity,operatorTechinicianQuantity,locationConfirmRequestQuantity,scheduleChangeRequestQuantity,unpaidClientQuantity,monthRevenue,waterUsage,totalDept,unRecordedWaterBillQuantity`;
        
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as OverviewResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}