import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Pagination, User } from "../common/define-types";
import { Client } from "./Client.api";
export interface LocationApprove {
    id: number,
    attributes: {
        latitude: number,
        createdAt: Date,
        updatedAt: Date,
        status: number,
        longitude: number,
        client: {
            data: {
                id: number,
                attributes: Client
            }
        },
        userRequest: {
            data: {
                id: number,
                attributes: User
            }
        }
    }
}
export interface LocationApproveResponse {
    data: LocationApprove[],
    meta: {
        pagination:Pagination
    }
    
}
export default class LocationApproveAPI {
    static host = SYSTEM_CONSTANTS.HOST;
    static get(page: number, size: number): Observable<LocationApproveResponse | null> {
        const api = `${LocationApproveAPI.host}/${SYSTEM_CONSTANTS.LOCATION_APROVE.GET}?populate[client][populate]=city,district,ward,village&populate=userRequest&pagination[pageSize]=${size}&pagination[page]=${page}`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as LocationApproveResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static approve(id: string): Observable<LocationApproveResponse | null> {
        const api = `${LocationApproveAPI.host}/${SYSTEM_CONSTANTS.LOCATION_APROVE.APPROVE(id)}`;
        return HttpClient.put(api, null, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as LocationApproveResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static rejct(id: string): Observable<LocationApproveResponse | null> {
        const api = `${LocationApproveAPI.host}/${SYSTEM_CONSTANTS.LOCATION_APROVE.REJECT(id)}`;
        return HttpClient.put(api, null, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as LocationApproveResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}