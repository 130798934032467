import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ScheduleAPI, { ScheduleRequest, ScheduleResponse } from "../../api/Schedule.api";
import { Pagination, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface ScheduleState {
    loading: boolean;
    scheduleData: ScheduleResponse| null
    createSchedule: ScheduleResponse | null,
    approveSchedule: ScheduleResponse| null,
    rejectSchdule: ScheduleResponse | null,
    errorMessage: string | null;
    pagination: Pagination | null;
}

const initState: ScheduleState = {
    loading: false,
    scheduleData: null,
    errorMessage: null,
    createSchedule: null,
    rejectSchdule: null,
    approveSchedule: null,
    pagination: null
};
const scheduleSlice = createSlice({
    name: 'schedule',
    initialState: initState,
    reducers: {
        getSchedule(state, action: PayloadAction<{page: number, size: number, status?: number}>) {
            state.loading = true;
        },
        getScheduleSuccess(state, action: PayloadAction<ScheduleResponse>) {
            state.loading = false;
            state.scheduleData = action.payload;
            state.pagination = action.payload.meta.pagination;
        },
        createSchedule(state, action: PayloadAction<ScheduleRequest>) {
            state.loading = true;
        },
        createScheduleSuccess(state, action: PayloadAction<ScheduleResponse>) {
            state.loading = false;
            state.createSchedule = action.payload;
        },
        approveSchedule(state, action: PayloadAction<{id: string, page: number, size: number, status?: number}>) {
            state.loading = true;
        },
        aprroveScheduleSuccess(state, action: PayloadAction<ScheduleResponse>) {
            state.loading = false;
            state.approveSchedule = action.payload;
        },
        rejectSchedule(state, action: PayloadAction<{id: string, page: number, size: number, status?: number, rejectReason?: string}>) {
            state.loading = true;
        },
        rejectScheduleSuccess(state, action: PayloadAction<ScheduleResponse>) {
            state.loading = false;
            state.rejectSchdule = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const schedule$: RootEpic = (action$) =>
    action$.pipe(
        filter(getSchedule.match),
        switchMap((re) => {
            return ScheduleAPI.get(re.payload.page, re.payload.size, re.payload.status).pipe(
                mergeMap((res: any) => {
                    return [
                        scheduleSlice.actions.getScheduleSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    scheduleSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const createSchedule$: RootEpic = (action$) =>
    action$.pipe(
        filter(createSchedule.match),
        switchMap((re) => {
            return ScheduleAPI.create(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        scheduleSlice.actions.createScheduleSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    scheduleSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const rejectSchedule$: RootEpic = (action$) =>
    action$.pipe(
        filter(rejectSchedule.match),
        switchMap((re) => {
            return ScheduleAPI.reject(re.payload.id, re.payload.rejectReason).pipe(
                mergeMap((res: any) => {
                    return ScheduleAPI.get(re.payload.page, re.payload.size, re.payload.status).pipe(
                        mergeMap((res: any) => {
                            return [
                                scheduleSlice.actions.getScheduleSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            scheduleSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    scheduleSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const approveSchedule$: RootEpic = (action$) =>
    action$.pipe(
        filter(approveSchedule.match),
        switchMap((re) => {
            return ScheduleAPI.approve(re.payload.id).pipe(
                mergeMap((res: any) => {
                    return ScheduleAPI.get(re.payload.page, re.payload.size, re.payload.status).pipe(
                        mergeMap((res: any) => {
                            return [
                                scheduleSlice.actions.getScheduleSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            scheduleSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    scheduleSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const ScheduleEpics = [schedule$, createSchedule$, approveSchedule$, rejectSchedule$];
export const {
    getSchedule,
    getScheduleSuccess,
    createSchedule,
    createScheduleSuccess,
    rejectSchedule,
    rejectScheduleSuccess,
    approveSchedule,
    aprroveScheduleSuccess
} = scheduleSlice.actions;
export const scheduleReducer = scheduleSlice.reducer;