import { CChart } from "@coreui/react-chartjs";
import { Card, MenuProps, Row, Spin, Typography } from "antd"
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Utils from "../../../common/Utils";
import { DropdownComponent } from "../../../components/Dropdown/DropdownComponent";
import { getBillWaterUsageMonth, getBillWaterUsageYear } from "../../../store/controls/Bill.slice";
import { useSelectorRoot } from "../../../store/store";
const {Text} = Typography;
export const AmountOfWWater = () => {
    const dispatch = useDispatch();
    const years: MenuProps['items'] = Utils.arrayRange(1900, new Date().getFullYear(), 1).map(x =>({key: '' + x, label: '' + x})).reverse();
    const {billWaterUsageMonth, billWaterUsageYear, loading} = useSelectorRoot(state => state.bill);
    const {ward} = useSelectorRoot(x => x.location)
    const {isSuccess} = useSelectorRoot(state => state.login)
    const [selectedFromYear, setSelectedFromYear] = useState<number>((new Date).getFullYear() - 5);
    const [selectedToYear, setSelectedToYear] = useState<number>((new Date).getFullYear());
    const [selectedMonth, setSelectedMonth] = useState<number>((new Date).getFullYear());
    const [listArea, setListArea] = useState(ward?.map(x => ({label: x.attributes.name, key: x.id})).concat([{label: 'Tất cả', key: -1}]));
    const [areaMonth, setAreaMonth] = useState<number>();
    const [areaYear, setAreaYear] = useState<number>();

    const [waterUsageMonth, setWaterUsageMonth] = useState(billWaterUsageMonth);
    const [waterUsageYear, setWaterUsageYear] = useState(billWaterUsageYear);
    useEffect(() => {
        setWaterUsageYear(billWaterUsageYear)
    }, [billWaterUsageYear]);

    useEffect(() => {
        setWaterUsageMonth(billWaterUsageMonth)
    }, [billWaterUsageMonth]);
    useEffect(() => {
        if (selectedFromYear && selectedToYear && selectedToYear > selectedFromYear) {
            dispatch(getBillWaterUsageYear({end: selectedToYear, start: selectedFromYear, ward: areaYear}));
        }
        dispatch(getBillWaterUsageMonth({ year: selectedMonth, ward: areaMonth}));
    }, [isSuccess])
    useEffect(() => {
        setListArea(ward?.map(x => ({label: x.attributes.name, key: x.id})).concat([{label: 'Tất cả', key: -1}]));
    }, [ward])
    return (
        <div style={{overflowY: 'scroll', width: '100%', height: 'calc(100vh - 150px)', padding: '15px 15px 30px 15px'}}>
        <Spin spinning={loading}>
        <Card style={{width: '100%', marginBottom: 30}}>
            <Row style={{marginBottom: '10px'}}>
                <Text style={{fontSize: 22, fontWeight: 600}}>{'Lượng nuớc tiêu thụ theo tháng'}</Text>
                <div style={{flex: 1}}></div>
                <DropdownComponent items={{items: years, onClick: (value) => {
                    setSelectedMonth(Number(value.key));
                    dispatch(getBillWaterUsageMonth({ year: Number(value.key), ward: areaMonth}));
                }}} label={'Chọn năm'} flex={0.3} value={selectedMonth}></DropdownComponent>
                <DropdownComponent items={{items: listArea, onClick: (value) => {
                    setAreaMonth(Number(value.key))
                    dispatch(getBillWaterUsageMonth({ year: selectedMonth, ward: Number(value.key)}));
                    }}} label={'Chọn khu vực'} flex={0.3}></DropdownComponent>
            </Row>
            
            <CChart
                type="bar"
                data={{
                    labels: waterUsageMonth?.map(x => x.month),
                    datasets: [
                    {
                        label: 'Lượng nước tiêu thụ (m3)',
                        backgroundColor: '#329BF09A',
                        data: waterUsageMonth?.map(x => x.value)?? [],
                        hoverBackgroundColor: '#005BA5',
                        maxBarThickness: 28,
                        
                    },
                    ],
                }}
                />
        </Card>
        <Card style={{width: '100%', marginBottom: 30}}>
            <Row style={{marginBottom: '10px'}}>
                <Text style={{fontSize: 22, fontWeight: 600}}>{'Lượng nuớc tiêu thụ theo năm'}</Text>
                <div style={{flex: 1}}></div>
                <DropdownComponent items={{items: years, onClick: (value) => {
                    setSelectedFromYear(Number(value.key));
                    if (selectedFromYear && selectedToYear && selectedToYear > selectedFromYear) {
                        dispatch(getBillWaterUsageYear({end: selectedToYear, start: Number(value.key), ward: areaYear}));
                    }
                    }}} label={'Chọn từ năm'} flex={0.3} value={selectedFromYear}></DropdownComponent>
                <DropdownComponent items={{items: years, onClick: (value) => {
                    setSelectedToYear(Number(value.key))
                    if (selectedFromYear && selectedToYear && selectedToYear > selectedFromYear) {
                        dispatch(getBillWaterUsageYear({end: Number(value.key), start: selectedFromYear, ward: areaYear}));
                    }
                    }}} label={'Chọn đến năm'} flex={0.3} value={selectedToYear}></DropdownComponent>
                <DropdownComponent items={{items: listArea, onClick: (value) => {
                    setAreaYear(Number(value.key));
                    if (selectedFromYear && selectedToYear && selectedToYear > selectedFromYear) {
                        dispatch(getBillWaterUsageYear({end: selectedToYear, start: selectedFromYear, ward: Number(value.key)}));
                    }
                    }}} label={'Chọn xã'} flex={0.3}></DropdownComponent>
            </Row>
            
            <CChart
                type="bar"
                data={{
                    labels: waterUsageYear?.map(x => x.year) ?? [],
                    datasets: [
                    {
                        label: 'Lượng nước tiêu thụ (m3)',
                        backgroundColor: '#329BF09A',
                        data: waterUsageYear?.map(x => x.value) ?? [],
                        hoverBackgroundColor: '#005BA5',
                        maxBarThickness: 28,
                        
                    },
                    ],
                }}
                />
        </Card>
        </Spin>
        </div>
    );
}