import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ZoneAPI, { Zone, ZoneRequest, ZoneResponse } from "../../api/Zone.api";
import { RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface ZoneState {
    loading: boolean;
    zoneData: Zone[]| null,
    postZone: ZoneResponse | null;
    putZone: ZoneResponse | null;
    deleteZone: ZoneResponse | null;
    errorMessage: string | null;
}

const initState: ZoneState = {
    loading: false,
    zoneData: null,
    errorMessage: null,
    postZone: null,
    putZone: null,
    deleteZone: null
};
const zoneSlice = createSlice({
    name: 'zone',
    initialState: initState,
    reducers: {
        getZone(state, action: PayloadAction<boolean | undefined>) {
            state.loading = true;
        },
        getZoneSuccess(state, action: PayloadAction<ZoneResponse>) {
            state.loading = false;
            state.zoneData = action.payload.data;
        },
        postZone(state, action: PayloadAction<ZoneRequest>) {
            state.loading = true;
        },
        postZoneSuccess(state, action: PayloadAction<ZoneResponse>) {
            state.loading = false;
            state.postZone = action.payload;
        },
        putZone(state, action: PayloadAction<ZoneRequest>) {
            state.loading = true;
        },
        putZoneSuccess(state, action: PayloadAction<ZoneResponse>) {
            state.loading = false;
            state.putZone = action.payload;
        },
        deleteZone(state, action: PayloadAction<string>) {
            state.loading = true;
        },
        deleteZoneSuccess(state, action: PayloadAction<void>) {
            state.loading = false;
        },
        
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const getZone$: RootEpic = (action$) =>
    action$.pipe(
        filter(getZone.match),
        switchMap((re) => {
            return ZoneAPI.get(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        zoneSlice.actions.getZoneSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    zoneSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const postZone$: RootEpic = (action$) =>
    action$.pipe(
        filter(postZone.match),
        switchMap((re) => {
            return ZoneAPI.post(re.payload).pipe(
                mergeMap((res: any) => {
                    return ZoneAPI.get(true).pipe(
                        mergeMap((res: any) => {
                            return [
                                zoneSlice.actions.getZoneSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            zoneSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    zoneSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const putZone$: RootEpic = (action$) =>
    action$.pipe(
        filter(putZone.match),
        switchMap((re) => {
            return ZoneAPI.put(re.payload).pipe(
                mergeMap((res: any) => {
                    return ZoneAPI.get(true).pipe(
                        mergeMap((res: any) => {
                            return [
                                zoneSlice.actions.getZoneSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            zoneSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    zoneSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const deleteZone$: RootEpic = (action$) =>
    action$.pipe(
        filter(deleteZone.match),
        switchMap((re) => {
            return ZoneAPI.delete(re.payload).pipe(
                mergeMap((res: any) => {
                    return ZoneAPI.get(true).pipe(
                        mergeMap((res: any) => {
                            return [
                                zoneSlice.actions.getZoneSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            zoneSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                }),
                catchError((e: AjaxError) => [
                    zoneSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const ZoneEpics = [getZone$, deleteZone$, putZone$, postZone$];
export const {
    getZone,
    getZoneSuccess,
    postZone,
    postZoneSuccess,
    putZone,
    putZoneSuccess,
    deleteZone,
    deleteZoneSuccess
} = zoneSlice.actions;
export const zoneReducer = zoneSlice.reducer;