import React, { useEffect, useState } from 'react';
import { Layout, notification } from 'antd';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Login } from './Login';
import { useSelectorRoot } from '../store/store';
import { LeftMenu } from './leftMenu/LeftMenu';
import { Main } from './main/Main';
import { useDispatch } from 'react-redux';
import Utils from '../common/Utils';
import { getProfile, setLoginSuccess } from '../store/controls/LoginEpic';
import { ResponseToken } from '../common/define-types';
import { pushBreadcrum } from '../store/slice/controlSlice';
import { createBreadcrum } from './Routes';
import { getArea, getDistrict, getLine, getPriceCatagory, getProvince, getWard } from '../store/controls/location.slice';
import {getRole} from '../store/controls/User.slice'
import { getZone } from '../store/controls/Zone.slice';
import { Print } from './print/Print';

export const Root = () => {
  const {isSuccess, user} = useSelectorRoot(state => state.login);
  const {isCollap} = useSelectorRoot(state => state.control)
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const [api, contextHolder] = notification.useNotification();
  history.listen((e) => {
    if (isSuccess) navigateBreadcrum(e.pathname);
  });
  useEffect(() => {
    Utils.getValueLocalStorage(Utils.constant.token).then(async (token: ResponseToken) => {
      if (token !=null) {
        var user = await Utils.getValueLocalStorage(Utils.constant.user);
        dispatch(setLoginSuccess({status: true, token: token.jwt, user}));
        navigateBreadcrum(location.pathname);
      } else {
        history.replace('/login');
      }
    });
    
  }, []);
  useEffect(() => {
    if (isSuccess) {
      if (user) dispatch(getProfile(user.id));
      dispatch(getDistrict());
      dispatch(getProvince());
      dispatch(getWard());
      dispatch(getArea());
      dispatch(getLine());
      dispatch(getPriceCatagory());
      dispatch(getZone(true));
      dispatch(getRole());
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isSuccess) {
      setReRender(true);
      var timeOut = setTimeout(() => {
        setReRender(false);
        if(timeOut) {
          clearTimeout(timeOut);
        }
      }, 200);
    }
  }, [isCollap, isSuccess])
  const navigateBreadcrum = (path: string) => {
    var data = createBreadcrum(path)
    dispatch(pushBreadcrum(data));
  }
  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  const [reRender, setReRender] = useState(false)
  useEffect(() => {
    window.addEventListener('resize', () =>{
      
      
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      if(window.innerWidth != dimensions.width) {
        setReRender(true);
        var timeOut = setTimeout(() => {
          setReRender(false);
          if(timeOut) {
            clearTimeout(timeOut);
          }
        }, 500)  
      }
        
  })
  }, [])
  return (
    <Layout style={{ height: '100vh' }}>
      {contextHolder}
      {isSuccess && location.pathname.indexOf('print') < 0 && <LeftMenu />}
      {!reRender && 
      <Layout>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/print" component={Print} />
          <Route path="/" component={Main} />
        </Switch>
      </Layout>
      }
    </Layout>
  );
};
