import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Area } from "./location.api";
import { Pagination } from "../common/define-types";
export interface Zone {
    id: number,
    attributes: {
        name: string,
        createdAt: Date,
        updatedAt: Date,
        cities: {
            data: Area[]
        },
        districts: {
            data: Area[]
        },
        wards: {
            data: Area[]
        },
        villages: {
            data: Area[]
        }
    }
}
export interface ZoneResponse {
    data: Zone[],
    meta: {
        pagination: Pagination
    }
}
export interface ZoneRequest {
    id?: number;
    data: {
        name: string;
        cities: {
            disconnect?: number[],
            connect?: number[]
        },
        districts: {
            disconnect?: number[],
            connect?: number[]
        },
        wards: {
            disconnect?: number[],
            connect?: number[]
        },
        villages: {
            disconnect?: number[],
            connect?: number[]
        }
    }
}
export default class ZoneAPI {
    static host = SYSTEM_CONSTANTS.HOST;
    static get(isFull: boolean = false): Observable<ZoneResponse | null> {
        const api = `${ZoneAPI.host}/${SYSTEM_CONSTANTS.ZONE.GET}${isFull ? '?populate=cities,districts,wards,villages' : ''}`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ZoneResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static post(body: ZoneRequest): Observable<ZoneResponse | null> {
        const api = `${ZoneAPI.host}/${SYSTEM_CONSTANTS.ZONE.POST}`;
        return HttpClient.post(api, body, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ZoneResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static put(body: ZoneRequest): Observable<ZoneResponse | null> {
        const api = `${ZoneAPI.host}/${SYSTEM_CONSTANTS.ZONE.PUT(body.id?.toString() ?? "")}`;
        return HttpClient.put(api, body, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ZoneResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static delete(id: string): Observable<ZoneResponse | null> {
        const api = `${ZoneAPI.host}/${SYSTEM_CONSTANTS.ZONE.DELETE(id)}`;
        return HttpClient.delete(api, null, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ZoneResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}