import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Pagination } from "../common/define-types";
import moment from "moment";

export interface MaterialItem {
    id?: number;
            materialName?:string;
            createdAt?: Date;
            updatedAt?: Date;
            publishedAt?:Date;
            materialCode?: string;
            materialUnit?: string;
            requestQuantity?: number;
            realQuantity?: number;
            materialPrice?: number;
            totalMoney?: number;
            actionType?: number;
            importExportId?: string;
            materialId?: string;
            materialWarehouseId?: string;
}
export interface ExportBill {
    id?: number,
    employeeName: string,
    department?: string,
    reason: string,
    wareHouseName?: string,
    wareHouseAddress?: string,
    type: number,
    createdAt?: Date,
    updatedAt?: Date,
    publishedAt?: Date,
    wareHouseId: string;
    materialItems: MaterialItem[]
}
export interface ExportBillResponse {
    data: {
        id?: number,
        attributes: ExportBill
    }[],
    meta: {
        pagination: Pagination
    }
}

export interface ExportBillQuery {
    size: number, 
    page: number,
    search?: string,
    warehouseId?: number,
    startDate?: Date,
    endDate?: Date,
    type: number,
}
export default class ExportBillAPI {
    static host = SYSTEM_CONSTANTS.HOST;
    static getExportBill(query: ExportBillQuery): Observable<ExportBillResponse | null> {
        let api = `${ExportBillAPI.host}/${SYSTEM_CONSTANTS.ExportBill.GET}?pagination[pageSize]=${query.size ?? 10}&pagination[page]=${query.page}`;
        if(query.warehouseId) {
            api = `${api}&filters[wareHouseId][$eq]=${query.warehouseId}`
        }
        if(query.type) {
            api = `${api}&filters[type][$eq]=${query.type}`
        }
        if(query.startDate) {
            api = `${api}&filters[publishedAt][$gt]=${moment(query.startDate).utc(true).toISOString()}`
        }
        if(query.endDate) {
            api = `${api}&filters[publishedAt][$lt]=${moment(query.endDate).utc(true).toISOString()}`
        }
        return HttpClient.get(api, {
            headers: {  
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ExportBillResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static createExportBill(body: ExportBill): Observable<ExportBillResponse | null> {
        let api = `${ExportBillAPI.host}/${SYSTEM_CONSTANTS.ExportBill.CREATE}`;
        
        return HttpClient.post(api,body, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ExportBillResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    
}