import React, { useEffect, useState } from 'react'
import { Button, Layout, Menu, Typography } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelectorRoot } from '../../store/store'
import { pushBreadcrum, setCollap } from '../../store/slice/controlSlice'
import { RouteObj, Routes } from '../Routes'
import CaratRight from 'remixicon-react/ArrowRightSLineIcon'
import CaratLeft from 'remixicon-react/ArrowLeftSLineIcon'
import logo from '../../logo.svg';
import styles from './LeftMenu.module.css';
import Home from '../../image/icon/smart-home.svg';
import HeaderLogo from '../../image/icon/Header-logo.svg';
import { FloatingActionButton } from '../../components/Buttons/FloatingActionButton'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb'
const {Text} = Typography;
interface SVGProps {
  $filled?: boolean;
  $colorText?: string
}
const SVG = styled(ReactSVG)<SVGProps>`
  & svg rect{
    stroke: ${(props) => (props.$filled ? '#005BA5' : props.$colorText)};
  };
  & svg ellipse {
    stroke: ${(props) => (props.$filled ? '#005BA5' : props.$colorText)};
  };
  & svg path {
    stroke: ${(props) => (props.$filled ? '#005BA5' : props.$colorText)};
  }
`

export const LeftMenu = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const collapsed = useSelectorRoot(state => state.control.isCollap)
  const onCollapse = () => {dispatch(setCollap(!collapsed));}
  const getWindowDimensions = () => {
    const width = typeof window !== 'undefined' ? window.innerWidth : 1000;
    const height = typeof window !== 'undefined' ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    if (getWindowDimensions().width < 1000)dispatch(setCollap(true));
  }, [])
  const [widthWin, setWitdhWin] = useState(getWindowDimensions().width);
  const navigate = ({ key }: any) => {
    history.push(key)
  }
  window.addEventListener('resize', () => {
    var withWindows = getWindowDimensions().width;
    setWitdhWin(withWindows ?? 1000);
  });

  const activeKey = (): string => {
    for(var route of Routes){
      if (location.pathname.startsWith(`${route.routeProps?.path as string}`) ) {
        return route.routeProps?.path as string;
      }
      for(var child of (route.items ?? [])){
        if(location.pathname.startsWith(`${child.routeProps?.path as string}`)) {
          return child.routeProps?.path as string;
        }
      }
    }
    return '/';
  }
  const activeGroupKey = (): string => {
    for(var route of Routes){
      if (location.pathname.startsWith(`${route.routeProps?.path as string}`) ) {
        return route.routeProps?.path as string;
      }
      for(var child of (route.items ?? [])){
        if(location.pathname.startsWith(`${child.routeProps?.path as string}`)) {
          return route.routeProps?.path as string;
        }
      }
    }
    return '/';
  }
  const rederItem = (route: RouteObj) => (
    route.isRoot?
    <Menu.Item 
    style={{
      backgroundColor: "white",
      borderColor: "white",
    }}
            key={route.routeProps? (route.routeProps.path as string): undefined}
            disabled={true}
            icon={route.icon ? <img src={route.icon} alt='icon'></img>  : undefined}>
              {
              !collapsed?
              <Text style={{
                marginLeft: route.icon || route.isRoot? 0 : 15, 
                fontSize: 15,
                color: activeKey() === route.routeProps?.path as string? '#005BA5' :route.color}}>{ route.label}</Text>
              :  <Text></Text>}
              
              </Menu.Item>
    :<Menu.SubMenu key={route.routeProps? (route.routeProps.path as string) : undefined} 
    style={{
      backgroundColor: "white",
      borderColor: "white",
      background: "white"
    }}
    icon={route.icon ? <SVG src={route.icon} $filled={ activeGroupKey() === route.routeProps?.path } $colorText={route.color ?? '#222222AF'}></SVG> : undefined}
    title={<Text style={{
      fontSize: 15,
      color: activeGroupKey() === route.routeProps?.path as string? '#005BA5' :route.color,
      fontWeight: activeGroupKey() === route.routeProps?.path as string? 600 : 400
    }}>{ route.label}</Text>}>
      {route.items && route.items.map(child => (
          <Menu.Item 
          style={{
            backgroundColor: "white",
            borderColor: "white",
            background: "white"
          }}
            key={child.routeProps? (child.routeProps.path as string): undefined}
            icon={child.icon ? <img src={child.icon} alt='icon'></img>  : undefined}>
              {
              !collapsed?
              <Text style={{
                marginLeft: child.icon || child.isRoot? 0 : 0, 
                fontSize: 13,
                color: activeKey() === child.routeProps?.path as string? '#005BA5' :child.color,
                fontWeight: activeKey() === child.routeProps?.path as string? 600 : 400
              }}>{ child.label}</Text>
              :  <Text style={{color: activeKey() === child.routeProps?.path as string? '#005BA5' :child.color}}>{ child.label}</Text>}
              
              </Menu.Item>
          ))}
    </Menu.SubMenu>
  ) ; 
  const render = () => (
    <>
     <div
        style={{
          padding: collapsed? 16 :16,
          width: '100%',
          background: '#f0f2f5',
          // textAlign: 'center',
          minHeight: '77px',
          backgroundColor: 'white'
        }}
      >
        {collapsed ? (
          <img src={logo} width={40} height={40} alt={'logo'}/>
          
        ) : (
        //   <div >
        //   <Text style={{color:'#005BA5', fontSize: 12}}>NƯỚC SẠCH</Text>
        //   <br></br>
        //   <Text style={{color:'#329BF0', fontSize: 16, fontWeight: 800 }}>LAM SƠN</Text><Text style={{color:'#005BA5', fontSize: 16, fontWeight: 800}}> SAO VÀNG</Text>
        // </div>
        <img src={HeaderLogo} alt='logo'></img>
        )}
      </div>

      <FloatingActionButton top="106px" right="-14px" onClick={onCollapse}>
        {collapsed ? (
          <CaratRight style={{ verticalAlign: 'middle' }} />
        ) : (
          <CaratLeft style={{ verticalAlign: 'middle' }} />
        )}
      </FloatingActionButton>
      <Menu
        onSelect={navigate}
        className={styles.LeftMenu}
        selectedKeys={[activeKey()]}
        inlineCollapsed={collapsed}
        mode="inline"
      >
        <Menu.Item key={'/'} icon={<img src={Home} alt='home'/>} style={{backgroundImage: 'linear-gradient(to right, #005BA5, #329BF0)'}} title={"Bảng điều khiển"}>
          <Text style={{color: 'white'}}>{collapsed? "" : "Bảng điều khiển"}</Text>
        </Menu.Item>
        {Routes.map(route => (
          <>
          {rederItem(route)}
          
          </>
        ))}
        
      </Menu>
    </>
  )
  

  return (
    widthWin >= 1000? 
    <Layout.Sider collapsible={false} width={collapsed? 50 : 260} collapsed={collapsed} style={{backgroundColor: 'white'}}>
      {render()}
    </Layout.Sider>
    : <Layout.Sider  width={ 50} collapsed={collapsed} style={{backgroundColor: 'white'}}>
      <Layout style={{backgroundColor: 'white', height: "100%", position: "absolute", top: 0, left: 0, zIndex: 1000, width: collapsed? 80 : 260}}>
      {render()}
      </Layout>
    </Layout.Sider >
  )
}
