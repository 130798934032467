import React, { ReactNode } from 'react'
import { RouteProps } from 'react-router-dom'
import Chart from '../image/icon/chart-bar.svg'
import Users from '../image/icon/users.svg'
import User from '../image/icon/user.svg'
import Brand from '../image/icon/brand-tabler.svg'
import { StatusMeasureWater } from './screen/StatusMeasureWater/StatusMeasureWater'
import { AmountOfWWater } from './screen/AmountOfWater/AmountOfWater'
import { Revenue } from './screen/Revenue/Revenue'
import { PositionRequest } from './screen/PositionRequest/PositionRequest'
import {  UserManager } from './screen/UserManager/UserManager'
import { WaterVan } from './screen/WaterVan/WaterVan'
import { MeasureWaterArea } from './screen/MeasureWaterArea/MeasureWaterArea'
import { MeasureWaterPerson } from './screen/MeasureWaterPerson/MeasureWaterPerson'
import { Shift } from './screen/Shift/Shift'
import { ChangeShiftRequest } from './screen/ChangeShiftRequest/ChangeShiftRequest'
import { Operator } from './screen/Operator/Operator'
import { Admin } from './screen/admin/Admin'
import { UserDetail } from './screen/UserManager/UserDetail'
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { ReportRequest } from './screen/ContractRequest/ReportRequest'
import { OperatorReport } from './screen/OperatorReport/OperatorReport'
import { MaterialWarehouseScreen } from './screen/MaterialWarehouse/MaterialWarehouse'
import { ExportBillScreen } from './screen/ExportBill/ExportBillScreen'
import { ExportBillReportScreen } from './screen/ExportBillReport/ExportBillReportScreen'

export const enum RootClaim {
  Admin = 'Admin',
  SuperAdmin = 'Super Admin',
  All = 'All',
  Manager = 'Manager',
  WaterMeterTechnician = 'Water Meter Technician',
  OperatorTechinician = 'Operator Techinician',
  Account = 'Account',
  Bussiness = 'Bussiness',
  RepairTechnician = 'Repair Technician',
}

export interface RouteObj {
  label: string
  icon?: string;
  rootPermission?: RootClaim[]
  routeProps?: RouteProps
  documentTitle?: string;
  items?: RouteObj[];
  color?: string;
  isRoot?: boolean;
}
export const createBreadcrum = (path: string) => {
  var data: ItemType[] = [];
    if (path === '/') {
      data.push({title: 'Bảng điều khiển'});
    } else {
      var isDone = false;
      for(var i of Routes) {
        if (i.items && i.items.length > 0) {
          for(var child of i.items) {
            if (child.routeProps && path.startsWith(child.routeProps?.path as string)) {
              data.push({title: <a href={(child.routeProps?.path ?? '/') as string} style={{color:"#222222CA"}}>{i.label}</a>});
              data.push({title: <a href={(child.routeProps?.path ?? '/') as string} style={{color:"#000"}}>{child.label}</a>});
              isDone = true;
              break;
            }
            for(var childItem of (child.items ?? [])) {
              if (child.routeProps && path.startsWith(childItem.routeProps?.path as string)) {
                data.push({title: <a href={(child.routeProps?.path ?? '/') as string} style={{color:"#222222CA"}}>{i.label}</a>});
                data.push({title: <a href={(child.routeProps?.path ?? '/') as string} style={{color:"#222222CA"}}>{child.label}</a>})
                data.push({title: <a href={(childItem.routeProps?.path ?? '/') as string} style={{color:"#000"}}>{childItem.label}</a>});
                isDone = true;
                break;
              }
            }
            if (isDone) break;
          }
        }
        if (isDone) break;
      }
    }
    return data;
}

export const Routes: RouteObj[] = [
  {
    label: 'Khách hàng',
    color: '#222222CA',
    isRoot: true
  },
  {
    label: 'Thống kê báo cáo',
    icon: Chart,
    color: '#000000',
    rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager],
    routeProps: {
      path: '/chartreport'
    },
    items: [
      {
        label: 'Tình trạng đo nước',
        routeProps: {
          path: '/chart',
          children: <StatusMeasureWater></StatusMeasureWater>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager],
        color: '#222222CA'
        
      },
      {
        label: 'Lượng nước tiêu thụ',
        routeProps: {
          path: '/luongnuoc',
          children: <AmountOfWWater></AmountOfWWater>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager],
        color: '#222222CA'
      },
      {
        label: 'Doanh thu',
        routeProps: {
          path: '/doanhthu',
          children: <Revenue></Revenue>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager],
        color: '#222222CA'
      },
    ]
  },
  {
    label: 'Khách hàng',
    icon: Users,
    routeProps: {
      path: '/client'
    },
    rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager, RootClaim.WaterMeterTechnician],
    items: [
      {
        label: 'Quản lý yêu cầu phê duyệt vị trí',
        routeProps: {
          path: '/quanlyyeucau',
          children: <PositionRequest></PositionRequest>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin],
        color: '#222222CA'
        
      },
      {
        label: 'Quản lý duyệt biên bản',
        routeProps: {
          path: '/quanlyduyetbienban',
          children: <ReportRequest></ReportRequest>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager],
        color: '#222222CA'
        
      },
      {
        label: 'Quản lý khách hàng',
        routeProps: {
          path: '/quanlyuser',
          children: <UserManager></UserManager>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager, RootClaim.WaterMeterTechnician],
        items: [
          {
            label: 'Chi tiết khách hàng',
            routeProps: {
              path: '/userdetail/',
              children: <UserDetail/>,
            },
            rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager, RootClaim.WaterMeterTechnician],
            color: '#222222CA'
          },
        ],
        color: '#222222CA'
      },
    ]
  },
  {
    label: 'Hố van',
    icon: Brand,
    rootPermission: [RootClaim.All],
    routeProps: {
      path: '/hovan'
    },
    items: [
      {
        label: 'Quản lý hố van',
        routeProps: {
          path: '/hovan',
          children: <WaterVan></WaterVan>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager, RootClaim.OperatorTechinician, RootClaim.RepairTechnician],
        color: '#222222CA'
        
      },
    ]
  },
  {
    label: 'Quản lý nội bộ',
    color: '#222222CA',
    isRoot: true,
  },
  {
    label: 'KTV đo nước',
    icon: User,
    rootPermission: [RootClaim.SuperAdmin, RootClaim.Admin, RootClaim.Manager],
    routeProps: {
      path: '/KTV'
    },
    items: [
      {
        label: 'Quản lý khu vực đo nước',
        routeProps: {
          path: '/khuvucdonuoc',
          children: <MeasureWaterArea></MeasureWaterArea>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Admin, RootClaim.Manager],
        color: '#222222CA'
        
      },
      {
        label: 'Quản lý KTV đo nước',
        routeProps: {
          path: '/ktvdonuoc',
          children: <MeasureWaterPerson></MeasureWaterPerson>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Admin, RootClaim.Manager],
        color: '#222222CA'
        
      },
      
    ]
  },
  {
    label: 'KTV vận hành nhà máy',
    icon: User,
    rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager],
    routeProps: {
      path: '/operator'
    },
    items: [
      {
        label: 'Quản lý ca trực',
        routeProps: {
          path: '/catruc',
          children: <Shift></Shift>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager],
        color: '#222222CA'
        
      },
      {
        label: 'Quản lý yêu cầu đổi ca',
        routeProps: {
          path: '/doicatruc',
          children: <ChangeShiftRequest></ChangeShiftRequest>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager],
        color: '#222222CA'
        
      },

      {
        label: 'Quản lý KTV vận hành',
        routeProps: {
          path: '/vanhanh',
          children: <Operator></Operator>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager],
        color: '#222222CA'
        
      },
      {
        label: 'Báo cáo vận hành',
        routeProps: {
          path: '/reportOperator',
          children: <OperatorReport></OperatorReport>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Account, RootClaim.Admin, RootClaim.Bussiness, RootClaim.Manager],
        color: '#222222CA'
        
      },
      
    ]
  },
  {
    label: 'Quản lý kho',
    icon: User,
    rootPermission: [RootClaim.SuperAdmin, RootClaim.Admin, RootClaim.Manager],
    routeProps: {
      path: '/warehouse'
    },
    items: [
      {
        label: 'Vật tư',
        routeProps: {
          path: '/material',
          children: <MaterialWarehouseScreen></MaterialWarehouseScreen>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Admin, RootClaim.Manager],
        color: '#222222CA'
        
      },
      {
        label: 'Phiếu xuất kho',
        routeProps: {
          path: '/exportBill',
          children: <ExportBillScreen typeBill={2}></ExportBillScreen>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Admin, RootClaim.Manager],
        color: '#222222CA'
        
      },
      {
        label: 'Phiếu nhập kho',
        routeProps: {
          path: '/importBill',
          children: <ExportBillScreen typeBill={1}></ExportBillScreen>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Admin, RootClaim.Manager],
        color: '#222222CA'
        
      },
      {
        label: 'Báo cáo nhập/xuất vật tư',
        routeProps: {
          path: '/ExportBillReport',
          children: <ExportBillReportScreen ></ExportBillReportScreen>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Admin, RootClaim.Manager],
        color: '#222222CA'
        
      },
    ]
  },
  {
    label: 'Quản trị viên',
    icon: User,
    rootPermission: [RootClaim.SuperAdmin, RootClaim.Admin, RootClaim.Manager],
    items: [
      {
        label: 'Quản lý quản trị viên',
        routeProps: {
          path: '/admin',
          children: <Admin></Admin>,
        },
        rootPermission: [RootClaim.SuperAdmin, RootClaim.Admin, RootClaim.Manager],
        color: '#222222CA'
        
      },
      
    ]
  },
]
