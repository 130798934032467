import { Button, Modal, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteZone, getZone, postZone, putZone } from "../../../store/controls/Zone.slice";
import { useSelectorRoot } from "../../../store/store";
import { AreaItem } from "./AreaItem";
import {PlusOutlined} from '@ant-design/icons'

export const MeasureWaterArea = () => {
    const {zoneData,loading} = useSelectorRoot(state => state.zone);
    var [dataSource, setDataSource]= useState<AreaItem[] | undefined>(zoneData?.map(x => ({
        key: x.id.toString(),
        title: x.attributes.name,
        district: x.attributes?.districts?.data?.map(x => ({id: x.id, name: x.attributes.name})),
        province: x.attributes?.cities?.data?.map(x => ({id: x.id, name: x.attributes.name})),
        ward: x.attributes?.wards?.data.map(x => ({id: x.id, name: x.attributes.name})),
        area: x.attributes?.villages?.data.map(x => ({id: x.id, name: x.attributes.name}))
    })))
    const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
    const {isSuccess} = useSelectorRoot(state => state.login);
    const dispatch = useDispatch();
    useEffect(() => {
        setDataSource(zoneData?.map(x => ({
            key: x.id.toString(),
            title: x.attributes.name,
        district: x.attributes?.districts?.data?.map(x => ({id: x.id, name: x.attributes.name})),
        province: x.attributes?.cities?.data?.map(x => ({id: x.id, name: x.attributes.name})),
        ward: x.attributes?.wards?.data?.map(x => ({id: x.id, name: x.attributes.name})),
        area: x.attributes?.villages?.data?.map(x => ({id: x.id, name: x.attributes.name}))
        })))
    }, [zoneData]);
    const [deleteRow, setDeleteRow] = useState<AreaItem>()
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const handleOk = () => {
        if (deleteRow?.key) dispatch(deleteZone(deleteRow.key));
        setIsModalConfirmOpen(false);
    }
    const onDelete = (value: AreaItem) => {
        setDeleteRow(value);
        setIsModalConfirmOpen(true);
    }
    const onUpdate = (value: AreaItem) => {
        var itemUpdate = zoneData?.find(x => x.id == Number(value.key));
        var oldCity = itemUpdate?.attributes.cities.data.map(x => x.id);
        var newCity = value.province?.map(x => x.id)
        var oldDis = itemUpdate?.attributes.districts.data.map(x => x.id);
        var newDis = value.district?.map(x => x.id)
        var oldWard = itemUpdate?.attributes.wards.data.map(x => x.id);
        var newWard = value.ward?.map(x => x.id)
        var oldArea = itemUpdate?.attributes.villages.data.map(x => x.id);
        var newArea = value.area?.map(x => x.id)
        dispatch(putZone({
            id: Number(value.key),
            data: {
                name: value.title ?? "",
                cities: {
                    disconnect: oldCity?.filter(x => !newCity?.some(y => y == x)),
                    connect: newCity?.filter(x => !oldCity?.some(y => y == x))
                },
                districts: {
                    disconnect: oldDis?.filter(x => !newDis?.some(y => y == x)),
                    connect: newDis?.filter(x => !oldDis?.some(y => y == x))
                },
                wards: {
                    disconnect: oldWard?.filter(x => !newWard?.some(y => y == x)),
                    connect: newWard?.filter(x => !oldWard?.some(y => y == x))
                },
                villages: {
                    disconnect: oldArea?.filter(x => !newArea?.some(y => y == x)),
                    connect: newArea?.filter(x => !oldArea?.some(y => y == x))
                }
            }
        }))
    }
    return (
        <view style={{width: '100%', height: 'calc(100vh - 200px)' }}>
        <Spin spinning={loading}>
        <Row style={{justifyContent: "right", marginBottom: 20, flex: 1}}>
                <Button type="primary" onClick={() => {showModal()}}>
                    <Row>
                      {<PlusOutlined></PlusOutlined>}
                      {'Thêm khu vực'}
                    </Row>
                </Button>
            </Row>
            <view style={{width: '100%', overflowY: 'scroll', height: 'calc(100vh - 300px)'}}>
            
            { dataSource?.map(x => <AreaItem items={x} onUpdate={onUpdate} onDelete={onDelete}></AreaItem>)}
        </view>
        <Modal title={<></>} open={isModalOpen} onCancel={handleCancel}  footer={[]}  closeIcon={<></>} width={"100vh"}>
            <AreaItem items={null} onUpdate={(value: AreaItem) => {
                dispatch(postZone({
                    data: {
                        name: value.title ?? "",
                        cities: {
                            connect: value.province?.map(x => x.id)
                        },
                        districts: {
                            connect: value.district?.map(x => x.id)
                        },
                        wards: {
                            connect: value.ward?.map(x => x.id)
                        },
                        villages: {
                            connect: value.area?.map(x => x.id)
                        }
                    }
                }))
                handleCancel();
            }}></AreaItem>
       </Modal>
       <Modal title="Xóa khu vực" open={isModalConfirmOpen} onOk={handleOk} onCancel={() => {setIsModalConfirmOpen(false)}}>
        <p>Bạn có chắc muốn xóa {deleteRow?.title}</p>
      </Modal>
       </Spin>
        </view>
        );
}