import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import OperatorAPI, { OperatorCreateRequest, OperatorResponse, OperatorUpdateRequest, ReportReponse } from "../../api/Operator.api";
import { Pagination, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface OperatorState {
    loading: boolean;
    operatorData: OperatorResponse| null
    createOperatorData: OperatorResponse| null
    updateOperatorData: OperatorResponse| null
    errorMessage: string | null;
    pagination: Pagination | null;
    report: ReportReponse| null;
}

const initState: OperatorState = {
    loading: false,
    operatorData: null,
    errorMessage: null,
    createOperatorData: null,
    updateOperatorData: null,
    pagination: null,
    report: null
};
const operatorSlice = createSlice({
    name: 'operator',
    initialState: initState,
    reducers: {
        getOperator(state, action: PayloadAction<{year: number, month: number}>) {
            state.loading = true;
        },
        getOperatorSuccess(state, action: PayloadAction<OperatorResponse>) {
            state.loading = false;
            state.operatorData = action.payload;
            state.pagination = action.payload.meta.pagination;
        },
        getOperatorReport(state, action: PayloadAction<{year: number, month: number, day: number}>) {
            state.loading = true;
        },
        getOperatorReportSuccess(state, action: PayloadAction<ReportReponse>) {
            state.loading = false;
            state.report = action.payload;
        },
        createOperator(state, action: PayloadAction<OperatorCreateRequest>) {
            state.loading = true;
        },
        createOperatorSuccess(state, action: PayloadAction<OperatorResponse>) {
            state.loading = false;
            state.createOperatorData = action.payload;
        },
        updateOperator(state, action: PayloadAction<OperatorUpdateRequest>) {
            state.loading = true;
        },
        updateOperatorSuccess(state, action: PayloadAction<OperatorResponse>) {
            state.loading = false;
            state.updateOperatorData = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const getOperatorReport$: RootEpic = (action$) =>
    action$.pipe(
        filter(getOperatorReport.match),
        switchMap((re) => {
            return OperatorAPI.getReport(re.payload.month, re.payload.year, re.payload.day).pipe(
                mergeMap((res: any) => {
                    return [
                        operatorSlice.actions.getOperatorReportSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    operatorSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const operator$: RootEpic = (action$) =>
    action$.pipe(
        filter(getOperator.match),
        switchMap((re) => {
            return OperatorAPI.get(re.payload.month, re.payload.year).pipe(
                mergeMap((res: any) => {
                    return [
                        operatorSlice.actions.getOperatorSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    operatorSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const createOperator$: RootEpic = (action$) =>
    action$.pipe(
        filter(createOperator.match),
        switchMap((re) => {
            return OperatorAPI.create(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        operatorSlice.actions.createOperatorSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    operatorSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const updateOperator$: RootEpic = (action$) =>
    action$.pipe(
        filter(updateOperator.match),
        switchMap((re) => {
            return OperatorAPI.update(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        operatorSlice.actions.updateOperatorSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    operatorSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
 
export const OperatorEpics = [operator$, updateOperator$, createOperator$, getOperatorReport$];
export const {
    getOperator,
    getOperatorSuccess,
    getOperatorReport,
    getOperatorReportSuccess,
    createOperator,
    createOperatorSuccess,
    updateOperator,
    updateOperatorSuccess
} = operatorSlice.actions;
export const operatorReducer = operatorSlice.reducer;