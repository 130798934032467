import { Button, Card, Row, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const {Text} = Typography;
export const NotAllowed = () => {
    const history = useHistory();
  const navigateTo = () => history.push('/');
    return (
    <Card style={{width: "100%"}} 
    title={
        <>
        <Row style={{margin: '20px 8px 16px 0px'}}>
        <Text style={{fontSize: 15, fontWeight: 600}}>{'Bạn không có quyền try cập trang này'}</Text>
        </Row>
       
        </>
    }>
        <Button
                    type="primary"
                    style={{
                        backgroundColor: "#005BA5"
                    }}
                    onClick={ () => {
                        navigateTo();
                    }}
                    >
                        <ArrowLeftOutlined />
                      
                      {"Quay về bảng điều khiển"}
                      
                </Button>
    </Card>);
};
