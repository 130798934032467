import { Button, Card, Col, Input, InputNumber, MenuProps, Modal, Pagination, Row, Spin, Table, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MaterialWarehouse } from "../../../api/Warehouse.api";
import { DropdownComponent } from "../../../components/Dropdown/DropdownComponent";
import { getMaterialWarehouse, getWarehouse } from "../../../store/controls/Warehouse.slice";
import { useSelectorRoot } from "../../../store/store";
const { Text } = Typography;
const { Search } = Input;
export const MaterialWarehouseScreen = () => {
    const dispatch = useDispatch();
  
  const { WarehouseData, MaterialWarehouseData, loading } = useSelectorRoot((state) => state.warehouse);
  const [load, setLoad] = useState(loading);
  const [length, setLength] = useState(MaterialWarehouseData?.meta?.pagination.total?? 10);
  const [searchText, setSearchText] = useState<string>();
  const [size, setSize] = useState(MaterialWarehouseData?.meta?.pagination.pageSize ?? 10);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<MaterialWarehouse[]>([]);
  const [selectedHouse, setSelectedHouse] = useState<number>(1);
  const [houses, setHouses]  = useState<MenuProps["items"]>([]);
  const columns: ColumnsType<MaterialWarehouse> = [
    {
      title: "Mã vật tư",
      dataIndex: "materialCode",
      key: "materialCode",
      render: (text, item) => (
        <>
          <div>
            <Text style={{ color: "#2222225A" }}>{item.material.data.attributes?.materialCode}</Text>
          </div>
        </>
      ),
    },
    {
      title: "Tên vật tư",
      dataIndex: "materialName",
      key: "materialName",
      render: (text, item) => (
        <>
          <Text style={{ color: "#2222225A" }}>{item.material.data.attributes?.materialName}</Text>
        </>
      ),
    },
    {
      title: "Đơn vị tính",
      dataIndex: "unit",
      key: "unit",
      render: (text, item) => (
        <>
          <Text style={{ color: "#2222225A" }}>{item.material.data.attributes?.unit}</Text>
        </>
      ),
    },
    {
      title: "Giá vật tư",
      dataIndex: "materialPrice",
      key: "materialPrice",
      render: (text, item) => (
        <>
          <Text style={{ color: "#2222225A" }}>{item.material.data.attributes?.materialPrice}</Text>
        </>
      ),
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, item) => (
        <>
          <Text style={{ color: "#2222225A" }}>{item.quantity}</Text>
        </>
      ),
    }
  ];

  const onSearch = (value: string) => {
    setSearchText(value);
    dispatch(
      getMaterialWarehouse({
        page: currentPage,
        size: size,
        search: value,

      })
    );
  };
  useEffect(() => {
    setLoad(loading);
  }, [loading]);
  useEffect(() => {
    dispatch(
        getMaterialWarehouse({
          page: currentPage,
          size: size,
          search: searchText,
          warehouseId: selectedHouse
        })
      );
      dispatch(
        getWarehouse()
      );
  }, []);

  useEffect(() => {
    setHouses(WarehouseData?.data.map((x) => ({
        key: "" + x.id,
        label: "" + x.attributes?.wareHouseName,
      })) ?? []);
  }, [WarehouseData]);
  
  useEffect(() => {
    setLength(MaterialWarehouseData?.meta.pagination?.total ?? 10);
    setSize(MaterialWarehouseData?.meta.pagination?.pageSize ?? 10);
    setData(MaterialWarehouseData?.data.map(x => x.attributes) ?? []);
  }, [MaterialWarehouseData]);
  
  
  return (
    <Content style={{ padding: "0px 0px 0px 15px" }}>
      <Spin spinning={load}>
        
        <Card
          title={
            <>
              <Row style={{ margin: "20px 8px 16px 0px" }}>
                <Text style={{ fontSize: 22, fontWeight: 600 }}>
                  {"Có " + length + " vật tư"}
                </Text>
                
              </Row>
            </>
          }
        >
          <Row align={"middle"}>
            <Search
              placeholder="Nhập từ khóa tìm kiếm"
              allowClear
              size="large"
              onSearch={onSearch}
              style={{ flex: 1, paddingRight: "16px" }}
            />
            <Text style={{marginRight: 10}}>Kho vật tư:  </Text>
            <DropdownComponent
                  items={{
                    items: houses,
                    onClick: (value) => {
                      setSelectedHouse(Number(value.key));
                      dispatch(
                        getMaterialWarehouse({
                          page: currentPage,
                          size: size,
                          search: searchText,
                          warehouseId: Number(value.key)
                        })
                      );
                    },
                  }}
                  label={"Chọn kho"}
                  value={WarehouseData?.data.find(x => x?.id == selectedHouse)?.attributes?.wareHouseName ?? 'Tất cả'}
                  flex={1}
                ></DropdownComponent>
          </Row>
          <Row
            style={{ paddingTop: "20px", minHeight: "55vh", paddingBottom: 20 }}
          >
            <Table
              scroll={{ x: "60vh", y: "50vh" }}
              style={{ flex: 1 }}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </Row>
          <Row style={{ justifyContent: "flex-end", flex: 1 }}>
            <Pagination
              defaultCurrent={1}
              total={length}
              pageSize={size}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setSize(pageSize);
                dispatch(
                    getMaterialWarehouse({
                      page: page,
                      size: pageSize,
                      search: searchText,
                      warehouseId: selectedHouse
                    })
                  );
              }}
              style={{ justifyContent: "flex-end" }}
            ></Pagination>
          </Row>
        </Card>
      </Spin>
     
    </Content>
  );
}