import {combineReducers} from '@reduxjs/toolkit';
import {combineEpics} from 'redux-observable';
import { BillEpics, billReducer } from './controls/Bill.slice';
import {BootstrapEpics, bootstrapReducer} from './controls/bootstrap.slice';
import { ClientEpics, clientReducer } from './controls/Client.slice';
import { LocationEpics, locationReducer } from './controls/location.slice';
import { LocationApproveEpics, locationApproveReducer } from './controls/LocationApprove.slice';
import {LoginEpics, loginReducer} from './controls/LoginEpic';
import { OperatorEpics, operatorReducer } from './controls/Operator.slice';
import { OverviewEpics, overviewReducer } from './controls/Overview.slice';
import { ScheduleEpics, scheduleReducer } from './controls/Schedule.slice';
import { UserEpics, userReducer } from './controls/User.slice';
import { VanPitEpics, vanPitReducer } from './controls/VanPit.slice';
import { ZoneEpics, zoneReducer } from './controls/Zone.slice';
import { ControlEpics, controlReducer } from './slice/controlSlice';
import { reportEpics, reportReducer } from './controls/Report.slice';
import { InvoicesEpics, invoiceReducer } from './controls/Invoices';
import { WarehouseEpics, WarehouseReducer } from './controls/Warehouse.slice';
import { ExportBillEpics, ExportBillReducer } from './controls/ExportBill.slice';

const rootReducer = combineReducers({
    login: loginReducer,
    bootstrap: bootstrapReducer,
    control: controlReducer,
    overview: overviewReducer,
    location: locationReducer,
    zone: zoneReducer,
    bill: billReducer,
    client: clientReducer,
    operator: operatorReducer,
    schedule: scheduleReducer,
    valPit: vanPitReducer,
    user: userReducer,
    locationApproved: locationApproveReducer,
    report: reportReducer,
    invoice: invoiceReducer,
    warehouse: WarehouseReducer,
    exportBill: ExportBillReducer
});

export const rootEpic = combineEpics(
    ...LoginEpics, 
    ...BootstrapEpics, 
    ...ControlEpics, 
    ...OverviewEpics, 
    ...LocationEpics, 
    ...ZoneEpics, 
    ...BillEpics,
    ...OperatorEpics, 
    ...ScheduleEpics,
    ...VanPitEpics,
    ...UserEpics,
    ...LocationApproveEpics,
    ...reportEpics,
    ...InvoicesEpics,
    ...WarehouseEpics,
    ...ExportBillEpics,
    ...ClientEpics);
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
