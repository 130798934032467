import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Pagination } from "../common/define-types";
import { Area } from "./location.api";
import { Contract } from "./Client.api";
export interface ValvePit {
    id?: string;
    name?: string,
    status?: number,
    createdAt?: Date,
    updatedAt?: Date,
    longitude?: number,
    latitude?: number,
    address?: string,
    city?: {
        data?: Area
    },
    district?: {
        data?: Area
    },
    ward?: {
        data?: Area
    },
    village?: {
        data?: Area
    },
    image?: {
        data: Contract
    }
}
export interface VanPitResponse{
    data: {
        id: number,
        attributes: ValvePit
    }[],
    meta: {
        pagination: Pagination
    }
}
export interface ValPitRequest {
    id?: string;
    data: {
        name?: string,
        city?: {
            disconnect?: number[],
            connect?: number[]
        },
        district?: {
            disconnect?: number[],
            connect?: number[]
        },
        ward?: {
            disconnect?: number[],
            connect?: number[]
        },
        village?: {
            disconnect?: number[],
            connect?: number[]
        },
        image?: string,
        address?: string,
        status?: string,
        longitude?: number,
        latitude?: number
    }
}
export interface queryValPit {
        size: number, 
        page: number,
        village?: string,
        province?: string, 
        ward?: string,
        district?: string,
        status?: string,
        search?: string
}
export default class VanPitAPI {
    static host = SYSTEM_CONSTANTS.HOST;
    static get(
        data: queryValPit
    ): Observable<VanPitResponse | null> {
        var api = `${VanPitAPI.host}/${SYSTEM_CONSTANTS.VAN_PIT.GET}?populate=city,district,ward,village,image&populate=image&pagination[pageSize]=${data.size ?? 10}&pagination[page]=${data.page}`;
        if(data.district && data.district?.length > 0 && data.district != "-1" ) {
            api = api + '&filters[district][id][$eq]=' +  data.district;
        }
        if( data.province && data.province.length > 0 && data.province != "-1") {
            api = api + '&filters[city][id][$eq]=' +  data.province;
        }
        if(data.village && data.village.length > 0 && data.village != "-1") {
            api = api + '&filters[village][id][$eq]=' +  data.village;
        }
        if(data.ward && data.ward.length > 0 && data.ward != "-1") {
            api = api + '&filters[ward][id][$eq]=' +  data.ward;
        }

        if(data.status && data.status.length > 0 && data.status != "-1") {
            api = api + '&filters[status][$eq]=' +  data.status;
        }
        if(data.search ) {
            api = api + '&filters[name][$contains]=' +  data.search;
        }
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as VanPitResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static post(
        data: ValPitRequest
    ): Observable<VanPitResponse | null> {
        const api = `${VanPitAPI.host}/${SYSTEM_CONSTANTS.VAN_PIT.POST}`;
        return HttpClient.post(api, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as VanPitResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static put(
        data: ValPitRequest
    ): Observable<VanPitResponse | null> {
        const api = `${VanPitAPI.host}/${SYSTEM_CONSTANTS.VAN_PIT.POST}/` + data.id;
        return HttpClient.put(api, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as VanPitResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static delete(
        id: string
    ): Observable<VanPitResponse | null> {
        const api = `${VanPitAPI.host}/${SYSTEM_CONSTANTS.VAN_PIT.POST}/` + id;
        return HttpClient.delete(api, null, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as VanPitResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}