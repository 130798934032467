import { Button, Card, Checkbox, Col, Input, Modal, notification, Pagination, Row, Table, Image, Typography, Spin } from "antd"
import { Content } from "antd/es/layout/layout"
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { User } from "../../../common/define-types";
import exportSvg from "../../../image/icon/export.svg";
import { NewUserPopup } from "./NewUserPopup";
import {PlusOutlined, FormOutlined, DeleteOutlined} from '@ant-design/icons'
import { ColumnsType } from "antd/es/table";
import { useHistory, useLocation } from "react-router-dom";
import { useSelectorRoot } from "../../../store/store";
import ClientAPI, { Client, ClientBill, UpdateBillRequest } from "../../../api/Client.api";
import { getBillClient, getClient, getDetail, updateBillClient, updateClient } from "../../../store/controls/Client.slice";
import { createBreadcrum } from "../../Routes";
import { useDispatch } from "react-redux";
import { pushBreadcrum } from "../../../store/slice/controlSlice";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import Utils from '../../../common/Utils';
import SYSTEM_CONSTANTS from "../../../common/constants";
const {Text} = Typography;
const { Search } = Input;
var billExport :ClientBill[] = [];
export const UserDetail = () => {
  const headers = [
    { label: "Tháng", key: "month" },
    { label: "Năm", key: "year" },
    { label: "Số đầu kỳ", key: "startingQuantity" },
    { label: "Số cuối kỳ", key: "endingQuantity" },
    { label: "Tiêu thụ", key: "usage" },
    { label: "Tổng tiền", key: "totalMoney" },
    { label: "Đã thanh toán", key: 'paidMoney'},
    { label: "Dư nợ", key: 'deptMoney'},
    { label: "Chú ý", key: 'note'},
    { label: "Ngày tạo", key: 'createdAt'}
  ];
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [Id, setId] = useState('');
  const {detailClient, clientBill, paginationBill, updateError, loading} = useSelectorRoot(state => state.client);
    const {isSuccess} = useSelectorRoot(state => state.login);
    const [user, setUser] = useState<Client>();

    const [size, setSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [data, setData] = useState<ClientBill[]>(clientBill?.data?.map(x => ({...x.attributes, id: x.id})) ?? []);
    const [length, setLength] = useState(paginationBill?.total ?? 10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [exportBill, setExportBill] = useState<ClientBill[]>([]);
    const [load, setLoad] = useState(loading);
  useEffect(() => {
    setLoad(loading);
  }, [loading])
    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    useEffect(() => {
      var path = location.pathname.split('/userdetail/');
      
      var id = path[path.length - 1];
      if (id.length <= 0) {
        var data = createBreadcrum('/quanlyuser')
        dispatch(pushBreadcrum(data));
        history.push('/quanlyuser');
      } else {
        if (!detailClient || detailClient.data){
          dispatch(
          getClient(
            { 
              page: 1, 
              size: 100
            }
          ));
          dispatch(getDetail({id: Number(id)}));
        } else {
          var dataClient = detailClient?.data as {
            id?: number,
            attributes?: Client;
        };
          if (dataClient != null)
            setUser({
              ...dataClient.attributes, 
              id: dataClient?.id?.toString()
            });
          else {
            var data = createBreadcrum('/quanlyuser')
        dispatch(pushBreadcrum(data));
        history.push('/quanlyuser');
          }
        }
        dispatch(getBillClient({id: id, query: {
          page: currentPage, 
          size: size
        }}))
        setId(id);
        
      }
    }, [isSuccess]);
    useEffect(() => {
      var data = detailClient?.data;
      if (data != null)
      setUser({
        ...data?.attributes, 
        id: data?.id?.toString()
      });
      
    }, [detailClient])
    useEffect(() => {
      setData(clientBill?.data?.map(x => ({...x.attributes, id: x.id})) ?? [])
    }, [clientBill]);
    useEffect(() => {
      setCurrentPage(paginationBill?.page ?? 1);
      setSize(paginationBill?.pageSize ?? 10);
      setLength(paginationBill?.total ?? 100);
    }, [paginationBill]);
    useEffect(() => {
      if (updateError && updateError.code != undefined)api['error']({
        message: 'Thông báo',
        description: updateError?.message ?? 'Có sự cố xảy ra trong quá trình cập nhật',
      });
    }, [updateError])
    const [selectRow, setSelectRow] = useState<ClientBill>();
    const columns: ColumnsType<ClientBill> = [
        {
          title: 'THÁNG',
          dataIndex: 'month',
          key: 'month',
          render: (text) => <><Text style={{color: '#2222225A'}}>{text}</Text></>,
        },
        {
          title: 'NĂM',
          dataIndex: 'year',
          key: 'year',
          render: (text) => <><Text style={{color: '#2222225A'}}>{text}</Text></>
        },
        {
          title: 'ĐẦU KỲ',
          dataIndex: 'startingQuantity',
          key: 'startingQuantity',
          render: (text) => <><Text style={{color: '#2222225A'}}>{text}</Text></>
        },
        {
          title: 'CUỐI KỲ',
          dataIndex: 'endingQuantity',
          key: 'endingQuantity',
          render: (text) => <><Text style={{color: '#2222225A'}}>{text}</Text></>
        },
        {
          title: 'TIÊU THỤ',
          dataIndex: 'usage',
          key: 'usage',
          render: (text) => <><Text style={{color: '#2222225A'}}>{text}</Text></>
        },
        {
          title: 'TỔNG TIỀN',
          dataIndex: 'totalMoney',
          key: 'totalMoney',
          render: text => <><Text style={{color: '#2222225A'}}>{Utils.formatVNDCash(String(text))}</Text></>
        },
        {
          title: 'ĐÃ T.TOÁN',
          dataIndex: 'paidMoney',
          key: 'paidMoney',
          render: (text) => <><Text style={{color: '#2222225A'}}>{Utils.formatVNDCash(String(text))}</Text></>
        },
        {
          title: 'CÒN NỢ',
          dataIndex: 'deptMoney',
          key: 'deptMoney',
          render: (text) => <><Text style={{color: '#2222225A'}}>{Utils.formatVNDCash(String(text))}</Text></>
        },
        {
          title: 'HÌNH ẢNH',
          dataIndex: 'waterMeterImage',
          key: 'waterMeterImage',
          render: (text) => text?.data?.attributes?.url ? <Image src={SYSTEM_CONSTANTS.HOST + text?.data?.attributes?.url} height={50} width={50}></Image> : <></>
        },
        {
          title: 'GHI CHÚ',
          dataIndex: 'note',
          key: 'Note',
          render: (text) => <><Text style={{color: '#2222225A'}}>{text}</Text></>
        },
        {
          title: 'Chọn',
          key: '',
          dataIndex: 'key',
          render: (id, item) => (
            <>
              <Button icon={<FormOutlined />} onClick={() => {
                setSelectRow(item);
                setUpdateBillData({
                  startingQuantity: item.startingQuantity,
                  endingQuantity: item.endingQuantity,
                  isPaid: item.isPaid,
                  id: Number(item.id),
                  usage: item.usage,
                  totalMoney: item.totalMoney,
                  deptMoney: item.deptMoney,
                  paidMoney: item.paidMoney,
                  isManualUpdate: false
                })
                showModal();

                }} style={{marginRight: 20}}></Button>
              {/* <Button icon={<DeleteOutlined /> } onClick={() => {}}></Button> */}
            </>
          ),
        },
      ];

      const [updateBillData, setUpdateBillData] = useState<UpdateBillRequest>();
    const onUpdate = () => {
      if(updateBillData && selectRow && selectRow.id != undefined) 
      {
        dispatch(updateBillClient({
          data: updateBillData,
          id: Id,
          billId: updateBillData.id?.toString() ?? '',
          query: {
            page: currentPage,
            size: size
          }
        }))
      }
    }
    return (
        <Content style={{padding: "0px 0px 0px 15px", height: "calc(100vh - 200px)"}} >
          {contextHolder}
          <Spin spinning={load}></Spin>
            <Row>
                <Col xl={8} sm={24} md={24}>
                    <Card style={{marginBottom: 16, marginRight: 24, flex: 1}}>
                        <Text style={{fontSize: 18, fontWeight: 500, color: '#2222225A'}}>{"Tên khách hàng"}</Text>
                        <br/>
                        <Text style={{fontSize: 18, fontWeight: 500, color: '#222222'}}>{user?.name}</Text>
                    </Card>
                </Col>
                
                <Col xl={8} sm={24} md={24}>
                    <Card style={{marginBottom: 16, marginRight: 24, flex: 1}}>
                        <Text style={{fontSize: 18, fontWeight: 500, color: '#2222225A'}}>{"Mã hợp đồng"}</Text>
                        <br/>
                        <Text style={{fontSize: 18, fontWeight: 500, color: '#222222'}}>{user?.code}</Text>
                    </Card>
                </Col>
                <Col xl={8} sm={24} md={24}>
                    <Card style={{marginBottom: 16, marginRight: 24, flex: 1}}>
                        <Text style={{fontSize: 18, fontWeight: 500, color: '#2222225A'}}>{"Số điện thoại"}</Text>
                        <br/>
                        <Text style={{fontSize: 18, fontWeight: 500, color: '#222222'}}>{user?.phone}</Text>
                    </Card>
                </Col>
            </Row>
            <Card>
                <NewUserPopup data={{
                  name: user?.name,
                  codeContract: user?.code,
                  phone: user?.phone,
                  city: user?.city?.data?.id.toString(),
                  district: user?.district?.data?.id.toString(),
                  ward: user?.ward?.data?.id.toString(),
                  area: user?.village?.data?.id.toString(),
                  address: user?.address,
                  priceCatagory: user?.priceCategory?.data?.id.toString(),
                  dateContract: user?.contractDate,
                  waterCode: user?.gaugesCode,
                  line: user?.line?.data?.id.toString(),
                  point: {lng: user?.longitude ?? 0, lat: user?.latitude ?? 0},
                  contract: user?.contract?.data,
                  clientType: user?.clientType,
                  cleaningFee: user?.cleaningFee
                }} maxHeightContent={"100%"} 
                onCreate={(value) => {
                  if (value.data.city && user?.city?.data && user?.city?.data?.id >= 0 ) {
                    var tem = Number(user?.city?.data?.id);
                    if (value.data.city.connect && tem != value.data.city.connect[0]) {
                      value.data.city.disconnect = [tem];
                    }
                  }
                  if (value.data.district && user?.district?.data && user?.district?.data?.id >= 0) {
                    var tem = Number(user?.district?.data?.id);
                    if (value.data.district.connect && tem != value.data.district.connect[0]) {
                      value.data.district.disconnect = [tem];
                    }
                  }
                  if (value.data.ward && user?.ward?.data && user?.ward?.data?.id >= 0) {
                    var tem = Number(user?.ward?.data?.id);
                    if (value.data.ward.connect && tem != value.data.ward.connect[0]) {
                      value.data.ward.disconnect = [tem];
                    }
                  }
                  if (value.data.village && user?.village?.data && user?.village?.data?.id >= 0) {
                    var tem = Number(user?.village?.data?.id);
                    if (value.data.village.connect && tem != value.data.village.connect[0]) {
                      value.data.village.disconnect = [tem];
                    }
                  }
                  if (value.data.line && user?.line?.data && user?.line?.data?.id >= 0) {
                    var tem = Number(user?.line?.data?.id);
                    if (value.data.line.connect && tem != value.data.line.connect[0]) {
                      value.data.line.disconnect = [tem];
                    }
                  }
                  if (value.data.priceCategory && user?.priceCategory?.data && user?.priceCategory?.data?.id >= 0) {
                    var tem = Number(user?.priceCategory?.data?.id);
                    if (value.data.priceCategory.connect && tem != value.data.priceCategory.connect[0]) {
                      value.data.priceCategory.disconnect = [tem];
                    }
                  }
                  value.data.id = user?.id;
                  value.id = user?.id;
                  dispatch(updateClient({data: value, query: {
                    page: paginationBill?.page,
                    size: paginationBill?.pageSize
                  }}))
                }}
                />
                <Row align={'middle'} style={{marginTop: 10}}>
                <Text style={{fontSize: 18, fontWeight: 500}}>{"Tình trạng đo nước"}</Text>
                <div style={{flex: 1}}></div>
                    {/* <DropdownComponent items={{items: listSize, onClick: (value) => {setSize(Number(value.key))}}} label={'Số phần tử'} flex={0.2}></DropdownComponent> */}
                <Button
                    type="primary"
                    style={{marginRight: '10px'}}
                    onClick={() => {
                      var conutPage = paginationBill?.pageCount ?? 1;
                      if (billExport.length < (paginationBill?.total ?? 0)){
                        setLoad(true)
                        ClientAPI.getBill(Id, 
                         {
                            page: 1, 
                            size: 100
                          }
                        ).subscribe(async (json) => {
                          conutPage = json?.meta.pagination.pageCount ?? 1;
                          billExport = json?.data?.map((x) => {
                            return {...x.attributes, id: x.id}
                          }) ?? [];
                          
                          for(var i = 2; i < conutPage + 1; i++) {
                            var data = await ClientAPI.getBill(Id, 
                              {
                                 page: i, 
                                 size: 100
                              }
                             ).toPromise();
                            billExport = billExport.concat(data?.data ?? [])
                            console.log(billExport.length);
                          }
                          setExportBill(billExport);
                          setTimeout(() => {
                            setLoad(false)
                            document.getElementById('exportCXV')?.click()
                          }, 1000)
                          
                        });
                      }
                    }}
                    >
                      <Row>
                      <ReactSVG src={exportSvg} style={{marginRight: 5, width: 18, height: 18}}></ReactSVG>
                      {'Xuất file'}
                      </Row>
                      
                </Button>
                <CSVLink
                style={{display: 'none'}}
                  headers={headers}
                  filename={`Tình trạng đo nước của ${user?.name}.csv`}
                  data={exportBill?.map(x => ({
                    ...x,
                    endingQuantity: x?.endingQuantity && x?.endingQuantity > 0 ? x?.endingQuantity : ''
                  })) ?? []}
                  id="exportCXV"
                />
                {/* <Button
                    type="primary"
                    icon={<PlusOutlined></PlusOutlined>}
                    onClick={() => {}}
                    >
                      
                      {'Thêm số liệu'}
                      
                </Button> */}
            </Row>
            <Row style={{paddingTop: '20px', minHeight: '55vh', paddingBottom: 20}}>
                <Table 
                    scroll={{x: '60vh', y: '50vh'}}
                    style={{flex: 1}}
                    columns={columns} 
                    dataSource={data}
                    pagination={false} 
                    />
            </Row>
            <Row style={{justifyContent: 'flex-end', flex: 1}}>
              {paginationBill?.total ? <Pagination defaultCurrent={1} total={length} pageSize={size} onChange={(page, pageSize) => {
                setCurrentPage(page); 
                setSize(pageSize);
                dispatch(getBillClient({id: Id, query: {
                  page: page, 
                  size: pageSize
                }}))
                }} style={{justifyContent: 'flex-end'}}></Pagination>: null
              }
            </Row>
            </Card>
            <Modal centered title={'Thông tin chốt số'} open={isModalOpen} onCancel={handleCancel} onOk={(value) => {
              if((updateBillData?.endingQuantity == undefined || updateBillData?.startingQuantity == undefined) || (updateBillData?.endingQuantity < updateBillData?.startingQuantity)){
                notification.error({
                  message: 'Cuối kỳ không được thấp hơn đầu ký'
                })
              } else {
                onUpdate();
              handleCancel();
              }
              
            }}   width={"50vh"} closeIcon={<></>}>
              {/* {
                user?.contractDate && selectRow?.month == (new Date(user.contractDate).getMonth() + 1) ? 
                <Row style={{marginBottom: 10}}>
                <Text style={{fontSize: 13, fontWeight: 500, marginBottom: 10}}>{'Số đầu kỳ'}</Text>
                    <Input placeholder={'Nhập số đầu kỳ'} type={"number"} 
                    value={updateBillData?.startingQuantity}
                    min={0}
                    onChange={(value) => {
                      setUpdateBillData({
                        ...updateBillData,
                        startingQuantity: Number(value.target.value)
                      })
                    }}></Input>
                </Row>
                :<></>
              } */}
              <Row style={{marginBottom: 10}}>
                <Text style={{fontSize: 13, fontWeight: 500, marginBottom: 10}}>{'Số đầu kỳ'}</Text>
                    <Input placeholder={'Nhập số đầu kỳ'} type={"number"} 
                    value={updateBillData?.startingQuantity}
                    min={0}
                    onChange={(value) => {
                      setUpdateBillData({
                        ...updateBillData,
                        startingQuantity: Number(value.target.value)
                      })
                    }}></Input>
                </Row>
                
              <Row style={{marginBottom: 10}}>
                <Text style={{fontSize: 13, fontWeight: 500, marginBottom: 10}}>{'Số cuối kỳ'}</Text>
                    <Input placeholder={'Nhập số cuối kỳ'} type={"number"} 
                    value={updateBillData?.endingQuantity}
                    min={updateBillData?.startingQuantity ?? 0}
                    onChange={(value) => {
                      setUpdateBillData({
                        ...updateBillData,
                        endingQuantity: Number(value.target.value)
                      })
                    }}></Input>
              </Row>
              <Row style={{marginBottom: 10}}>
                <Text style={{fontSize: 13, fontWeight: 500, marginBottom: 10}}>{'Tiêu thụ'}</Text>
                    <Input placeholder={'Tiêu thụ'} type={"number"} 
                    value={updateBillData?.usage}
                    min={0}
                    onChange={(value) => {
                      setUpdateBillData({
                        ...updateBillData,
                        usage: Number(value.target.value)
                      })
                    }}></Input>
              </Row>
              <Row style={{marginBottom: 10}}>
                <Text style={{fontSize: 13, fontWeight: 500, marginBottom: 10}}>{'Tổng tiền'}</Text>
                    <Input placeholder={'Tổng tiền'} type={"number"} 
                    value={updateBillData?.totalMoney}
                    min={0}
                    onChange={(value) => {
                      setUpdateBillData({
                        ...updateBillData,
                        totalMoney: Number(value.target.value)
                      })
                    }}></Input>
              </Row>
              <Row style={{marginBottom: 10}}>
                <Text style={{fontSize: 13, fontWeight: 500, marginBottom: 10}}>{'Đã thanh toán'}</Text>
                    <Input placeholder={'Đã thanh toán'} type={"number"} 
                    value={updateBillData?.paidMoney}
                    min={0}
                    onChange={(value) => {
                      setUpdateBillData({
                        ...updateBillData,
                        paidMoney: Number(value.target.value)
                      })
                    }}></Input>
              </Row>
              <Row style={{marginBottom: 10}}>
                <Text style={{fontSize: 13, fontWeight: 500, marginBottom: 10}}>{'Còn nợ'}</Text>
                    <Input placeholder={'Còn nợ'} type={"number"} 
                    value={updateBillData?.deptMoney}
                    min={0}
                    onChange={(value) => {
                      setUpdateBillData({
                        ...updateBillData,
                        deptMoney: Number(value.target.value)
                      })
                    }}></Input>
              </Row>
              <Row>
              <Checkbox checked={updateBillData?.isPaid} onChange={(value) => {
                      setUpdateBillData({
                        ...updateBillData,
                        isPaid: value.target.checked
                      })
                    }} >Đã thanh toán</Checkbox>
              </Row>
              <Row>
              <Checkbox checked={!updateBillData?.isManualUpdate} onChange={(value) => {
                      setUpdateBillData({
                        ...updateBillData,
                        isManualUpdate: value.target.checked ? false : true
                      })
                    }} >Tự động tính toán số liệu</Checkbox>
              </Row>
            </Modal>
        </Content>
    )
}
