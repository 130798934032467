import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import {SaveOutlined} from '@ant-design/icons'
import logoutIcon from '../../image/icon/logout.svg'
import { ReactSVG } from "react-svg";
import { useDispatch } from "react-redux";
import { logout, updateProfile } from "../../store/controls/LoginEpic";
import { useHistory } from "react-router-dom";
import { useSelectorRoot } from "../../store/store";
import { useEffect, useState } from "react";
import { User } from "../../common/define-types";
const {Text} = Typography;
export const Profile = (prop:{open: boolean, onClose?: (value: any) => void}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const {user} = useSelectorRoot(state => state.login);
    const [validForm, setValidForm] = useState(false);
    useEffect(() => {
        form.setFieldsValue(user);
    }, [form, user]);
    const submit = (value: User) => {
        dispatch(updateProfile(value))
        prop.onClose && prop.onClose(value);
    };
    return (
        <Modal title={'Thông tin tài khoản'} 
            centered
            width={'80vh'}
            open={prop.open}
            closable={true}
            footer={<></>} 
            onCancel={() => {prop.onClose && prop.onClose(null)}}
            closeIcon={
                <Row style={{position: 'relative', left: '-240px', minWidth: "300px"}}>
                    <Button 
                    type="primary"
                    danger
                    onClick={(e) => {
                        dispatch(logout());
                        history.replace("/login");
                        history.push("/login");
                    }}
                    style={{marginRight: 10, height: 40}}>
                        <Row style={{height: 40, justifyContent: "center"}}>
                        <ReactSVG src={logoutIcon} width={20} style={{marginRight: 5}}></ReactSVG>
                        <Text style={{color: "white", justifyContent: "center", marginTop: 6}}>{'Đăng xuất'}</Text>
                        </Row>
                        
                    </Button>
                    <Button 
                    type="primary"
                    style={{height: 40}}
                    disabled={!validForm}
                    onClick={(e) => {
                        
                           submit(form.getFieldsValue());
                           form.setFieldValue('rePassword', '');
                    }}
                    icon={<SaveOutlined></SaveOutlined>} >
                        {'Lưu thay đổi'}
                    </Button>
                </Row>
            }
         >
            <Form
            layout="vertical"
            name="profile"
            form={form}
            onFinish={submit}
            
            onValuesChange={(e) => {
                form
                .validateFields()
                .then((values) => {
                    form.resetFields();
                    setValidForm(true);
                })
                .catch((info) => {
                    setValidForm(info.errorFields?.length <= 0);
                });
            }}
            requiredMark={false}
            >
            <Row>
                <Col span={12} style={{padding: 12}}>
                    <Form.Item
                    label="Họ và tên"
                    name="fullName"
                    rules={[
                        { required: true, message: 'Không được để trống' },
                    ]}
                    >
                        <Input placeholder='Nhập họ và tên' type="text" value={user?.fullName}/>
                    </Form.Item>
                </Col>
                <Col span={12} style={{padding: 12}}>
                    <Form.Item
                    label="Tên đăng nhập"
                    name="username"
                    rules={[
                        { required: true, message: 'Không được để trống' },
                    ]}
                    >
                        <Input placeholder='Nhập tên đăng nhập' type="text" value={user?.username}/>
                    </Form.Item>
                </Col>
                
            </Row>
            <Row>
                <Col span={12} style={{padding: 12}}>
                    <Form.Item
                    label="Địa chỉ email"
                    name="email"
                    rules={[
                        { required: true, message: 'Không được để trống' },
                    ]}
                    >
                        <Input placeholder='Nhập địa chỉ email' type="email" value={user?.email}/>
                    </Form.Item>
                </Col>
                <Col span={12} style={{padding: 12}}>
                    <Form.Item
                    label="Số điện thoại"
                    name="phone"
                    rules={[
                        { required: true, message: 'Không được để trống' },
                    ]}
                    >
                        <Input placeholder='Nhập số điện thoại' type="number" value={user?.phone}/>
                    </Form.Item>
                </Col>
                
            </Row>
            <Row>
                <Col span={12} style={{padding: 12}}>
                    <Form.Item
                    label="Mật khẩu mới"
                    name="password"
                    // rules={[
                    //     { required: true, message: 'Không được để trống' },
                    // ]}
                    >
                        <Input.Password placeholder='Nhập mật khẩu mới'/>
                    </Form.Item>
                </Col>
                <Col span={12} style={{padding: 12}}>
                    <Form.Item
                    label="Nhập lại mật khẩu mới"
                    name="rePassword"
                    rules={[
                        // { required: true, message: 'Không được để trống' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Không khớp mật khẩu'));
                            },
                          }),
                    ]}
                    >
                        <Input.Password placeholder='Nhập lại mật khẩu mới'/>
                       
                    </Form.Item>
                </Col>
                
            </Row>
            </Form>
        </Modal>
    );
}