import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import VanPitAPI, { queryValPit, ValPitRequest, VanPitResponse } from "../../api/VanPit.api";
import { Pagination, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface VanPitState {
    loading: boolean;
    vanPitData: VanPitResponse| null
    errorMessage: string | null;
    pagination: Pagination | null;
}

const initState: VanPitState = {
    loading: false,
    vanPitData: null,
    errorMessage: null,
    pagination: null
};
const vanPitSlice = createSlice({
    name: 'vanPit',
    initialState: initState,
    reducers: {
        getVanPit(state, action: PayloadAction<{
            size: number, 
            page: number,
            village?: string,
            province?: string, 
            ward?: string,
            district?: string,
            status?: string,
            search?: string}>) {
            state.loading = true;
        },
        getVanPitSuccess(state, action: PayloadAction<VanPitResponse>) {
            state.loading = false;
            state.vanPitData = action.payload;
            state.pagination = action.payload.meta.pagination;
        },
        createVanPit(state, action: PayloadAction<{data: ValPitRequest, query: queryValPit}>) {
            state.loading = true;
        },
        updateVanPit(state, action: PayloadAction<{data: ValPitRequest, query: queryValPit}>) {
            state.loading = true;
        },
        deleteVanPit(state, action: PayloadAction<{id: string, query: queryValPit}>) {
            state.loading = true;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const vanPit$: RootEpic = (action$) =>
    action$.pipe(
        filter(getVanPit.match),
        switchMap((re) => {
            return VanPitAPI.get(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        vanPitSlice.actions.getVanPitSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    vanPitSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const createVanPit$: RootEpic = (action$) =>
    action$.pipe(
        filter(createVanPit.match),
        switchMap((re) => {
            return VanPitAPI.post(re.payload.data).pipe(
                mergeMap((res: any) => {
                    return VanPitAPI.get(re.payload.query).pipe(
                        mergeMap((res: any) => {
                            return [
                                vanPitSlice.actions.getVanPitSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            vanPitSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    );
                }),
                catchError((e: AjaxError) => [
                    vanPitSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const updateVanPit$: RootEpic = (action$) =>
    action$.pipe(
        filter(updateVanPit.match),
        switchMap((re) => {
            return VanPitAPI.put(re.payload.data).pipe(
                mergeMap((res: any) => {
                    return VanPitAPI.get(re.payload.query).pipe(
                        mergeMap((res: any) => {
                            return [
                                vanPitSlice.actions.getVanPitSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            vanPitSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    );
                }),
                catchError((e: AjaxError) => [
                    vanPitSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const deleteVanPit$: RootEpic = (action$) =>
    action$.pipe(
        filter(deleteVanPit.match),
        switchMap((re) => {
            return VanPitAPI.delete(re.payload.id).pipe(
                mergeMap((res: any) => {
                    return VanPitAPI.get(re.payload.query).pipe(
                        mergeMap((res: any) => {
                            return [
                                vanPitSlice.actions.getVanPitSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            vanPitSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    );
                }),
                catchError((e: AjaxError) => [
                    vanPitSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const VanPitEpics = [vanPit$, createVanPit$, updateVanPit$, deleteVanPit$];
export const {
    getVanPit,
    getVanPitSuccess,
    createVanPit,
    updateVanPit,
    deleteVanPit
} = vanPitSlice.actions;
export const vanPitReducer = vanPitSlice.reducer;