import { Row, Col, Input, Button, Typography, MenuProps, notification, AutoComplete } from "antd";
import { useState, useEffect } from "react";
import { MapContainer, useMapEvents, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { ReactSVG } from "react-svg";
import exportSvg from "../../../image/icon/export.svg";

import {EyeOutlined, UploadOutlined} from '@ant-design/icons'
import { LatLng, LeafletMouseEvent, LocationEvent } from "leaflet";
import { DropdownComponent, ItemTypeProp } from "../../../components/Dropdown/DropdownComponent";
import { FilterItem } from "../../../common/define-types";
import { useSelectorRoot } from "../../../store/store";
import { useDispatch } from "react-redux";
import { Contract } from "../../../api/Client.api";
import SYSTEM_CONSTANTS from "../../../common/constants";
import HttpClient from "../../../api/http-client";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
const {Text} = Typography;
export interface WaterVanData {
    id?: string;
    name?: string;
    city?: string;
    district?: string;
    ward?: string;
    area?: string;
    address?: string;
    waterCode?: string;
    image?: Contract;
    point?: {lng: number, lat: number}
    status?: string;
}


const items = [
    {
        label: 'Hoạt động',
        key: '2',
        color: 'green'
      },
      {
        label: 'Đang sửa chữa',
        key: '1',
        color: 'volcano'
      },
  ];

  var isSearching = false;
export const AddWaterVan =(props: {data?: WaterVanData | null, onAdd?: (value: WaterVanData) => void}) => {
    
    const {district, area, province, ward} = useSelectorRoot(state => state.location);

    const [listArea, setListArea] = useState<{label: string, key: string}[]>([]);
    const [listProvince, setListProvince] = useState<{label: string, key: string}[]>([]);
    const [listDistrict, setListDistrict] = useState<{label: string, key: string}[]>([]);
    const [listWard, setListWard] = useState<{label: string, key: string}[]>([]);
    useEffect(() => {
        setListDistrict(district?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
      }, [district]);
      useEffect(() => {
        setListProvince(province?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
      }, [province]);
      useEffect(() => {
        setListArea(area?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
      }, [area]);
      useEffect(() => {
        setListWard(ward?.map(x => ({label: x.attributes.name, key: x.id.toString()})) ?? []);
      }, [ward]);

      const [valPit, setValPit] = useState<WaterVanData>(props.data ?? {});
      const [selectDistrict, setSelectDistrict] = useState<string | undefined>(listDistrict.find(x => x.key == props.data?.district)?.label);
      const [selectedWard, setSelectedWard] = useState<string | undefined>(listWard.find(x => x.key == props.data?.ward)?.label);
      const [selectedProvince, setSelectedProvince] = useState<string | undefined>(listProvince.find(x => x.key == props.data?.city)?.label);
      const [selectedArea, setSelectedArea] = useState<string | undefined>(listArea.find(x => x.key == props.data?.area)?.label);
      const [selectedStatus, setSelectedStatus] = useState<string | undefined>(items.find(x => x.key == props.data?.status)?.label);
      useEffect(() => {
        setValPit(props.data ?? {});
        setSelectDistrict(listDistrict.find(x => x.key == props.data?.district)?.label);
        setSelectedWard(listWard.find(x => x.key == props.data?.ward)?.label);
        setSelectedProvince(listProvince.find(x => x.key == props.data?.city)?.label);
        setSelectedArea(listArea.find(x => x.key == props.data?.area)?.label);
        setSelectedStatus(items.find(x => x.key == props.data?.status)?.label);
      }, [props.data]);
      const [api, contextHolder] = notification.useNotification();
      const changeHandler = (event: any) => {
        var file = event.target.files[0] as File;
        if (!file) {
            return;
        }
        if (file.size > 3 * 1024 * 1024) {
            api['error']({
                message: 'Tải tệp tin lỗi',
                description: "Không thể tải tệp tin lớn hơn 10MB",
              });
            return;
        }
        if (![".jpeg",'.jpg','.png','.heif',".heic"].includes(file.name.substring(file.name.lastIndexOf('.')).toLocaleLowerCase())) {
            api['error']({
                message: 'Tải tệp tin lỗi',
                description: "Không thể tải tệp tin khác định dạng .jpeg,.jpg,.png,.heif,.heic",
              });
            return;
        }
        const formData = new FormData();

		formData.append('files', file);
        formData.append('fileInfo', `{\"name\": \"${file.name}\"}`)
        HttpClient.upload(SYSTEM_CONSTANTS.HOST + '/api/upload', formData).subscribe(
            (fileInfo: any) => {
                setValPit({
                    ...valPit,
                    image: {
                       id: fileInfo[0].id,
                       attributes: fileInfo[0]
                    }
                });
            }
        );
		
	};

    const [searchText, setSearchText] = useState<string| undefined>();
    const [resultSearch, setResultSearch] = useState<SearchResult<any>[]>([]);
    const onSearch = (value: string) => {
        const provider = new OpenStreetMapProvider();
        setSearchText(value);
        if (isSearching) return;
        isSearching = true;
        provider.search({ query: value }).then((result) => {
            isSearching = false;
            setResultSearch(result.map(x => ({
                ...x,
                value: x.label
            })));
        });
    }

    const onSelect = (value: string, item: SearchResult<any>) => {
        setSearchText(value);
        setValPit({
            ...valPit,
            point: {
                lat: item.y,
                lng: item.x
            }
        });
    }
  return (
    <div style={{width: "100%", maxHeight: "75vh"}}>
        {contextHolder}
        <Row style={{justifyContent: 'center', alignItems: 'center', marginBottom: 16, marginRight: 26}}>
                <Col sm={24} xl={12} md={12}>
                    <Text style={{fontSize: 20, fontWeight: 500, color: '#222222'}}>{valPit.name ?? "Thông tin hố van"}</Text>
                    
                </Col>
                <div style={{flex: 1}}></div>
                
                <Button type="primary" onClick={() => {
                    props.onAdd && props.onAdd(valPit);
                    }}>
                    <Row>
                      <ReactSVG src={exportSvg} style={{marginRight: 5, width: 18, height: 18}}></ReactSVG>
                      {props.data != null ? 'Lưu thay đổi' : "Thêm mới"}
                    </Row>
                </Button>
            </Row>
        <div style={{width: "100%", maxHeight: "calc(75vh - 50px)", overflowY: "auto"}}>
        <Row>
            <Col xl={12} md={24} sm={24} style={{ marginBottom: 16}}>
            <Row style={{paddingRight: 26, marginBottom: 16}}>
                       <Text style={{fontSize: 13, fontWeight: 500}}>{"Tên hố van"}</Text>
                        <Input placeholder={"Nhập tên hố van"} 
                        value={valPit.name}
                        onChange={(text) => {
                            setValPit({ 
                                    ...valPit, 
                                    name: text.target.value
                                });
                                
                        }}></Input>
                    </Row>
                <Row>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Tỉnh/thành phố'}</Text>
                        <DropdownComponent label={'Tỉnh/thành phố'} 
                        value={selectedProvince}
                        items={
                            {items: listProvince, 
                                onClick: 
                                (text) => {
                                    setSelectedProvince(text.label as string);
                                    setValPit({ 
                                    ...valPit, 
                                    ...{city: text.key}
                                });},
                            }
                        }></DropdownComponent>
                    </Col>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Huyện'}</Text>
                        <DropdownComponent label={'Huyện'} 
                        value={selectDistrict}
                        items={
                            {items: listDistrict, 
                                onClick: 
                                (text) => {
                                    setSelectDistrict(text.label as string);
                                    setValPit({ 
                                    ...valPit, 
                                    ...{district: text.key}
                                });},
                            }
                        }></DropdownComponent>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Xã/phường/thị trấn'}</Text>
                        <DropdownComponent label={'Xã/phường/thị trấn'} 
                        value={selectedWard}
                        items={
                             {
                                items: listWard,
                                onClick: (text) => {
                                    setSelectedWard(text.label as string);
                                    setValPit({ 
                                    ...valPit, 
                                    ...{ward: text.key}
                                });},
                            }
                        }></DropdownComponent>
                    </Col>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Thôn/khu phố'}</Text>
                        <DropdownComponent label={'Thôn/khu phố'} 
                        value={selectedArea}
                        items={
                            {
                                items: listArea,
                                onClick: (text) => {
                                    setSelectedArea(text.label as string);
                                    setValPit({ 
                                    ...valPit, 
                                    ...{area: text.key}
                                });},
                            }
                        }></DropdownComponent>
                    </Col>
                </Row>
                <Row style={{paddingRight: 26, marginBottom: 10}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{"Địa chỉ"}</Text>
                    <Input placeholder={"Nhập địa chỉ"} 
                    value={valPit.address}
                    onChange={(text) => {
                        setValPit({ 
                                ...valPit, 
                                address: text.target.value
                            });
                            
                    }}></Input>
                </Row>
                <Row style={{paddingRight: 15, marginBottom: 10}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{"Tình trạng"}</Text>
                    <DropdownComponent label={"Tình trạng"} 
                    value={selectedStatus}
                    items={{
                              onClick: function (value: ItemTypeProp): void {
                                setSelectedStatus(value.label as string);
                                setValPit({ 
                                    ...valPit, 
                                    status: value.key
                                })
                              },
                              items: items
                          }}></DropdownComponent>
                    
                </Row>
                <Row style={{marginRight: 26, marginBottom: 10}}>
                    <Col xl={24} md={24} sm={24} >
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Hình ảnh'}</Text>
                        <Row>
                            <Button style={{flex: 1, cursor: "default", borderColor: "#DBDADE", color: "#222222"}} danger>
                                <Row>
                                    <Text>{valPit?.image?.attributes?.name ?? "Chọn hình ảnh"}</Text>
                                    <view style={{flex: 1}}></view>
                                    <EyeOutlined disabled={!valPit.image?.attributes?.url} style={{marginRight: 10, marginLeft: 10, cursor: !valPit.image?.attributes?.url? "default" : "pointer"}} onClick={() =>{
                                        var link = SYSTEM_CONSTANTS.HOST + valPit.image?.attributes?.url
                                        if (link != null) {
                                            window.open(link, '_blank', 'noopener,noreferrer');
                                        }
                                    }}/>
                                    <UploadOutlined style={{cursor: "pointer"}} onClick={() => {
                                        var fielInput  = document.getElementById("fileInput");
                                        if (fielInput) {
                                            fielInput.click();
                                        }
                                    }}/>
                                    <input id="fileInput" accept={".jpeg,.jpg,.png,.heif,.heic"} type="file" name="file" onChange={changeHandler}  style={{display: "none"}}/>
                                </Row>
                            </Button>
                        </Row>
                        
                    </Col>
                </Row>

            </Col>
            <Col xl={12} md={24} sm={24}>
                <Row >
                    <Col xl={24} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{"Nhập địa chỉ tìm kiếm"}</Text>
                        <AutoComplete
                            placeholder={"Nhập tên địa chỉ"}
                            options={resultSearch}
                            style={{ width: "100%" }}
                            onSelect={(text, value) => {onSelect(text, value)}}
                            onSearch={onSearch}
                        />
                    </Col>
                </Row>
                <Row>
                <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{"Vĩ độ"}</Text>
                        <Input type="number" placeholder={"Nhập vĩ độ"} value={valPit?.point?.lat} 
                        onChange={(event) => {
                            setValPit({
                                ...valPit,
                                point: {
                                    lat: Number(event.target.value),
                                    lng: valPit.point?.lng ?? 0
                                }
                            })
                        }}></Input>
                    </Col>
                    <Col xl={12} md={24} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{"Kinh độ"}</Text>
                        <Input type="number" placeholder={"Nhập kinh độ"}
                        onChange={(event) => {
                            setValPit({
                                ...valPit,
                                point: {
                                    lat: valPit.point?.lat ?? 0,
                                    lng: Number(event.target.value)
                                }
                            })
                        }} value={valPit?.point?.lng}></Input>
                    </Col>
                </Row>
                <Row>
                <Col xl={12} md={12} sm={24} style={{minHeight: '316px', minWidth: '100%'}}>
                    
                    <MapContainer 
                        center={valPit.point?.lng && valPit.point?.lat ? valPit?.point : {lat: 19.806843, lng:105.784848}} 
                        zoom={15} 
                        style={{width: '100%', height: '100%'}}>
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <LocationMarker 
                        position={valPit.point?.lng && valPit.point?.lat ? valPit.point : {lat: 19.806843, lng:105.784848}}
                        
                        onClick={(e) => {
                            setValPit({
                                ...valPit,
                                point: {
                                    lat: e.latlng.lat,
                                    lng: e.latlng.lng
                                }
                            })
                        }}/>
                    </MapContainer>
                </Col>
                </Row>
            </Col>
        </Row>
        </div>
    </div>
  );
};

function LocationMarker({onClick, isLocation, position}: {onClick: (e: LeafletMouseEvent) => void, isLocation?: boolean, position?: any}) {
    const [locationData, setPosition] = useState<LatLng | null>(null)
    useEffect(() => {
        setPosition(position ?? null);
        // if(isLocation)map.locate()
        // else if(position) {
        //     map.flyTo(position, map.getZoom())
        // }
        map.flyTo(position, map.getZoom())
    }, [position])
    const map = useMapEvents({
      click(e) {
        onClick(e);
        setPosition(e.latlng);
      },
      locationfound: (e: LocationEvent) => {
        if(isLocation) {
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
        } 
        
        
      },
    })
  
    return locationData === null ? null : (
      <Marker position={locationData}>
        <Popup>{isLocation? "You are here" : "Vị trí hố van"}</Popup>
      </Marker>
    )
  }
