import React, { useEffect, useState } from 'react';
import { Layout, notification } from 'antd';
import {
  Redirect, Route, RouteProps, Switch
} from 'react-router-dom';
import { RootClaim, Routes } from '../Routes';
import { PrivateRoute } from '../../components/Routes';
import { Page } from '../../components/page/Page';
import { WaitOverlay } from '../../components/overlay/WaitOverlay';
import { ControlTable } from '../ControlTable/ControlTable';
import { useSelectorRoot } from '../../store/store';
import Utils from '../../common/Utils';

const { Content } = Layout;
export const Main = () => {

  const renderMain = () => {
    var routerProps: {label: string; routerProps:RouteProps; rootPermission?:RootClaim[]}[]   = []
    for (var item of Routes) {
      if (item.items && item.items.length > 0) {
        for(var child of item.items) {
          if (child.routeProps) {
            routerProps.push({
              label: child.label,
              routerProps: child.routeProps,
              rootPermission: child.rootPermission
            });
          }
          for(var childItem of (child?.items ?? [])) {
            if (childItem.routeProps) {
              routerProps.push({
                label: childItem.label,
                routerProps: childItem.routeProps,
                rootPermission: childItem.rootPermission
              });
            }
          }
        }
      }
      if (item.routeProps) {
        routerProps.push({
          label: item.label,
          routerProps: item.routeProps,
          rootPermission: item.rootPermission
        });
      }
    }
    return routerProps.map(({
      label,
      rootPermission,
      routerProps,
    }) => (
      <PrivateRoute
        allowedRoles={[...(rootPermission ?? [RootClaim.All])]}
        key={`${label}`}
        label={label}
        {...routerProps}
      />
    ))
  }
  return (
    <WaitOverlay >
      <Layout>
        <Content>
          <Switch>
            <Route exact path="/" render={() => (
            <Page title="Bảng điều khiển">
              <ControlTable></ControlTable>
            </Page>)} />
            {renderMain()}
            <Redirect to="/" />
          </Switch>
        </Content>
      </Layout>
    </WaitOverlay>
  );
};
