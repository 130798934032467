export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new ResponseError(response);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  url: string,
  options?: RequestInit,
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(url, options);
  const response = checkStatus(fetchResponse);
  return parseJSON(response);
}
export const handlePrint = (pdfURL: URL) => {
  const win: Window | null = window.open(pdfURL);
  if (win) {
      win.onload = () => {
          win.print();
      };
  }
};
export const genPrice = (price :number) => {
  var result = ''; 
  var data = price;
  let temp = getNameNumberFull(data, 1000000, 'triệu');
  result += temp.result;
  data = temp.data;
  temp = getNameNumberFull(data, 100000, 'trăm');
  result += temp.result;
  data = temp.data;
  temp = getNameNumberFull(data, 10000, 'mươi');
  result += temp.result;
  data = temp.data;
  temp = getNameNumberFull(data, 1000, 'ngàn');
  result += temp.result;
  data = temp.data;
  temp = getNameNumberFull(data, 100, 'trăm');
  result += temp.result;
  data = temp.data;
  temp = getNameNumberFull(data, 10, 'mươi');
  result += temp.result;
  data = temp.data;
  result += getNameNumber(data);
  return result;
}
const getNameNumberFull = (data: number, ten: number, end: string) => {
  if (data > ten) {
    let temp = Math.floor( data / ten);
    return {
      result : `${getNameNumber(temp)} ${end} `,
      data : data % ten
    }; 
  }
  return {
    result : ``,
    data : data
  }; 
}

const getNameNumber = (value: number) => {
  switch (value){
    case 1:
      return 'một';
    case 2:
      return 'hai';
    case 3:
      return 'ba';
    case 4:
      return 'bốn';
    case 5:
      return 'năm';
    case 6:
      return 'sáu';
    case 7:
      return 'bảy';
    case 8:
      return 'tám';
    case 9:
      return 'chín';
    case 10:
      return 'mười';
    default:
      return '';
  }

}