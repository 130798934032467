import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserAPI, { RoleResponse, UserRequest, UserResponse } from "../../api/User.api";
import { Pagination, RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError, map, of, OperatorFunction} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface UserState {
    loading: boolean;
    userData: UserResponse[]| null;
    role: RoleResponse | null;
    errorMessage: string | null;
    pagination: Pagination | null;
}

const initState: UserState = {
    loading: false,
    userData: null,
    errorMessage: null,
    role: null,
    pagination: null
};
const userSlice = createSlice({
    name: 'user',
    initialState: initState,
    reducers: {
        getUser(state, action: PayloadAction<{
            page: number,
            size: number,
            role?: number,
            isZole?: boolean,
            search?: string
        }>) {
            state.loading = true;
        },
        getUserSuccess(state, action: PayloadAction<UserResponse[]>) {
            state.loading = false;
            state.userData = action.payload;
        },

        getRole(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getRoleSuccess(state, action: PayloadAction<RoleResponse>) {
            state.loading = false;
            state.role = action.payload;
        },
        createUser(state, action: PayloadAction<{page: number, role?: number, size: number, data: UserRequest}>) {
            state.loading = false;
        },
        createUserSuccess(state, action: PayloadAction<UserResponse[]>) {
            state.loading = false;
            state.userData = action.payload;
        },
        updateUser(state, action: PayloadAction<{page: number, role?: number, size: number, data: UserRequest}>) {
            state.loading = false;
        },
        updateUserSuccess(state, action: PayloadAction<UserResponse[]>) {
            state.loading = false;
            state.userData = action.payload;
        },
        deleteUser(state, action: PayloadAction<{page: number, role?: number, size: number, data: string}>) {
            state.loading = false;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const user$: RootEpic = (action$) =>
    action$.pipe(
        filter(getUser.match),
        switchMap((re) => {
            return UserAPI.get(re.payload.size, re.payload.page, re.payload.role, re.payload.isZole, re.payload.search).pipe(
                mergeMap((res: any) => {
                    return [
                        userSlice.actions.getUserSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    userSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const role$: RootEpic = (action$) =>
    action$.pipe(
        filter(getUser.match),
        switchMap((re) => {
            return UserAPI.getAllRole().pipe(
                mergeMap((res: any) => {
                    return [
                        userSlice.actions.getRoleSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    userSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const createUser$: RootEpic = (action$) =>
    action$.pipe(
        filter(createUser.match),
        switchMap((re, state) => {
            return UserAPI.add(re.payload.data).pipe(
                mergeMap((res: any) => {
                    return UserAPI.get(re.payload.size, re.payload.page, re.payload.role).pipe(
                        mergeMap((res: any) => {
                            return [
                                userSlice.actions.createUserSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            userSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                   
                }),
                catchError((e: AjaxError) => [
                    userSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const updateUser$: RootEpic = (action$) =>
    action$.pipe(
        filter(updateUser.match),
        switchMap((re, state) => {
            return UserAPI.update(re.payload.data).pipe(
                mergeMap((res: any) => {
                    return UserAPI.get(re.payload.size, re.payload.page, re.payload.role).pipe(
                        mergeMap((res: any) => {
                            return [
                                userSlice.actions.updateUserSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            userSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                   
                }),
                catchError((e: AjaxError) => [
                    userSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const deleteUser$: RootEpic = (action$) =>
    action$.pipe(
        filter(deleteUser.match),
        switchMap((re, state) => {
            return UserAPI.delete(re.payload.data).pipe(
                mergeMap((res: any) => {
                    return UserAPI.get(re.payload.size, re.payload.page, re.payload.role).pipe(
                        mergeMap((res: any) => {
                            return [
                                userSlice.actions.updateUserSuccess(res),
                            ];
                        }),
                        catchError((e: AjaxError) => [
                            userSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                        ]),
                    )
                   
                }),
                catchError((e: AjaxError) => [
                    userSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
export const UserEpics = [user$, role$, createUser$, updateUser$, deleteUser$];
export const {
    getUser,
    getUserSuccess,
    getRole,
    createUser,
    updateUser,
    deleteUser
} = userSlice.actions;
export const userReducer = userSlice.reducer;