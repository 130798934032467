import { Row, Col, Button, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import { DropdownComponent, ItemTypeProp } from "../../../components/Dropdown/DropdownComponent";
import exportSvg from "../../../image/icon/export.svg";
import { createUser, updateUser } from "../../../store/controls/User.slice";
import { useSelectorRoot } from "../../../store/store";
const {Text} = Typography;

export interface AddAdminProps {
    username?: string;
    password?: string;
    name?: string;
    email?: string;
    phone?: string;
    role?: string;
    id?: string;
}
export const AddAdmin = (props: {data?: AddAdminProps | null, onClickAdd?: () => void }) => {
    const [newUser, setNewUser] = useState<AddAdminProps>(props.data ?? {});
    const dispatch = useDispatch();
    const {role, pagination} = useSelectorRoot(state => state.user)
    const [items, setItems] = useState(role?.roles.map(x => ({
        key: x.id, label: x.description
    })));
    useEffect(() => {
        setItems(role?.roles.filter(x => x.id > 3).map(x => ({
        key: x.id, label: x.description
    })));
    },[role])
    useEffect(() => {
        setNewUser(props.data ?? {})
    }, [props.data])
    return (
    <div style={{width: "100%", maxHeight: "75vh"}}>
        <Row style={{justifyContent: 'center', alignItems: 'center', marginBottom: 16, marginRight: 26}}>
                <Col sm={24} xl={12} md={12}>
                    <Text style={{fontSize: 20, fontWeight: 500, color: '#222222'}}>{"Thông tin quản trị viên"}</Text>
                    
                </Col>
                <div style={{flex: 1}}></div>
                
                <Button type="primary" onClick={() => {
                    if (props.data == null){
                        dispatch(createUser({
                            page: pagination?.page ?? 1,
                            size: pagination?.pageSize ?? 10,
                            data: {
                                confirmed: true,
                                username: newUser.username,
                                password: newUser.password,
                                fullName: newUser.name,
                                email: newUser.email,
                                phone: newUser.phone,
                                role: {
                                    connect: role?.roles.find(x => x.id === Number(newUser.role)) != null? [{id: role?.roles.find(x => x.id === Number(newUser.role))?.id ?? 0}] : []
                                }
                            }
                        }))
                    } else {
                        dispatch(updateUser({
                            
                            page: pagination?.page ?? 1,
                            size: pagination?.pageSize ?? 10,
                            data: {
                                id: props.data.id,
                                confirmed: true,
                                username: newUser.username,
                                password: newUser.password,
                                fullName: newUser.name,
                                email: newUser.email,
                                phone: newUser.phone,
                                role: {
                                    connect: role?.roles.find(x => x.id === Number(newUser.role)) != null? [{id: role?.roles.find(x => x.id === Number(newUser.role))?.id ?? 0}] : [],
                                    disconnect: [{id: Number(props.data.role)}]
                                }
                            }
                        }))
                    }
                    
                    props.onClickAdd && props.onClickAdd();
                    }}>
                    <Row>
                      <ReactSVG src={exportSvg} style={{marginRight: 5, width: 18, height: 18}}></ReactSVG>
                      {props.data == null ? 'Thêm người dùng' :'Lưu thay đổi'}
                    </Row>
                </Button>
        </Row>
        <div style={{width: "100%", maxHeight: "calc(75vh - 50px)", overflowY: "auto"}}>
                <Row>
                    <Col xl={12} md={12} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Tên đăng nhập'}</Text>
                        <Input placeholder={'Nhập tên đăng nhập'} 
                        value={newUser.username}
                        
                        onChange={(text) => setNewUser({ 
                                ...newUser, 
                                username: text.target.value
                            })}></Input>
                    </Col>
                    <Col xl={12} md={12} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Mật khẩu'}</Text>
                        <Input.Password placeholder={'Nhập mật khẩu'} 

                        value={newUser.password}
                        onChange={(text) => setNewUser({ 
                                ...newUser, 
                                password: text.target.value
                            })} ></Input.Password>
                    </Col>
                </Row>
               
                <Row>
                    <Col xl={8} md={8} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Họ và tên'}</Text>
                        <Input placeholder={'Nhập họ và tên'} 
                        value={newUser.name}
                        onChange={(text) => setNewUser({ 
                                ...newUser, 
                                name: text.target.value
                            })}></Input>
                    </Col>
                    <Col xl={8} md={8} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                        <Text style={{fontSize: 13, fontWeight: 500}}>{'Địa chỉ email'}</Text>
                        <Input placeholder={'Nhập email'} type="email"
                         value={newUser.email}
                         onChange={(text) => setNewUser({ 
                                ...newUser, 
                                email: text.target.value
                            })} ></Input>
                    </Col>
                    <Col xl={8} md={8} sm={24} style={{paddingRight: 26, marginBottom: 16}}>
                            <Text style={{fontSize: 13, fontWeight: 500}}>{'Số điện thoại'}</Text>
                            <Input placeholder={'Nhập số điện thoại'} type="number" 
                            value={newUser.phone}
                            onChange={(text) => setNewUser({ 
                                    ...newUser, 
                                    phone: text.target.value
                                })} ></Input>
                    </Col>
                </Row>
                <Row style={{marginRight: 15}}>
                    <Text style={{fontSize: 13, fontWeight: 500}}>{'Quyền quản trị'}</Text>
                    <DropdownComponent label={"Quyền quản trị"}
                    value={role?.roles.find(x => x.id == Number(newUser.role))?.description}
                     items={{
                      onClick: function (value: ItemTypeProp): void {
                        setNewUser({ 
                            ...newUser, 
                            role: value.key
                        })
                      },
                      items: items
                  }}></DropdownComponent>
                </Row>
        </div>
    </div> );
}