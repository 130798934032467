import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import WarehouseAPI, { InventoryReport, MaterialInHouseQuery, MaterialResponse, MaterialWarehouseResponse, WarehouseResponse } from "../../api/Warehouse.api";
import { RootEpic } from "../../common/define-types";
import {filter, switchMap, mergeMap, catchError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';

interface WarehouseState {
    loading: boolean;
    WarehouseData: WarehouseResponse| null;
    MaterialData: MaterialResponse|null;
    MaterialWarehouseData: MaterialWarehouseResponse|null;
    InventoryReportData: InventoryReport[]|null;
    errorMessage: string | null;
}

const initState: WarehouseState = {
    loading: false,
    WarehouseData: null,
    MaterialData: null,
    MaterialWarehouseData: null,
    errorMessage: null,
    InventoryReportData: null
};
const WarehouseSlice = createSlice({
    name: 'Warehouse',
    initialState: initState,
    reducers: {
        getWarehouse(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getWarehouseSuccess(state, action: PayloadAction<WarehouseResponse>) {
            state.loading = false;
            state.WarehouseData = action.payload;
        },
        getMaterial(state, action: PayloadAction<void>) {
            state.loading = true;
        },
        getMaterialSuccess(state, action: PayloadAction<MaterialResponse>) {
            state.loading = false;
            state.MaterialData = action.payload;
        },
        getMaterialWarehouse(state, action: PayloadAction<MaterialInHouseQuery>) {
            state.loading = true;
        },
        getMaterialWarehouseSuccess(state, action: PayloadAction<MaterialWarehouseResponse>) {
            state.loading = false;
            state.MaterialWarehouseData = action.payload;
        },
        getInventoryReport(state, action: PayloadAction<MaterialInHouseQuery>) {
            state.loading = true;
        },
        getInventoryReportSuccess(state, action: PayloadAction<InventoryReport[]>) {
            state.loading = false;
            state.InventoryReportData = action.payload;
        },
        failRequest(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorMessage = action.payload;
        },
    }}
);

const Warehouse$: RootEpic = (action$) =>
    action$.pipe(
        filter(getWarehouse.match),
        switchMap((re) => {
            return WarehouseAPI.getWarehouse().pipe(
                mergeMap((res: any) => {
                    return [
                        WarehouseSlice.actions.getWarehouseSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    WarehouseSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const Material$: RootEpic = (action$) =>
    action$.pipe(
        filter(getMaterial.match),
        switchMap((re) => {
            return WarehouseAPI.getMaterial().pipe(
                mergeMap((res: any) => {
                    return [
                        WarehouseSlice.actions.getMaterialSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    WarehouseSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)
const MaterialWarehouse$: RootEpic = (action$) =>
    action$.pipe(
        filter(getMaterialWarehouse.match),
        switchMap((re) => { 
            return WarehouseAPI.getMaterialInHouse(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        WarehouseSlice.actions.getMaterialWarehouseSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    WarehouseSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

const getInventoryReport$: RootEpic = (action$) =>
    action$.pipe(
        filter(getInventoryReport.match),
        switchMap((re) => { 
            return WarehouseAPI.getInventoryReport(re.payload).pipe(
                mergeMap((res: any) => {
                    return [
                        WarehouseSlice.actions.getInventoryReportSuccess(res),
                    ];
                }),
                catchError((e: AjaxError) => [
                    WarehouseSlice.actions.failRequest("Có lỗi xảy ra vui lòng thử lại sau"),
                ]),
            )
        })
)

export const WarehouseEpics = [Warehouse$,Material$,MaterialWarehouse$,getInventoryReport$];
export const {
    getWarehouse,
    getMaterial,
    getMaterialWarehouse,
    getWarehouseSuccess,
    getMaterialWarehouseSuccess,
    getInventoryReport,
    getInventoryReportSuccess,
} = WarehouseSlice.actions;
export const WarehouseReducer = WarehouseSlice.reducer;