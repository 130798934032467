import SYSTEM_CONSTANTS from "../common/constants";
import HttpClient from "./http-client";
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import { Pagination, User } from "../common/define-types";
import { Contract } from "./Client.api";
export interface Shift {
    name: string,
    description: string,
    createdAt: Date,
    updatedAt: Date
}
export interface Operator {
    id?: number,
        date: Date,
        note: string,
        createdAt: Date,
        updatedAt: Date,
        day: number,
        month: number,
        year: number,
        shift: {
            data: {
                id: number,
                attributes: Shift
            }
        },
        operatorTechnicians: {
            data: 
                {
                    id: number,
                    attributes: User
                }[]
        },
        handoverDocument?: {
            data?: Contract
        }
    
}
export interface OperatorResponse {
    data: {
        id: number,
        attributes: Operator
    }[],
    meta: {
        pagination:Pagination
    }
    
}
export interface ReportReponse {
    pumpingHour: number,
    roughWater: number,
    pipelineDischargeWater: number,
    filteredWater: number,
    consumedCloAmount: number,
    consumedAlumAmount: number
}
export interface OperatorCreateRequestData {
    shift: {
        connect: number[]
    },
    operatorTechnicians: {
        connect: number[]
    },
    date: string
}
export interface OperatorCreateRequest {
    data: OperatorCreateRequestData[]
}
export interface OperatorUpdateRequestData {
    id?: number,
    operatorTechnicians: {
        connect?: number[],
        disconnect: number[]
    },
    date?: string
}
export interface OperatorUpdateRequest {
    data: OperatorUpdateRequestData[]
}
export default class OperatorAPI {
    static host = SYSTEM_CONSTANTS.HOST;
    static get(month: number, year: number): Observable<OperatorResponse | null> {
        const api = `${OperatorAPI.host}/${SYSTEM_CONSTANTS.OPERATOR.GET}?populate=shift,operatorTechnicians,handoverDocument&filters[month][$eq]=${month}&filters[year][$eq]=${year}&pagination[pageSize]=400`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as OperatorResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static getReport(month: number, year: number, day: number): Observable<ReportReponse | null> {
        const api = `${OperatorAPI.host}/${SYSTEM_CONSTANTS.OPERATOR.REPORT}?month=${month}&year=${year}&day=${day}`;
        return HttpClient.get(api, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as ReportReponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static create(body: OperatorCreateRequest): Observable<OperatorResponse | null> {
        const api = `${OperatorAPI.host}/${SYSTEM_CONSTANTS.OPERATOR.CREATE}`;
        return HttpClient.post(api, body, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as OperatorResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    static update(body: OperatorUpdateRequest): Observable<OperatorResponse | null> {
        const api = `${OperatorAPI.host}/${SYSTEM_CONSTANTS.OPERATOR.UPDATE}`;
        return HttpClient.put(api, body, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).pipe(
            map(
                res => (res as OperatorResponse) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
}